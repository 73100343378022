import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";
// import { countryList, stateList } from "../Components/helpers/dumpCountryData";
import { countryList, stateList } from "../Components/helpers/countryData";

const DEFAULT_ADDRESS = {
  address_line_1: "",
  address_line_2: "",
  city: "",
  zipcode: "",
  state: null,
  country: null,
};

const ControllerDataForm = (props) => {
  const { controllerAlias, controllerAddress, showAlias, onSubmitHandler } =
    props;
  const { t } = useTranslation();
  const form = useFormik({
    initialValues: {
      ...(showAlias ? { alias: controllerAlias } : {}),
      ...(!!controllerAddress ? controllerAddress : DEFAULT_ADDRESS),
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });

  const getStateOptions = (initialCountryCode, countryName) => {
    let countryCode = initialCountryCode;

    if (!initialCountryCode) {
      countryCode = (
        countryList.find((item) => t(item.name) === countryName) || {}
      ).code;
    }

    const result =
      countryCode === null
        ? []
        : stateList.filter((item) => {
            return item.country_code === countryCode;
          });

    return result;
  };

  const [stateOptionList, setStateOptionsList] = useState(
    !!controllerAddress ? getStateOptions(null, controllerAddress.country) : []
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      {showAlias && (
        <Grid item xs={10}>
          <TextField
            id="line-1-input"
            label={t(`Controller Name`)}
            required
            name="alias"
            onChange={form.handleChange}
            value={form.values.alias}
            variant="outlined"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={10}>
        <TextField
          id="line-1-input"
          label={t(`Address line 1`)}
          required
          name="address_line_1"
          onChange={form.handleChange}
          value={form.values.address_line_1}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          id="line-2-input"
          label={t(`Address line 2`)}
          name="address_line_2"
          onChange={form.handleChange}
          value={form.values.address_line_2}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          id="city-input"
          required
          label={t(`City`)}
          name="city"
          onChange={form.handleChange}
          value={form.values.city}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          id="zipcode-input"
          required
          label={t(`Zipcode`)}
          name="zipcode"
          onChange={form.handleChange}
          value={form.values.zipcode}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={10}>
        <Autocomplete
          id="country-input"
          disablePortal
          autoHighlight
          options={countryList}
          value={
            form.values.country &&
            countryList.find(
              (item) =>
                item.code === form.values.country.code ||
                t(item.name) === form.values.country
            )
          }
          getOptionLabel={(option) => t(option.name)}
          onChange={(event, value) => {
            form.setFieldValue("country", value);
            form.setFieldValue("state", null);

            const newStatesList = getStateOptions((value || {}).code);
            setStateOptionsList(newStatesList);
          }}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;

            return (
              <Box
                key={key}
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=""
                />
                {t(option.name)}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label={t(`Country`) + " *"} />
          )}
        />
      </Grid>
      <Grid item xs={10}>
        <Autocomplete
          id="state-input"
          disablePortal
          autoHighlight
          disabled={stateOptionList.length === 0}
          options={stateOptionList}
          value={
            form.values.state &&
            stateList.find(
              (item) =>
                item.id === form.values.state.id ||
                t(item.name) === form.values.state
            )
          }
          getOptionLabel={(option) => t(option.name)}
          onChange={(event, value) => {
            form.setFieldValue("state", value);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t(`State`) + " *"} />
          )}
        />
      </Grid>
      <Grid item xs={10}>
        <LoadingButton
          variant="contained"
          fullWidth
          loading={props.isLoading}
          loadingPosition="center"
          sx={{
            backgroundColor: "#032C65",
            color: "white",
            paddingY: 2,
            "&:hover": {
              backgroundColor: "white",
              color: "#032C65",
            },
          }}
          startIcon={<Save />}
          onClick={() => form.handleSubmit()}
        >
          {t(`Confirm`)}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

ControllerDataForm.defaultProps = {
  showAlias: false,
};

ControllerDataForm.propTypes = {
  showAlias: PropTypes.bool,
  controllerAlias: PropTypes.string,
  onSubmitHandler: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  controllerAddress: PropTypes.shape({
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    country: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
};

export default ControllerDataForm;
