import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  IconButton,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { SpinnerCircular } from "spinners-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import "./SubAccounts.css";
import { MySwal } from "../../Components/helpers/SwalAlert";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { ArrowBack, ExpandMore, Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import zIndex from "@mui/material/styles/zIndex";

export const SubAccounts_table = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subAcc, setsubAcc] = useState(false);
  const [open, setOpen] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    id: "",
    password: "",
    pc: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [optionButton, setoptionButton] = useState("");
  const [originalForm, setoriginalForm] = useState("");
  const { t, i18n } = useTranslation();
  // const isSmallScreen = useMediaQuery("(max-width:768px)");

  // const [requiredFields, setrequiredFields] = useState({
  //   firstName: false,
  //   lastName: false,
  //   email: false,
  //   company: false,
  // });

  // const modalStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   maxWidth: "500px",
  //   bgcolor: "white",
  //   border: "2px solid grey",
  //   borderRadius: "8px",
  //   boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
  //   padding: "20px",
  //   textAlign: "center",
  // };

  const labelStyle = {
    fontSize: "22px",
    fontWeight: "normal",
    marginBottom: "10px",
    color: "#032C65",

  };

  const modalStyleAdaptable = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    bgcolor: "white",
    border: "2px solid grey",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
    padding: "20px",
    textAlign: "center",
    overflow: "scroll",
    width: "80%",
    height: "90%",
    zIndex:0
  };

  useEffect(() => {
    try {
      fetchUsers();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const resp = await apiUser.get(`/sub_accounts`);

      // console.log(resp.data);
      setsubAcc(resp.data);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const saveChanges = async () => {
    setIsLoading(true);

    // console.log(form);
    try {
      if (optionButton === 1) {
        const sendR = await apiUser.put(`/sub_accounts`, form);


        if(sendR.data.message  ==  "PasswordMismatch"){

          Swal.fire({
            title:t('Error, something went wrong'),
            text:t("Passwords do not match"),
            icon:'error',
            zIndex:1,
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

        }
        else  if(sendR.data.message  == "InvalidPasswordException"){

          Swal.fire({
            title:t('Error, something went wrong'),
            text:t("Password does not meet minimum requirement of 6 characters."),
            icon:'error',
            zIndex:1,
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

        }
        else if(sendR.data.success == true){

          Swal.fire({
            title:t('Successfull'),
            text:t("Password changed"),
            icon:'success',
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

          closebox()
        }

        // console.log(sendR);
      } else if (optionButton === 2) {
        const sendR = await apiUser.post(`/sub_accounts`, form);

        console.log(sendR.data.message)
        if(sendR.data.message  ==  "PasswordMismatch"){

          Swal.fire({
            title:t('Error, something went wrong'),
            text:t("Passwords do not match"),
            icon:'error',
            zIndex:1,
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

        }
        else if(sendR.data.message  ==  "InvalidParameterException"){

          Swal.fire({
            title:t('Error, something went wrong'),
            text:t("All fields must be filled out"),
            icon:'error',
            zIndex:1,
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

        }
        else if(sendR.data.success == true){

          Swal.fire({
            title: t('Successfull'),
            text:t("Sub-account created"),
            icon:'success',
            showConfirmButton:true,
            confirmButtonText: t('Confirm')

          })

          closebox()

        }

      }
    } catch (e) {
      Swal.fire({
        title:t('Error, something went wrong'),
        text:t("All fields must be filled out"),
        icon:'error',
        zIndex:1,
        showConfirmButton:true,
        confirmButtonText: t('Confirm')

      })
    }

    setIsLoading(false);
    fetchUsers();
  };

  const addUser = () => {
    setoptionButton(2);
    setOpen(true);
  };

  const refreshData = () => {
    setRefresh(true);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
    setForm(originalForm);
  };

  const editAccount = (row) => {
    setoptionButton(1);
    setoriginalForm(row);

    setForm({
      ...form,
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      company_name: row.company_name,
      id: row.id,
      password: row.password,
      pc: row.pc,
    });

    // console.log(form);
    setOpen(true);
  };

  const deleteAcc = async (row) => {
    if (row) {
      MySwal.fire({
        title: t("Delete account"),
        text: t(`Are you sure to delete `) + ` ${row.first_name} ${row.last_name}` + `?`,
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#032C65",
        confirmButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it!"),
        cancelButtonText: t("Cancel")
      }).then(async (result) => {
        setIsLoading(true);
        try {
          if (result.isConfirmed) {
            const resp = await apiUser.delete(`/sub_accounts/${row.id}`);
            
            if(resp.data.success){
              MySwal.fire({
                title:t('Successfull'),
                text:t('Sub-account deleted'),
                icon:'success',
                showConfirmButton:true,
                confirmButtonText:t("Confirm")
              })
            }

            fetchUsers();
          }
        } catch (e) {
          console.error(e);

          MySwal.fire({
            title:'Error',
            text:t('Error, something went wrong'),
            icon:'error',
            showConfirmButton:true,
            confirmButtonText:t("Confirm")
          })

        } finally {
          setIsLoading(false);
        }
      });
    }
  };

  const closebox = () => setOpen(false);

  const columns = [
    {
      field: "fullname",
      headerName: t("Full Name"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
    },
    {
      field: "email",
      headerName: t("Email Address"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "company_name",
      headerName: t("Company"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: t("Actions"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      sortable: false,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ border: "none" }}
            onClick={() => editAccount(params.row)}
            variant="outlined"
            startIcon={
              <EditIcon style={{ fontSize: "25px", color: "#032C65" }} />
            }
          ></Button>
          <Button
            style={{ border: "none" }}
            onClick={() => deleteAcc(params.row)}
            variant="outlined"
            startIcon={
              <DeleteIcon style={{ fontSize: "25px", color: "#C0392B" }} />
            }
          ></Button>
        </div>
      ),
    },
  ];

  const MobileTable = ({ data }) => (
    <div
      style={{
        maxWidth: "600px",
        margin: "auto",
        width: "80vw",
        marginTop: "2%",
      }}
    >
      {data.map((row) => (
        <Accordion
          style={{
            backgroundColor: "white",
            color: "black",
            border: "1px solid grey",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
            marginBottom: "16px",
          }}
          key={row.id}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid
              container
              justifyContent="start"
              alignContent="center"
              padding={1}
            >
              <Grid
                item
                container
                justifyContent="start"
                xs={2}
                alignItems="center"
              >
                <AssignmentIndIcon sx={{ fontSize: "2.5em" }} color="success" />
              </Grid>
              <Grid
                item
                container
                justifyContent="start"
                xs={10}
                alignItems="center"
              >
                <Typography fontWeight="bold" textAlign="left">
                  {row.username}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            style={{
              color: "#032C65",
              fontWeight: "normal",
              marginRight: "8px",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            <Typography
              style={{
                color: "#032C65",
                fontWeight: "normal",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >{t(`First name`)}:</Typography>
            <Typography
              style={{
                color: "black",
                fontSize: "13px",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >{`${row.first_name || "N/A"}`}</Typography>

            <Typography
              style={{
                color: "#032C65",
                fontWeight: "normal",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >{t(`Last name`)}:</Typography>
            <Typography
              style={{
                color: "black",
                fontSize: "13px",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >{`${row.last_name || "N/A"}`}</Typography>

            <Typography
              style={{
                color: "#032C65",
                fontWeight: "normal",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {t(`Email`)}:
            </Typography>
            <Typography
              style={{
                color: "black",
                fontSize: "13px",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {`${row.email || "N/A"}`}
            </Typography>
            <Typography
              style={{
                color: "#032C65",
                fontWeight: "normal",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {t(`Company name`)}:
            </Typography>
            <Typography
              style={{
                color: "black",
                fontSize: "13px",

                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {`${row.company_name || "N/A"}`}
            </Typography>

            <AccordionDetails
              style={{
                textAlign: "justify",
                color: "#032C65",
                padding: "16px",
                borderTop: "1px solid #ccc",
                marginTop: "8px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="button"
                size="large"
                style={{
                  backgroundColor: "yellowgreen",
                  border: "1px solid black",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
                  fontSize: "15px",
                  color: "black",
                }}
                startIcon={<EditIcon />}
                onClick={() => editAccount(row)}
              >
                {t(`Edit`)}
              </Button>

              <Button
                type="button"
                size="large"
                style={{
                  backgroundColor: "white",
                  border: "1px solid grey",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
                  fontSize: "15px",
                  fontWeight: "normal",
                  color: "black",
                }}
                startIcon={<DeleteIcon style={{ color: "red" }} />}
                onClick={() => deleteAcc(row)}
              >
                {t(`Delete`)}
              </Button>
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );

  const SubAccountsTable = () => {
    const isSmallScreen = useMediaQuery("(max-width:800px)");
    const mapData = Object.keys(subAcc).map((key) => {
      const { company_name, first_name, last_name, username, email, id } =
        subAcc[key];

      return {
        id: id,
        company_name: company_name,
        fullname: first_name + " " + last_name,
        first_name: first_name,
        last_name: last_name,
        username: username,
        email: email,
      };
    });

    return (
      <>
        <Grid container xs={12} justifyContent="center">
          {isLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Grid
            sx={{
              borderRadius: "8px",
              border: `1px solid ${grey[300]}`,
              backgroundColor: "#F7F8FA",
              padding: 2,
            }}
            item
            container
            component={Paper}
            elevation={0}
            xs={10}
          >
            <Grid
              sx={{ borderRadius: "8px" }}
              item
              xs={12}
              sm={2}
              alignItems="center"
              component={Stack}
              direction="column"
              justifyContent="center"
            >
              <IconButton
                size="large"
                sx={{ backgroundColor: "white" }}
                onClick={() => navigate("/mainAcc")}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid
              sx={{ borderRadius: "8px" }}
              item
              xs={12}
              sm={6}
              md={7}
              lg={7}
              alignItems="center"
              component={Stack}
            >
              <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
                {t(`Sub-Account Management`)}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={4}
              md={3}
              lg={3}
              paddingX={3}
              paddingY={0.2}
              textAlign="left"
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ borderRadius: "13px" }}
              sx={{
                marginTop: { xs: 1, sm: 1, md: 0, lg: 0 },
              }}
            >
              <Button
                onClick={() => addUser()}
                className="button-new-controller-style"
                variant="contained"
                style={{
                  backgroundColor: "#032C65",
                  color: "white",
                  borderRadius: "10px",
                  position: "relative",
                  left: 0,
                  padding: 10,
                  fontSize: 16,

                  fontWeight: "normal",
                }}
                startIcon={<AddCircleIcon />}
              >
                {t(`Add user`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {mapData.length > 0 ? (
          isSmallScreen ? (
            <MobileTable data={mapData} />
          ) : (
            <Grid
              item
              container
              xs={9}
              justifyContent="center"
              sx={{ paddingTop: 3 }}
            >
              <DataGrid
                rows={mapData}
                columns={columns}
                hideFooter
                style={{
                  height: 400,
                  width: "100%",
                  backgroundColor: "white",
                  border: "2",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.1)",
                  fontSize: "15px",
                }}
                onSelectionModelChange={(newSelection) => {
                  if (newSelection.selectionModel.length > 0) {
                    const selectedRowIndex = newSelection.selectionModel[0];
                    setSelectedRow(mapData[selectedRowIndex]);
                  }
                }}
                selectionModel={selectedRow ? [selectedRow.id] : []}
              />
            </Grid>
          )
        ) : (
          <Grid item>
            <Stack direction="column" justifyContent="center">
              <Typography
                variant="h5"
                color={grey[600]}
                fontWeight="bold"
                textAlign="center"
              >
                <Info
                  fontSize="large"
                  sx={{ color: grey[600], fontSize: "4em" }}
                />
                <br />
                No sub-accounts
              </Typography>
            </Stack>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <div className="subAcc-page">
        {/* <SearchBar /> */}
        <Grid container xs={12} justifyContent="center">
          {isLoading ? <SpinnerCircular /> : <SubAccountsTable />}
        </Grid>
      </div>

      {refresh ? (
        <SpinnerCircular />
      ) : (
        <Modal
          open={open}
          onClose={closebox}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:0}}
        >
          <Box sx={modalStyleAdaptable}>
            <Grid container justifyContent="end" paddingX={1}>
              <CloseIcon
                size="large"
                color="primary"
                sx={{
                  right: 5,
                  top: "1%",
                  cursor: "pointer",
                }}
                onClick={closebox}
              />
            </Grid>
            <div style={labelStyle}>
              {optionButton === 2 ? t("New Sub-Account") : t("Edit Sub-Account")}
            </div>

            <label
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#032C65",
                fontWeight: "normal",
              }}
            >
              {t(`First name`)}:
            </label>
            <input
              type="text"
              placeholder={
                optionButton === 1
                  ? form.first_name
                  : t("Introduce the first name")
              }
              onChange={(e) => setForm({ ...form, first_name: e.target.value })}
              pattern="[a-zA-Z0-9]+"
              required
              style={{
                width: "95%",
                padding: "8px",
                borderRadius: "4px",
                border: form.first_name ? "1px solid #ccc" : "1px solid red",
                textAlign: "center",
                marginBottom: 10
              }}
            />
            {/* {form.first_name ? (
              ""
            ) : (
              <p style={{ color: "red" }}>{t(`Choose a first name`)}</p>
            )} */}
            <label
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#032C65",
                fontWeight: "normal",
              }}
            >
              {t(`Last name`)}:
            </label>
            <input
              type="text"
              placeholder={
                optionButton === 1 ? form.last_name : t("Introduce the last name")
              }
              onChange={(e) => setForm({ ...form, last_name: e.target.value })}
              style={{
                width: "95%",
                padding: "8px",
                borderRadius: "4px",
                border: form.last_name ? "1px solid #ccc" : "1px solid red",
                textAlign: "center",
                marginBottom: 10

              }}
            />
            {/* {form.last_name ? (
              ""
            ) : (
              <p style={{ color: "red" }}>{t(`Choose a last name`)}</p>
            )} */}

            <label
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#032C65",
                fontWeight: "normal",
              }}
            >
              {t(`Email`)}:
            </label>
            <input
              type="text"
              placeholder={
                optionButton === 1 ? form.email : t("Introduce your email")
              }
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              style={{
                width: "95%",
                padding: "8px",
                borderRadius: "4px",
                border: form.email ? "1px solid #ccc" : "1px solid red",
                textAlign: "center",
                marginBottom: 10

              }}
            />
            {/* {form.email ? "" : <p style={{ color: "red" }}>{t('Choose an email')}</p>} */}

            {optionButton === 2 ? (
              <>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Username`)}:
                </label>
                <input
                  type="text"
                  placeholder={t("Introduce an username")}
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                  style={{
                    width: "95%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: form.email ? "1px solid #ccc" : "1px solid red",
                    textAlign: "center",
                    marginBottom: 10

                  }}
                />
                {/* {form.username ? (
                  ""
                ) : (
                  <p style={{ color: "red" }}>{t(`Introduce an username`)}</p>
                )} */}
              </>
            ) : (
              ""
            )}

            <label
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#032C65",
                fontWeight: "normal",
              }}
            >
              {t(`Company name`)} :
            </label>

            <input
              type="text"
              placeholder={
                optionButton === 1
                  ? form.company_name
                  : t("Introduce the company name")
              }
              onChange={(e) =>
                setForm({ ...form, company_name: e.target.value })
              }
              style={{
                width: "95%",
                padding: "8px",
                borderRadius: "4px",
                border: form.company_name ? "1px solid #ccc" : "1px solid red",
                textAlign: "center",
                marginBottom: 10

              }}
            />
            {/* {form.company_name ? (
              ""
            ) : (
              <p style={{ color: "red" }}>{t(`Choose a Company name`)}</p>
            )} */}

            {optionButton === 1 ? (
              <FormControl
                component="fieldset"
                style={{ width: "100%", padding: "8px", textAlign: "center" }}
              >
                <FormControlLabel
                  key={1}
                  value={1}
                  control={<Checkbox />}
                  label={t("Change password") + " (?)"}
                  style={{ color: "#032C65" }}
                  checked={showPasswordFields}
                  onChange={() => setShowPasswordFields(!showPasswordFields)}
                />
              </FormControl>
            ) : (
              <>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Password`)}:
                </label>
                <input
                  type="password"
                  placeholder={t("Introduce a password")}
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  style={{
                    width: "95%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    marginBottom: 10

                  }}
                />

                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Confirm Password`)}:
                </label>
                <input
                  type="password"
                  placeholder={t("Confirm the password")}
                  value={form.pc}
                  onChange={(e) => setForm({ ...form, pc: e.target.value })}
                  style={{
                    width: "95%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    marginBottom: 10

                  }}
                />
              </>
            )}

            {showPasswordFields && optionButton === 1 && (
              <>
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                 {t(`New Password`)}:
                </label>
                <input
                  type="password"
                  placeholder={t("Enter new password")}
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  style={{
                    width: "95%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    marginBottom: 10

                  }}
                />

                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "#032C65",
                    fontWeight: "normal",
                  }}
                >
                  {t(`Confirm New Password`)}:
                </label>
                <input
                  type="password"
                  placeholder={t("Confirm new password")}
                  value={form.pc}
                  onChange={(e) => setForm({ ...form, pc: e.target.value })}
                  style={{
                    width: "95%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    textAlign: "center",
                    marginBottom: 10

                  }}
                />
              </>
            )}

            <div>
              <LoadingButton
                variant="contained"
                onClick={() => saveChanges()}
                loadingPosition="start"
                type="submit"
                loading={isLoading}
                style={{
                  marginTop: "20px",
                  left: "30px",
                  width: "50%",
                  backgroundColor: "#032C65",
                  color: "white",
                  fontWeight: "normal",
                }}
              >
                {t(`Confirm`)}
              </LoadingButton>

              <Button
                style={{
                  fontWeight: "normal",
                  marginTop: "20px",
                  left: "40px",
                }}
                onClick={refreshData}
                startIcon={
                  <RefreshIcon style={{ fontSize: 35, color: "#032C65" }} />
                }
              >


              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};
