import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useRef, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import Swal from "sweetalert2";
import useImage from "use-image";
import markerFlag from "./img/maps-and-flags.png"
import mapCenterIcon from "./img/home.png"


function Anchor(props) {
  const [strokeWidth, setStrokeWidth] = useState(2);

  return (
    <Circle
      x={props.point.x}
      y={props.point.y}
      radius={10}
      stroke="#666"
      fill={props.fill}
      strokeWidth={strokeWidth}
      onMouseOver={() => {
        document.body.style.cursor = "pointer";
        setStrokeWidth(3);
        props.onMouseOver();
      }}
      onMouseOut={() => {
        document.body.style.cursor = "default";
        setStrokeWidth(2);
        props.onMouseOut();
      }}
      onClick={() => {
        document.body.style.cursor = "default";
        props.onClick();
      }}
    />
  );
}

function PolygonOriginAnchor(props) {
  const isValid = props.validateMouseEvents();
  const [fill, setFill] = useState("transparent");

  return (
    <Anchor
      point={props.point}
      fill={fill}
      onClick={() => {
        if (isValid) {
          props.onValidClick();
        }
      }}
      onMouseOver={() => {
        if (isValid) {
          document.body.style.cursor = "pointer";
          setFill("green");
          props.onValidMouseOver();
        } else {
          document.body.style.cursor = "not-allowed";
          setFill("red");
        }
      }}
      onMouseOut={() => {
        setFill("transparent");
      }}
    />
  );
}

function PolygonConstructor({childRef , data  , onPolygonCompletion , drawing , poly , removed }) {
  
  const [polygons, setPolygons] = useState([]);
  const [currentPolygon, setCurrentPolygon] = useState([]);
  const [nextPoint, setNextPoint] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);
  const handleClick = ({ x, y }) => {
    if(!drawing){
      setCurrentPolygon([])
      return 
    }
   
      setCurrentPolygon([...currentPolygon, { x, y }]);

   
  };

  const handleCompletePolygon = () => {
    if (!data || !data.color) {
      console.error(" missing 'color' ");
      return null;
    }

      setPolygons([...polygons, { shape_points: currentPolygon, color: data.color || 'red' ,   zone_name  :  data.zone.zone_name , zone_decoder : data.zone.decoder , position : { x: currentPolygon[0], y : currentPolygon[1]} }]);
    childRef(mapRef.current);
    setCurrentPolygon([]);
    onPolygonCompletion()

    
  };
  useEffect(() => {

    if(removed){
      setPolygons(poly)

    }
  
   
  
    }, [removed,poly]);
  

   
  return (
    <>
      {polygons.length > 0? 
      
      polygons.map((polygon, index) => (
        <Line
        ref={childRef}
          key={index}
          
          opacity={0}
          zIndex={0}
          points={polygon.shape_points.flatMap((point) => [point.x, point.y])}
         
        />
      )) : null}

      <Line
        strokeWidth={3}
        stroke="blue"
        points={[...currentPolygon.flatMap((point) => [point.x, point.y]), nextPoint.x, nextPoint.y]}
      />

      <Rect
        x={0}
        y={0}
        width={window.innerWidth}
        height={800}
        onClick={(event) => {
          const x = event.evt.offsetX;
          const y = event.evt.offsetY;
          handleClick({ x, y });
        }}
        onMouseMove={(event) => {
          const x = event.evt.offsetX;
          const y = event.evt.offsetY;
          setNextPoint({ x, y });
        }}
      />

      {currentPolygon.length > 0 && (
        <PolygonOriginAnchor
          props
          point={currentPolygon[0]}
          onValidClick={handleCompletePolygon}
          onValidMouseOver={() => setNextPoint(currentPolygon[0])}
          validateMouseEvents={() => currentPolygon.length > 2}
        />
      )}
    </>
  );
}

export default function PolygonMaker({img , childR , data , containerWidth,  containerHeight , polygons , clickMarkers , dialogAction , drawing , removed , poly_completed  , imageMarkers , selectedMarker , controllerPinSitePlan   }) {

  const [points, setPoints] = useState([]);
  const [image] = useImage(img); 
  const [icon] = useImage(markerFlag)
  const [controllerIcon] = useImage(mapCenterIcon)
  const [decoders,setdecoders] = useState([])
  const [polygonCompleted, setPolygonCompleted] = useState(false); 
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (image) {
      setImageSize({
        width: image.width,
        height: image.height
      });
    }
  }, [image]);

  const makePoints = (e) =>{
    clickMarkers(e)
    const x = e.evt.x;
    const y = e.evt.y;
    setPoints([...points, { x, y }])

  } 

const select = (poly) => {
  dialogAction(poly)


}


const handleDragStartMarkers = (index) => {
  setdecoders(index);
};

const handleDragMoveMarkers = (index, e) => {
  if (decoders === index) {
    const newPos = {
      x: e.target.x(),
      y: e.target.y()
    };
    const updatedMarkers = [...imageMarkers];
    updatedMarkers[index].position = newPos;
    setdecoders([...updatedMarkers]);
    
  }
};
const handleDragEndMarkers = () => {
  setdecoders(null);
};

const handlePolygonCompletion = () => {

  setPolygonCompleted(true);
  poly_completed();

}

const handleDragController = (index,e) => {
  const newPos = {
    x: e.target.x(),
    y: e.target.y()
  };
  const updtController = [...controllerPinSitePlan];
  updtController[index].position = newPos;
  

}

const selectMarker = (e,marker) => {
  selectedMarker(e,marker)

 
}


  return (
    <Stage
    width={imageSize.width || containerWidth}
    height={containerHeight}
    ref={childR}
    onClick={makePoints}
  >


  <Layer   >


   
    {image && (
    <Image
    image={image}
    width={imageSize.width || containerWidth}
    height={containerHeight}
    x={0}
    y={0}
  />
    )}


 
  

     <PolygonConstructor
      data={data}
      poly={polygons}
      childRef={childR}
      points={points}
      drawing={drawing}
      removed={removed}
      onPolygonCompletion={handlePolygonCompletion}
    />
{polygons.map((polygon, index) => (
      <Line
        key={index}
        zIndex={1}
        strokeWidth={3}
        stroke={polygon.color}
        fill={polygon.color}
        opacity={0.5}
        closed
        onClick={() => select(polygon)}
        points={polygon.shape_points.flatMap((point) => point)}

      />
    ))}


{imageMarkers.map((marker, index) => (

 
  
  
<Image
  key={index}
  image={icon}
  x={marker.position.x}
  y={marker.position.y}
  width={44}
  zIndex={125}


  height={44}
  draggable
  onClick={(e) => selectMarker(e, marker)}
  onDragStart={(e)=> handleDragStartMarkers(index)}
  onDragMove={(e) => handleDragMoveMarkers(index, e)}
  onDragEnd={handleDragEndMarkers} />




     ))}




{controllerPinSitePlan !== null  ? controllerPinSitePlan.map((controller, index) => (

 
  
  
<Image
  key={index}
  image={controllerIcon}
  x={controller.position.x}
  y={controller.position.y}
  width={44}
  zIndex={15 }
  onDragMove={(e)=> handleDragController(index,e)}

  height={44}
  draggable
   />




     )) : ""}
       
       
    

   

  </Layer>
</Stage>
  );

}
