import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUser } from "../../Apis/configs/axiosConfig";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DataGrid } from "@mui/x-data-grid";
import { SpinnerCircular } from "spinners-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { blueGrey, green, grey } from "@mui/material/colors";
import { Alert, Paper } from "@mui/material";
import { Stack } from "react-bootstrap";
import {
  ArrowBack,
  CheckCircleRounded,
  Info,
  Save,
  Warning,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const PROGRAM_LETTERS = ["a", "b", "c", "d", "e", "f"];

export const ZoneGroupingTable = () => {
  const navigate = useNavigate();
  const [allZones, setAllZones] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState("a");
  const [programs, setPrograms] = useState({});
  const [programGroups, setProgramGroups] = useState([] || null);
  const [opt, setOpt] = useState(0);
  const [row, setRow] = useState({});
  const [name, setName] = useState("");
  const [groupZones, setGroupZones] = useState([]);
  const [existingZoneGroups, setExistingZoneGroups] = useState({});
  const [availableZones, setAvailableZones] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getGroupData();
  }, []);

  useEffect(() => {
    console.log("name", `-${name}-`);
  }, [name]);

  useEffect(() => {
    if (row && row.id && Object.keys(existingZoneGroups).length > 0) {
      const filteredZones = [
        ...allZones
          .filter((zone) => existingZoneGroups[zone.index] === row.id)
          .map((item) => item.index),
      ];

      // change starting zone groups
      setGroupZones(filteredZones);

      // change zones available
      setAvailableZones(
        allZones.filter((item) => {
          const existingGroup = existingZoneGroups[item.index];

          return !existingGroup || (row && existingGroup === row.id);
        })
      );
    } else {
      setGroupZones([]);
    }
  }, [row]);

  useEffect(() => {
    if (
      !!selectedProgram &&
      Object.keys(programs).length > 0 &&
      allZones.length > 0
    ) {
      loadGroupData(selectedProgram, programs, allZones);
    }
  }, [selectedProgram, allZones, programs]);

  const handleOpen = (e, params) => {
    if (e !== undefined) {
      setOpt(e);
      setRow(params);
      setName(e === 1 ? params.row.name : "");
      setOpen(true);
    }
  };

  const handleClose = (refresh = false) => {
    setOpen(false);
    setName("");
    setGroupZones([]);

    if (refresh) {
      getGroupData();
    }
  };

  const getGroupData = async () => {
    setIsLoading(true);
    try {
      const currentStatusResponse = await apiUser.get(
        `/controllers/${location.state.id}`
      );

      // set zones
      const originalZones = currentStatusResponse.data.data.zones;

      const connectedZones = originalZones.filter(
        (item) => item.decoder !== "000000"
      );

      connectedZones.forEach((connectedZone, index) => {
        const originalIndex = originalZones.findIndex(
          (zone) => zone.decoder === connectedZone.decoder
        );
        if (originalIndex !== -1) {
          connectedZone.index = originalIndex + 1;
        }
      });

      setAllZones(connectedZones);

      // set programs
      setPrograms(currentStatusResponse.data.data.programs);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleZoneInGroup = (item) => {
    const zone = item.index;

    if (groupZones.includes(zone)) {
      setGroupZones((prevZones) => prevZones.filter((z) => z !== zone));
    } else {
      setGroupZones((prevZones) => [...prevZones, zone]);
    }
  };

  const handleGroupName = (e) => {
    setName(e.target.value);
  };

  const addGroup = async () => {
    setIsLoading(true);
    console.log(`-${name}-`);
    try {
      const resp = await apiUser.post(
        `/controllers/${location.state.id}/groups/create`,
        {
          program: selectedProgram,
          group_name: name,
          zones: groupZones,
        }
      );

      handleClose(true);
      if (resp.data.success == true) {
        Swal.fire({
          title: t("Successfull"),
          text: t(`Group`) + ` ${name} ` + t(`has been created.`),
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: "Error",
          text: t(`An error has been occured, please try again.`),
          icon: "warning",
          timer: 2000,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadGroupData = (programLetter, allPrograms, connectedZones) => {
    const program = allPrograms[programLetter];

    // from the nested object of groups, set a plain dictionary with all zones in a program that have a group
    /* 
    {
        "GROUP_NAME_1": {
            "number": 1,
            "zones": [1, 4]
        },
        },
        "GROUP_NAME_2": {
            "number": 2,
            "zones": [93]
        }
    }

    TO

    {
      1: 1,
      4: 1,
      93: 2
    }
    */

    const zonesInGroups = {};
    Object.values(program.groups).forEach((gData) => {
      (gData.zones || []).forEach(
        (zoneGroup) => (zonesInGroups[zoneGroup] = gData.number)
      );
    });

    setProgramGroups(program.groups);
    setExistingZoneGroups(zonesInGroups);
  };

  const editGroup = async (params) => {
    params = row;

    setIsLoading(true);

    try {
      const request = await apiUser.put(
        `/controllers/${location.state.id}/groups/update`,
        {
          program: selectedProgram,
          group_number: params.id,
          group_name: name.length === 0 ? params.row.name : name,
          zones: groupZones,
        }
      );

      handleClose(true);

      Swal.fire({
        title: "Group Changed!",
        icon: "success",
        timer: 1500,
        background: "#f7f8fa",
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(true);
    }
  };

  const deleteGroup = (params) => {
    Swal.fire({
      title: t("Delete group"),
      text:
        t(`Are you sure you want to delete group:`) + ` ${params.row.name}?`,
      icon: "question",
      confirmButtonText: t("Confirm"),
      confirmButtonColor: "red",
      denyButtonText: t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const request = await apiUser.put(
            `/controllers/${location.state.id}/groups/delete`,
            {
              program: selectedProgram,
              group_number: params.id,
            }
          );

          if (request.success) {
            Swal.fire({
              title: t("Succesfull"),
              text: t(`Group`) + `:` + `${params.row.name} removed. `,
              icon: "success",
              timer: 2000,
            });
          }

          // call this to refresh data
          getGroupData();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerName: t("No."),
      flex: 1,
      align: "left",
      headerClassName: "header-table-title-style",
    },
    {
      field: "name",
      headerName: t("Groups"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
    },
    {
      field: "zones",
      headerName: t("Total of zones"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: t("Actions"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={(e) => handleOpen(1, params)} variant="outlined">
            <EditIcon style={{ fontSize: "30px", color: "#032C65" }} />
          </IconButton>
          <IconButton onClick={() => deleteGroup(params)} variant="outlined">
            <DeleteIcon style={{ fontSize: "30px", color: "#800000" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const ZoneGroupsList = () => {
    const zoneGroupsToShow = Object.entries(programGroups || {}).map(
      ([groupName, groupData]) => {
        return {
          id: groupData.number,
          name: groupName,
          zones: groupData.zones.length || [],
          zone: groupData.zones || [],
        };
      }
    );

    return zoneGroupsToShow.length > 0 ? (
      <DataGrid
        hideFooter
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
        }}
        rows={zoneGroupsToShow}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }],
          },
        }}
      />
    ) : (
      <Stack direction="column">
        <Info fontSize="large" sx={{ color: grey[600], fontSize: "4em" }} />
        <Typography variant="h5" color={grey[600]} fontWeight="bold">
          {t(`No Groups Found`)}
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      <Grid item container xs={12} justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            sx={{
              borderRadius: "8px",
              border: `1px solid ${grey[300]}`,
              backgroundColor: "#F7F8FA",
              padding: 2,
            }}
            item
            container
            component={Paper}
            elevation={0}
            xs={10}
          >
            <Grid
              sx={{ borderRadius: "8px" }}
              item
              sm={2}
              md={1}
              container
              alignItems="center"
              component={Stack}
              direction="column"
              justifyContent="center"
            >
              <IconButton
                size="large"
                sx={{ backgroundColor: "white" }}
                onClick={() => {
                  // navigate(`/dashboard/${location.state.id}`);
                  navigate(`/dashboard/${location.state.id}`, {
                    state: location.state,
                  });
                }}
              >
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid
              sx={{ borderRadius: "8px" }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={7}
              alignItems="center"
              component={Stack}
            >
              <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
                {t(`Zone Groups`)}
              </Typography>
              <Select
                placeholder={`Select program`}
                value={selectedProgram}
                style={{
                  marginTop: 10,
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: 8,
                  alignContent: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                onChange={(e) => {
                  setSelectedProgram(e.target.value);
                }}
              >
                {PROGRAM_LETTERS.length > 0 ? (
                  PROGRAM_LETTERS.map((item, index) => (
                    <MenuItem
                      style={{
                        backgroundColor: "white",
                        fontWeight: "bold",
                        color: "#032C65",
                        alignContent: "center",
                        textAlign: "center",
                      }}
                      key={item}
                      value={item}
                    >
                      {t(`Program`)} {item.toUpperCase()}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Programs Available</MenuItem>
                )}
              </Select>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={4}
              md={4}
              lg={3}
              paddingX={3}
              paddingY={0.2}
              textAlign="left"
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ borderRadius: "13px" }}
              sx={{
                marginTop: 1,
              }}
            >
              <Button
                onClick={(e) => handleOpen(0, null)}
                className="button-new-controller-style"
                variant="contained"
                style={{
                  backgroundColor: "#032C65",
                  color: "white",
                  borderRadius: "10px",
                  position: "relative",
                  left: 0,
                  padding: 10,
                  fontSize: 16,

                  fontWeight: "normal",
                }}
                startIcon={<AddCircleIcon />}
              >
                {t(`Add new group`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={11}
          sm={11}
          md={10}
          marginTop={4}
          justifyContent="center"
        >
          {isLoading ? <SpinnerCircular /> : <ZoneGroupsList />}
        </Grid>
      </Grid>
      <Modal open={open} onClose={() => handleClose(false)}>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          sx={{
            backgroundColor: "white",
            borderRadius: 3,
            margin: "80px auto",
            paddingX: { xs: 2, sm: 3, md: 4, lg: 5 },
            paddingY: 3,
            boxShadow: 5,
          }}
        >
          <Grid container justifyContent="end">
            <CloseIcon
              size="large"
              color="primary"
              sx={{
                left: "21.5%",
                top: "8%",

                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: "bold", color: "#032C65" }}
              variant="h5"
            >
              {opt === 1 ? t("Edit Group") : opt === 0 ? t("Create Group") : ""}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid
              item
              container
              xs={12}
              md={4}
              direction="column"
              justifyContent="center"
            >
              <LoadingButton
                loading={isLoading}
                onClick={opt === 1 ? editGroup : addGroup}
                startIcon={<Save />}
                disabled={groupZones.length < 2 || !name}
                variant="contained"
                style={
                  isLoading
                    ? {}
                    : {
                        backgroundColor:
                          groupZones.length < 2 || !name ? grey[50] : "#032C65",
                        color:
                          groupZones.length < 2 || !name ? grey[500] : "white",
                        fontWeight: "bold",
                        padding: 14,
                      }
                }
              >
                {opt === 1
                  ? t("Update Group")
                  : opt === 0
                  ? t("Create Group")
                  : ""}
              </LoadingButton>
              {groupZones.length < 2 && (
                <Alert
                  sx={{ marginTop: { xs: 2, sm: 2, md: 0, lg: 0 } }}
                  icon={<Warning fontSize="inherit" />}
                  severity="warning"
                >
                  {t("Need more than one zone")}
                </Alert>
              )}
              {!name && (
                <Alert
                  sx={{ marginTop: { xs: 2, sm: 2, md: 0, lg: 0 } }}
                  icon={<Warning fontSize="inherit" />}
                  severity="warning"
                >
                  {t("Need to name group")}
                </Alert>
              )}
            </Grid>
            <Grid item container xs={12} md={7} justifyContent="center">
              <Grid item xs={8} marginTop={{ xs: 3, sm: 3, md: 0, lg: 0 }}>
                <Typography fontWeight="bold" color="#032C65">
                  {t(`Group Name`)}
                </Typography>
                <input
                  placeholder={opt === 0 ? "" : name || ""}
                  onChange={handleGroupName}
                  maxLength={20}
                  style={{
                    borderRadius: "4px",
                    fontSize: 16,
                    width: "100%",
                    height: "40px",
                    textAlign: "center",
                    marginTop: "1%",
                    marginBottom: "5%",
                    border: "2px solid #042C66",
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                sx={{
                  backgroundColor: "white",
                  borderRadius: 3,
                  padding: 2,
                  border: `2px solid ${grey[300]}`,
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: "#032C65",
                      fontWeight: "bold",
                      marginBottom: 3,
                    }}
                  >
                    {t(`Available Zones`)}
                  </Typography>
                </Grid>
                {allZones
                  .filter((item) => {
                    const existingGroup = existingZoneGroups[item.index];

                    return !existingGroup || (row && existingGroup === row.id);
                  })
                  .toSorted((a, b) => a.index - b.index)
                  .map((item, index) => {
                    if (item) {
                      const isChecked = groupZones.some(
                        (zoneIndex) => zoneIndex === item.index
                      );

                      return (
                        <Grid
                          item
                          container
                          xs={12}
                          sm={5}
                          md={4.5}
                          component={Paper}
                          key={`available-zone-${index}-for-${opt}`}
                          onClick={() => toggleZoneInGroup(item)}
                          sx={{
                            margin: 1,
                            paddingY: 1,
                            paddingX: 0.5,
                            borderRadius: 2,
                            color: isChecked ? green[700] : blueGrey[700],
                            border: isChecked ? `1px solid ${green[700]}` : "",
                          }}
                          elevation={isChecked ? 4 : 1}
                          justifyContent="space-evenly"
                          alignContent="center"
                        >
                          {isChecked && <CheckCircleRounded color="success" />}
                          <Typography variant="body" fontWeight="bold">
                            {item.zone_name !== ""
                              ? item.zone_name
                              : t(`Zone`) + ` ${item.index}`}
                          </Typography>
                        </Grid>
                      );
                    } else {
                      return <></>;
                    }
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
