export const countryList = [
  {
    code: "AF",
    name: "countries.AF",
  },
  {
    code: "AL",
    name: "countries.AL",
  },
  {
    code: "DZ",
    name: "countries.DZ",
  },
  {
    code: "AD",
    name: "countries.AD",
  },
  {
    code: "AO",
    name: "countries.AO",
  },
  {
    code: "AG",
    name: "countries.AG",
  },
  {
    code: "AR",
    name: "countries.AR",
  },
  {
    code: "AM",
    name: "countries.AM",
  },
  {
    code: "AU",
    name: "countries.AU",
  },
  {
    code: "AT",
    name: "countries.AT",
  },
  {
    code: "AZ",
    name: "countries.AZ",
  },
  {
    code: "BS",
    name: "countries.BS",
  },
  {
    code: "BH",
    name: "countries.BH",
  },
  {
    code: "BD",
    name: "countries.BD",
  },
  {
    code: "BB",
    name: "countries.BB",
  },
  {
    code: "BY",
    name: "countries.BY",
  },
  {
    code: "BE",
    name: "countries.BE",
  },
  {
    code: "BZ",
    name: "countries.BZ",
  },
  {
    code: "BJ",
    name: "countries.BJ",
  },
  {
    code: "BT",
    name: "countries.BT",
  },
  {
    code: "BO",
    name: "countries.BO",
  },
  {
    code: "BA",
    name: "countries.BA",
  },
  {
    code: "BW",
    name: "countries.BW",
  },
  {
    code: "BR",
    name: "countries.BR",
  },
  {
    code: "BN",
    name: "countries.BN",
  },
  {
    code: "BG",
    name: "countries.BG",
  },
  {
    code: "BF",
    name: "countries.BF",
  },
  {
    code: "BI",
    name: "countries.BI",
  },
  {
    code: "KH",
    name: "countries.KH",
  },
  {
    code: "CM",
    name: "countries.CM",
  },
  {
    code: "CA",
    name: "countries.CA",
  },
  {
    code: "CV",
    name: "countries.CV",
  },
  {
    code: "CF",
    name: "countries.CF",
  },
  {
    code: "TD",
    name: "countries.TD",
  },
  {
    code: "CL",
    name: "countries.CL",
  },
  {
    code: "CN",
    name: "countries.CN",
  },
  {
    code: "CO",
    name: "countries.CO",
  },
  {
    code: "KM",
    name: "countries.KM",
  },
  {
    code: "CG",
    name: "countries.CG",
  },
  {
    code: "CR",
    name: "countries.CR",
  },
  {
    code: "CI",
    name: "countries.CI",
  },
  {
    code: "HR",
    name: "countries.HR",
  },
  {
    code: "CU",
    name: "countries.CU",
  },
  {
    code: "CY",
    name: "countries.CY",
  },
  {
    code: "CZ",
    name: "countries.CZ",
  },
  {
    code: "DK",
    name: "countries.DK",
  },
  {
    code: "DJ",
    name: "countries.DJ",
  },
  {
    code: "DM",
    name: "countries.DM",
  },
  {
    code: "DO",
    name: "countries.DO",
  },
  {
    code: "EC",
    name: "countries.EC",
  },
  {
    code: "EG",
    name: "countries.EG",
  },
  {
    code: "SV",
    name: "countries.SV",
  },
  {
    code: "GQ",
    name: "countries.GQ",
  },
  {
    code: "ER",
    name: "countries.ER",
  },
  {
    code: "EE",
    name: "countries.EE",
  },
  {
    code: "ET",
    name: "countries.ET",
  },
  {
    code: "FJ",
    name: "countries.FJ",
  },
  {
    code: "FI",
    name: "countries.FI",
  },
  {
    code: "FR",
    name: "countries.FR",
  },
  {
    code: "GA",
    name: "countries.GA",
  },
  {
    code: "GM",
    name: "countries.GM",
  },
  {
    code: "GE",
    name: "countries.GE",
  },
  {
    code: "DE",
    name: "countries.DE",
  },
  {
    code: "GH",
    name: "countries.GH",
  },
  {
    code: "GR",
    name: "countries.GR",
  },
  {
    code: "GD",
    name: "countries.GD",
  },
  {
    code: "GT",
    name: "countries.GT",
  },
  {
    code: "GN",
    name: "countries.GN",
  },
  {
    code: "GW",
    name: "countries.GW",
  },
  {
    code: "GY",
    name: "countries.GY",
  },
  {
    code: "HT",
    name: "countries.HT",
  },
  {
    code: "HN",
    name: "countries.HN",
  },
  {
    code: "HU",
    name: "countries.HU",
  },
  {
    code: "IS",
    name: "countries.IS",
  },
  {
    code: "IN",
    name: "countries.IN",
  },
  {
    code: "ID",
    name: "countries.ID",
  },
  {
    code: "IR",
    name: "countries.IR",
  },
  {
    code: "IQ",
    name: "countries.IQ",
  },
  {
    code: "IE",
    name: "countries.IE",
  },
  {
    code: "IL",
    name: "countries.IL",
  },
  {
    code: "IT",
    name: "countries.IT",
  },
  {
    code: "JM",
    name: "countries.JM",
  },
  {
    code: "JP",
    name: "countries.JP",
  },
  {
    code: "JO",
    name: "countries.JO",
  },
  {
    code: "KZ",
    name: "countries.KZ",
  },
  {
    code: "KE",
    name: "countries.KE",
  },
  {
    code: "KI",
    name: "countries.KI",
  },
  {
    code: "KW",
    name: "countries.KW",
  },
  {
    code: "KG",
    name: "countries.KG",
  },
  {
    code: "LA",
    name: "countries.LA",
  },
  {
    code: "LV",
    name: "countries.LV",
  },
  {
    code: "LB",
    name: "countries.LB",
  },
  {
    code: "LS",
    name: "countries.LS",
  },
  {
    code: "LR",
    name: "countries.LR",
  },
  {
    code: "LY",
    name: "countries.LY",
  },
  {
    code: "LI",
    name: "countries.LI",
  },
  {
    code: "LT",
    name: "countries.LT",
  },
  {
    code: "LU",
    name: "countries.LU",
  },
  {
    code: "MG",
    name: "countries.MG",
  },
  {
    code: "MW",
    name: "countries.MW",
  },
  {
    code: "MY",
    name: "countries.MY",
  },
  {
    code: "MV",
    name: "countries.MV",
  },
  {
    code: "ML",
    name: "countries.ML",
  },
  {
    code: "MT",
    name: "countries.MT",
  },
  {
    code: "MH",
    name: "countries.MH",
  },
  {
    code: "MR",
    name: "countries.MR",
  },
  {
    code: "MU",
    name: "countries.MU",
  },
  {
    code: "MX",
    name: "countries.MX",
  },
  {
    code: "FM",
    name: "countries.FM",
  },
  {
    code: "MD",
    name: "countries.MD",
  },
  {
    code: "MC",
    name: "countries.MC",
  },
  {
    code: "MN",
    name: "countries.MN",
  },
  {
    code: "MA",
    name: "countries.MA",
  },
  {
    code: "MZ",
    name: "countries.MZ",
  },
  {
    code: "MM",
    name: "countries.MM",
  },
  {
    code: "NA",
    name: "countries.NA",
  },
  {
    code: "NR",
    name: "countries.NR",
  },
  {
    code: "NP",
    name: "countries.NP",
  },
  {
    code: "NL",
    name: "countries.NL",
  },
  {
    code: "NZ",
    name: "countries.NZ",
  },
  {
    code: "NI",
    name: "countries.NI",
  },
  {
    code: "NE",
    name: "countries.NE",
  },
  {
    code: "NG",
    name: "countries.NG",
  },
  {
    code: "KP",
    name: "countries.KP",
  },
  {
    code: "MK",
    name: "countries.MK",
  },
  {
    code: "NO",
    name: "countries.NO",
  },
  {
    code: "OM",
    name: "countries.OM",
  },
  {
    code: "PK",
    name: "countries.PK",
  },
  {
    code: "PW",
    name: "countries.PW",
  },
  {
    code: "PA",
    name: "countries.PA",
  },
  {
    code: "PG",
    name: "countries.PG",
  },
  {
    code: "PY",
    name: "countries.PY",
  },
  {
    code: "PE",
    name: "countries.PE",
  },
  {
    code: "PH",
    name: "countries.PH",
  },
  {
    code: "PL",
    name: "countries.PL",
  },
  {
    code: "PT",
    name: "countries.PT",
  },
  {
    code: "PR",
    name: "countries.PR",
  },
  {
    code: "QA",
    name: "countries.QA",
  },
  {
    code: "RO",
    name: "countries.RO",
  },
  {
    code: "RU",
    name: "countries.RU",
  },
  {
    code: "RW",
    name: "countries.RW",
  },
  {
    code: "KN",
    name: "countries.KN",
  },
  {
    code: "LC",
    name: "countries.LC",
  },
  {
    code: "WS",
    name: "countries.WS",
  },
  {
    code: "SM",
    name: "countries.SM",
  },
  {
    code: "ST",
    name: "countries.ST",
  },
  {
    code: "SA",
    name: "countries.SA",
  },
  {
    code: "SN",
    name: "countries.SN",
  },
  {
    code: "RS",
    name: "countries.RS",
  },
  {
    code: "SC",
    name: "countries.SC",
  },
  {
    code: "SL",
    name: "countries.SL",
  },
  {
    code: "SG",
    name: "countries.SG",
  },
  {
    code: "SK",
    name: "countries.SK",
  },
  {
    code: "SI",
    name: "countries.SI",
  },
  {
    code: "SB",
    name: "countries.SB",
  },
  {
    code: "SO",
    name: "countries.SO",
  },
  {
    code: "ZA",
    name: "countries.ZA",
  },
  {
    code: "KR",
    name: "countries.KR",
  },
  {
    code: "SS",
    name: "countries.SS",
  },
  {
    code: "ES",
    name: "countries.ES",
  },
  {
    code: "LK",
    name: "countries.LK",
  },
  {
    code: "SD",
    name: "countries.SD",
  },
  {
    code: "SR",
    name: "countries.SR",
  },
  {
    code: "SE",
    name: "countries.SE",
  },
  {
    code: "CH",
    name: "countries.CH",
  },
  {
    code: "SY",
    name: "countries.SY",
  },
  {
    code: "TW",
    name: "countries.TW",
  },
  {
    code: "TJ",
    name: "countries.TJ",
  },
  {
    code: "TZ",
    name: "countries.TZ",
  },
  {
    code: "TH",
    name: "countries.TH",
  },
  {
    code: "TG",
    name: "countries.TG",
  },
  {
    code: "TO",
    name: "countries.TO",
  },
  {
    code: "TT",
    name: "countries.TT",
  },
  {
    code: "TN",
    name: "countries.TN",
  },
  {
    code: "TR",
    name: "countries.TR",
  },
  {
    code: "TM",
    name: "countries.TM",
  },
  {
    code: "TC",
    name: "countries.TC",
  },
  {
    code: "TV",
    name: "countries.TV",
  },
  {
    code: "UG",
    name: "countries.UG",
  },
  {
    code: "UA",
    name: "countries.UA",
  },
  {
    code: "AE",
    name: "countries.AE",
  },
  {
    code: "GB",
    name: "countries.GB",
  },
  {
    code: "US",
    name: "countries.US",
  },
  {
    code: "UY",
    name: "countries.UY",
  },
  {
    code: "UZ",
    name: "countries.UZ",
  },
  {
    code: "VU",
    name: "countries.VU",
  },
  {
    code: "VA",
    name: "countries.VA",
  },
  {
    code: "VE",
    name: "countries.VE",
  },
  {
    code: "VN",
    name: "countries.VN",
  },
  {
    code: "VC",
    name: "countries.VC",
  },
  {
    code: "PS",
    name: "countries.PS",
  },
  {
    code: "YE",
    name: "countries.YE",
  },
  {
    code: "ZM",
    name: "countries.ZM",
  },
  {
    code: "ZW",
    name: "countries.ZW",
  },
];

export const stateList = [
  {
    id: "1",
    name: "Andaman and Nicobar Islands",
    country_code: "IN",
  },
  {
    id: "2",
    name: "Andhra Pradesh",
    country_code: "IN",
  },
  {
    id: "3",
    name: "Arunachal Pradesh",
    country_code: "IN",
  },
  {
    id: "4",
    name: "Assam",
    country_code: "IN",
  },
  {
    id: "5",
    name: "Bihar",
    country_code: "IN",
  },
  {
    id: "6",
    name: "Chandigarh",
    country_code: "IN",
  },
  {
    id: "7",
    name: "Chhattisgarh",
    country_code: "IN",
  },
  {
    id: "8",
    name: "Dadra and Nagar Haveli",
    country_code: "IN",
  },
  {
    id: "9",
    name: "Daman and Diu",
    country_code: "IN",
  },
  {
    id: "10",
    name: "Delhi",
    country_code: "IN",
  },
  {
    id: "11",
    name: "Goa",
    country_code: "IN",
  },
  {
    id: "12",
    name: "Gujarat",
    country_code: "IN",
  },
  {
    id: "13",
    name: "Haryana",
    country_code: "IN",
  },
  {
    id: "14",
    name: "Himachal Pradesh",
    country_code: "IN",
  },
  {
    id: "15",
    name: "Jammu and Kashmir",
    country_code: "IN",
  },
  {
    id: "16",
    name: "Jharkhand",
    country_code: "IN",
  },
  {
    id: "17",
    name: "Karnataka",
    country_code: "IN",
  },
  {
    id: "19",
    name: "Kerala",
    country_code: "IN",
  },
  {
    id: "20",
    name: "Lakshadweep",
    country_code: "IN",
  },
  {
    id: "21",
    name: "Madhya Pradesh",
    country_code: "IN",
  },
  {
    id: "22",
    name: "Maharashtra",
    country_code: "IN",
  },
  {
    id: "23",
    name: "Manipur",
    country_code: "IN",
  },
  {
    id: "24",
    name: "Meghalaya",
    country_code: "IN",
  },
  {
    id: "25",
    name: "Mizoram",
    country_code: "IN",
  },
  {
    id: "26",
    name: "Nagaland",
    country_code: "IN",
  },
  {
    id: "29",
    name: "Odisha",
    country_code: "IN",
  },
  {
    id: "31",
    name: "Pondicherry",
    country_code: "IN",
  },
  {
    id: "32",
    name: "Punjab",
    country_code: "IN",
  },
  {
    id: "33",
    name: "Rajasthan",
    country_code: "IN",
  },
  {
    id: "34",
    name: "Sikkim",
    country_code: "IN",
  },
  {
    id: "35",
    name: "Tamil Nadu",
    country_code: "IN",
  },
  {
    id: "36",
    name: "Telangana",
    country_code: "IN",
  },
  {
    id: "37",
    name: "Tripura",
    country_code: "IN",
  },
  {
    id: "38",
    name: "Uttar Pradesh",
    country_code: "IN",
  },
  {
    id: "39",
    name: "Uttarakhand",
    country_code: "IN",
  },
  {
    id: "41",
    name: "West Bengal",
    country_code: "IN",
  },
  {
    id: "42",
    name: "Badakhshan",
    country_code: "AF",
  },
  {
    id: "43",
    name: "Badgis",
    country_code: "AF",
  },
  {
    id: "44",
    name: "Baglan",
    country_code: "AF",
  },
  {
    id: "45",
    name: "Balkh",
    country_code: "AF",
  },
  {
    id: "46",
    name: "Bamiyan",
    country_code: "AF",
  },
  {
    id: "47",
    name: "Farah",
    country_code: "AF",
  },
  {
    id: "48",
    name: "Faryab",
    country_code: "AF",
  },
  {
    id: "49",
    name: "Gawr",
    country_code: "AF",
  },
  {
    id: "50",
    name: "Gazni",
    country_code: "AF",
  },
  {
    id: "51",
    name: "Herat",
    country_code: "AF",
  },
  {
    id: "52",
    name: "Hilmand",
    country_code: "AF",
  },
  {
    id: "53",
    name: "Jawzjan",
    country_code: "AF",
  },
  {
    id: "54",
    name: "Kabul",
    country_code: "AF",
  },
  {
    id: "55",
    name: "Kapisa",
    country_code: "AF",
  },
  {
    id: "56",
    name: "Khawst",
    country_code: "AF",
  },
  {
    id: "57",
    name: "Kunar",
    country_code: "AF",
  },
  {
    id: "58",
    name: "Lagman",
    country_code: "AF",
  },
  {
    id: "59",
    name: "Lawghar",
    country_code: "AF",
  },
  {
    id: "60",
    name: "Nangarhar",
    country_code: "AF",
  },
  {
    id: "61",
    name: "Nimruz",
    country_code: "AF",
  },
  {
    id: "62",
    name: "Nuristan",
    country_code: "AF",
  },
  {
    id: "63",
    name: "Paktika",
    country_code: "AF",
  },
  {
    id: "64",
    name: "Paktiya",
    country_code: "AF",
  },
  {
    id: "65",
    name: "Parwan",
    country_code: "AF",
  },
  {
    id: "66",
    name: "Qandahar",
    country_code: "AF",
  },
  {
    id: "67",
    name: "Qunduz",
    country_code: "AF",
  },
  {
    id: "68",
    name: "Samangan",
    country_code: "AF",
  },
  {
    id: "69",
    name: "Sar-e Pul",
    country_code: "AF",
  },
  {
    id: "70",
    name: "Takhar",
    country_code: "AF",
  },
  {
    id: "71",
    name: "Uruzgan",
    country_code: "AF",
  },
  {
    id: "72",
    name: "Wardag",
    country_code: "AF",
  },
  {
    id: "73",
    name: "Zabul",
    country_code: "AF",
  },
  {
    id: "74",
    name: "Berat",
    country_code: "AL",
  },
  {
    id: "75",
    name: "Bulqize",
    country_code: "AL",
  },
  {
    id: "76",
    name: "Delvine",
    country_code: "AL",
  },
  {
    id: "77",
    name: "Devoll",
    country_code: "AL",
  },
  {
    id: "78",
    name: "Dibre",
    country_code: "AL",
  },
  {
    id: "79",
    name: "Durres",
    country_code: "AL",
  },
  {
    id: "80",
    name: "Elbasan",
    country_code: "AL",
  },
  {
    id: "81",
    name: "Fier",
    country_code: "AL",
  },
  {
    id: "82",
    name: "Gjirokaster",
    country_code: "AL",
  },
  {
    id: "83",
    name: "Gramsh",
    country_code: "AL",
  },
  {
    id: "84",
    name: "Has",
    country_code: "AL",
  },
  {
    id: "85",
    name: "Kavaje",
    country_code: "AL",
  },
  {
    id: "86",
    name: "Kolonje",
    country_code: "AL",
  },
  {
    id: "87",
    name: "Korce",
    country_code: "AL",
  },
  {
    id: "88",
    name: "Kruje",
    country_code: "AL",
  },
  {
    id: "89",
    name: "Kucove",
    country_code: "AL",
  },
  {
    id: "90",
    name: "Kukes",
    country_code: "AL",
  },
  {
    id: "91",
    name: "Kurbin",
    country_code: "AL",
  },
  {
    id: "92",
    name: "Lezhe",
    country_code: "AL",
  },
  {
    id: "93",
    name: "Librazhd",
    country_code: "AL",
  },
  {
    id: "94",
    name: "Lushnje",
    country_code: "AL",
  },
  {
    id: "95",
    name: "Mallakaster",
    country_code: "AL",
  },
  {
    id: "96",
    name: "Malsi e Madhe",
    country_code: "AL",
  },
  {
    id: "97",
    name: "Mat",
    country_code: "AL",
  },
  {
    id: "98",
    name: "Mirdite",
    country_code: "AL",
  },
  {
    id: "99",
    name: "Peqin",
    country_code: "AL",
  },
  {
    id: "100",
    name: "Permet",
    country_code: "AL",
  },
  {
    id: "101",
    name: "Pogradec",
    country_code: "AL",
  },
  {
    id: "102",
    name: "Puke",
    country_code: "AL",
  },
  {
    id: "103",
    name: "Sarande",
    country_code: "AL",
  },
  {
    id: "104",
    name: "Shkoder",
    country_code: "AL",
  },
  {
    id: "105",
    name: "Skrapar",
    country_code: "AL",
  },
  {
    id: "106",
    name: "Tepelene",
    country_code: "AL",
  },
  {
    id: "107",
    name: "Tirane",
    country_code: "AL",
  },
  {
    id: "108",
    name: "Tropoje",
    country_code: "AL",
  },
  {
    id: "109",
    name: "Vlore",
    country_code: "AL",
  },
  {
    id: "110",
    name: "Ayn Daflah",
    country_code: "DZ",
  },
  {
    id: "111",
    name: "Ayn Tamushanat",
    country_code: "DZ",
  },
  {
    id: "112",
    name: "Adrar",
    country_code: "DZ",
  },
  {
    id: "113",
    name: "Algiers",
    country_code: "DZ",
  },
  {
    id: "114",
    name: "Annabah",
    country_code: "DZ",
  },
  {
    id: "115",
    name: "Bashshar",
    country_code: "DZ",
  },
  {
    id: "116",
    name: "Batnah",
    country_code: "DZ",
  },
  {
    id: "117",
    name: "Bijayah",
    country_code: "DZ",
  },
  {
    id: "118",
    name: "Biskrah",
    country_code: "DZ",
  },
  {
    id: "119",
    name: "Blidah",
    country_code: "DZ",
  },
  {
    id: "120",
    name: "Buirah",
    country_code: "DZ",
  },
  {
    id: "121",
    name: "Bumardas",
    country_code: "DZ",
  },
  {
    id: "122",
    name: "Burj Bu Arririj",
    country_code: "DZ",
  },
  {
    id: "123",
    name: "Ghalizan",
    country_code: "DZ",
  },
  {
    id: "124",
    name: "Ghardayah",
    country_code: "DZ",
  },
  {
    id: "125",
    name: "Ilizi",
    country_code: "DZ",
  },
  {
    id: "126",
    name: "Jijili",
    country_code: "DZ",
  },
  {
    id: "127",
    name: "Jilfah",
    country_code: "DZ",
  },
  {
    id: "128",
    name: "Khanshalah",
    country_code: "DZ",
  },
  {
    id: "129",
    name: "Masilah",
    country_code: "DZ",
  },
  {
    id: "130",
    name: "Midyah",
    country_code: "DZ",
  },
  {
    id: "131",
    name: "Milah",
    country_code: "DZ",
  },
  {
    id: "132",
    name: "Muaskar",
    country_code: "DZ",
  },
  {
    id: "133",
    name: "Mustaghanam",
    country_code: "DZ",
  },
  {
    id: "134",
    name: "Naama",
    country_code: "DZ",
  },
  {
    id: "135",
    name: "Oran",
    country_code: "DZ",
  },
  {
    id: "136",
    name: "Ouargla",
    country_code: "DZ",
  },
  {
    id: "137",
    name: "Qalmah",
    country_code: "DZ",
  },
  {
    id: "138",
    name: "Qustantinah",
    country_code: "DZ",
  },
  {
    id: "139",
    name: "Sakikdah",
    country_code: "DZ",
  },
  {
    id: "140",
    name: "Satif",
    country_code: "DZ",
  },
  {
    id: "141",
    name: "Sayda",
    country_code: "DZ",
  },
  {
    id: "142",
    name: "Sidi ban-al-''Abbas",
    country_code: "DZ",
  },
  {
    id: "143",
    name: "Suq Ahras",
    country_code: "DZ",
  },
  {
    id: "144",
    name: "Tamanghasat",
    country_code: "DZ",
  },
  {
    id: "145",
    name: "Tibazah",
    country_code: "DZ",
  },
  {
    id: "146",
    name: "Tibissah",
    country_code: "DZ",
  },
  {
    id: "147",
    name: "Tilimsan",
    country_code: "DZ",
  },
  {
    id: "148",
    name: "Tinduf",
    country_code: "DZ",
  },
  {
    id: "149",
    name: "Tisamsilt",
    country_code: "DZ",
  },
  {
    id: "150",
    name: "Tiyarat",
    country_code: "DZ",
  },
  {
    id: "151",
    name: "Tizi Wazu",
    country_code: "DZ",
  },
  {
    id: "152",
    name: "Umm-al-Bawaghi",
    country_code: "DZ",
  },
  {
    id: "153",
    name: "Wahran",
    country_code: "DZ",
  },
  {
    id: "154",
    name: "Warqla",
    country_code: "DZ",
  },
  {
    id: "155",
    name: "Wilaya d Alger",
    country_code: "DZ",
  },
  {
    id: "156",
    name: "Wilaya de Bejaia",
    country_code: "DZ",
  },
  {
    id: "157",
    name: "Wilaya de Constantine",
    country_code: "DZ",
  },
  {
    id: "158",
    name: "al-Aghwat",
    country_code: "DZ",
  },
  {
    id: "159",
    name: "al-Bayadh",
    country_code: "DZ",
  },
  {
    id: "160",
    name: "al-Jaza''ir",
    country_code: "DZ",
  },
  {
    id: "161",
    name: "al-Wad",
    country_code: "DZ",
  },
  {
    id: "162",
    name: "ash-Shalif",
    country_code: "DZ",
  },
  {
    id: "163",
    name: "at-Tarif",
    country_code: "DZ",
  },
  {
    id: "168",
    name: "Andorra la Vella",
    country_code: "AD",
  },
  {
    id: "169",
    name: "Canillo",
    country_code: "AD",
  },
  {
    id: "170",
    name: "Encamp",
    country_code: "AD",
  },
  {
    id: "171",
    name: "La Massana",
    country_code: "AD",
  },
  {
    id: "172",
    name: "Les Escaldes",
    country_code: "AD",
  },
  {
    id: "173",
    name: "Ordino",
    country_code: "AD",
  },
  {
    id: "174",
    name: "Sant Julia de Loria",
    country_code: "AD",
  },
  {
    id: "175",
    name: "Bengo",
    country_code: "AO",
  },
  {
    id: "176",
    name: "Benguela",
    country_code: "AO",
  },
  {
    id: "177",
    name: "Bie",
    country_code: "AO",
  },
  {
    id: "178",
    name: "Cabinda",
    country_code: "AO",
  },
  {
    id: "179",
    name: "Cunene",
    country_code: "AO",
  },
  {
    id: "180",
    name: "Huambo",
    country_code: "AO",
  },
  {
    id: "181",
    name: "Huila",
    country_code: "AO",
  },
  {
    id: "182",
    name: "Kuando-Kubango",
    country_code: "AO",
  },
  {
    id: "183",
    name: "Kwanza Norte",
    country_code: "AO",
  },
  {
    id: "184",
    name: "Kwanza Sul",
    country_code: "AO",
  },
  {
    id: "185",
    name: "Luanda",
    country_code: "AO",
  },
  {
    id: "186",
    name: "Lunda Norte",
    country_code: "AO",
  },
  {
    id: "187",
    name: "Lunda Sul",
    country_code: "AO",
  },
  {
    id: "188",
    name: "Malanje",
    country_code: "AO",
  },
  {
    id: "189",
    name: "Moxico",
    country_code: "AO",
  },
  {
    id: "190",
    name: "Namibe",
    country_code: "AO",
  },
  {
    id: "191",
    name: "Uige",
    country_code: "AO",
  },
  {
    id: "192",
    name: "Zaire",
    country_code: "AO",
  },
  {
    id: "201",
    name: "Barbuda",
    country_code: "AG",
  },
  {
    id: "202",
    name: "Saint George",
    country_code: "AG",
  },
  {
    id: "203",
    name: "Saint John",
    country_code: "AG",
  },
  {
    id: "204",
    name: "Saint Mary",
    country_code: "AG",
  },
  {
    id: "205",
    name: "Saint Paul",
    country_code: "AG",
  },
  {
    id: "206",
    name: "Saint Peter",
    country_code: "AG",
  },
  {
    id: "207",
    name: "Saint Philip",
    country_code: "AG",
  },
  {
    id: "208",
    name: "Buenos Aires",
    country_code: "AR",
  },
  {
    id: "209",
    name: "Catamarca",
    country_code: "AR",
  },
  {
    id: "210",
    name: "Chaco",
    country_code: "AR",
  },
  {
    id: "211",
    name: "Chubut",
    country_code: "AR",
  },
  {
    id: "212",
    name: "Cordoba",
    country_code: "AR",
  },
  {
    id: "213",
    name: "Corrientes",
    country_code: "AR",
  },
  {
    id: "214",
    name: "Distrito Federal",
    country_code: "AR",
  },
  {
    id: "215",
    name: "Entre Rios",
    country_code: "AR",
  },
  {
    id: "216",
    name: "Formosa",
    country_code: "AR",
  },
  {
    id: "217",
    name: "Jujuy",
    country_code: "AR",
  },
  {
    id: "218",
    name: "La Pampa",
    country_code: "AR",
  },
  {
    id: "219",
    name: "La Rioja",
    country_code: "AR",
  },
  {
    id: "220",
    name: "Mendoza",
    country_code: "AR",
  },
  {
    id: "221",
    name: "Misiones",
    country_code: "AR",
  },
  {
    id: "222",
    name: "Neuquen",
    country_code: "AR",
  },
  {
    id: "223",
    name: "Rio Negro",
    country_code: "AR",
  },
  {
    id: "224",
    name: "Salta",
    country_code: "AR",
  },
  {
    id: "225",
    name: "San Juan",
    country_code: "AR",
  },
  {
    id: "226",
    name: "San Luis",
    country_code: "AR",
  },
  {
    id: "227",
    name: "Santa Cruz",
    country_code: "AR",
  },
  {
    id: "228",
    name: "Santa Fe",
    country_code: "AR",
  },
  {
    id: "229",
    name: "Santiago del Estero",
    country_code: "AR",
  },
  {
    id: "230",
    name: "Tierra del Fuego",
    country_code: "AR",
  },
  {
    id: "231",
    name: "Tucuman",
    country_code: "AR",
  },
  {
    id: "232",
    name: "Aragatsotn",
    country_code: "AM",
  },
  {
    id: "233",
    name: "Ararat",
    country_code: "AM",
  },
  {
    id: "234",
    name: "Armavir",
    country_code: "AM",
  },
  {
    id: "235",
    name: "Gegharkunik",
    country_code: "AM",
  },
  {
    id: "236",
    name: "Kotaik",
    country_code: "AM",
  },
  {
    id: "237",
    name: "Lori",
    country_code: "AM",
  },
  {
    id: "238",
    name: "Shirak",
    country_code: "AM",
  },
  {
    id: "239",
    name: "Stepanakert",
    country_code: "AM",
  },
  {
    id: "240",
    name: "Syunik",
    country_code: "AM",
  },
  {
    id: "241",
    name: "Tavush",
    country_code: "AM",
  },
  {
    id: "242",
    name: "Vayots Dzor",
    country_code: "AM",
  },
  {
    id: "243",
    name: "Yerevan",
    country_code: "AM",
  },
  {
    id: "245",
    name: "Auckland",
    country_code: "AU",
  },
  {
    id: "246",
    name: "Australian Capital Territory",
    country_code: "AU",
  },
  {
    id: "247",
    name: "Balgowlah",
    country_code: "AU",
  },
  {
    id: "248",
    name: "Balmain",
    country_code: "AU",
  },
  {
    id: "249",
    name: "Bankstown",
    country_code: "AU",
  },
  {
    id: "250",
    name: "Baulkham Hills",
    country_code: "AU",
  },
  {
    id: "251",
    name: "Bonnet Bay",
    country_code: "AU",
  },
  {
    id: "252",
    name: "Camberwell",
    country_code: "AU",
  },
  {
    id: "253",
    name: "Carole Park",
    country_code: "AU",
  },
  {
    id: "254",
    name: "Castle Hill",
    country_code: "AU",
  },
  {
    id: "255",
    name: "Caulfield",
    country_code: "AU",
  },
  {
    id: "256",
    name: "Chatswood",
    country_code: "AU",
  },
  {
    id: "257",
    name: "Cheltenham",
    country_code: "AU",
  },
  {
    id: "258",
    name: "Cherrybrook",
    country_code: "AU",
  },
  {
    id: "259",
    name: "Clayton",
    country_code: "AU",
  },
  {
    id: "260",
    name: "Collingwood",
    country_code: "AU",
  },
  {
    id: "261",
    name: "Frenchs Forest",
    country_code: "AU",
  },
  {
    id: "262",
    name: "Hawthorn",
    country_code: "AU",
  },
  {
    id: "263",
    name: "Jannnali",
    country_code: "AU",
  },
  {
    id: "264",
    name: "Knoxfield",
    country_code: "AU",
  },
  {
    id: "265",
    name: "Melbourne",
    country_code: "AU",
  },
  {
    id: "266",
    name: "New South Wales",
    country_code: "AU",
  },
  {
    id: "267",
    name: "Northern Territory",
    country_code: "AU",
  },
  {
    id: "268",
    name: "Perth",
    country_code: "AU",
  },
  {
    id: "269",
    name: "Queensland",
    country_code: "AU",
  },
  {
    id: "270",
    name: "South Australia",
    country_code: "AU",
  },
  {
    id: "271",
    name: "Tasmania",
    country_code: "AU",
  },
  {
    id: "272",
    name: "Templestowe",
    country_code: "AU",
  },
  {
    id: "273",
    name: "Victoria",
    country_code: "AU",
  },
  {
    id: "274",
    name: "Werribee south",
    country_code: "AU",
  },
  {
    id: "275",
    name: "Western Australia",
    country_code: "AU",
  },
  {
    id: "276",
    name: "Wheeler",
    country_code: "AU",
  },
  {
    id: "277",
    name: "Bundesland Salzburg",
    country_code: "AT",
  },
  {
    id: "278",
    name: "Bundesland Steiermark",
    country_code: "AT",
  },
  {
    id: "279",
    name: "Bundesland Tirol",
    country_code: "AT",
  },
  {
    id: "280",
    name: "Burgenland",
    country_code: "AT",
  },
  {
    id: "281",
    name: "Carinthia",
    country_code: "AT",
  },
  {
    id: "282",
    name: "Karnten",
    country_code: "AT",
  },
  {
    id: "283",
    name: "Liezen",
    country_code: "AT",
  },
  {
    id: "284",
    name: "Lower Austria",
    country_code: "AT",
  },
  {
    id: "285",
    name: "Niederosterreich",
    country_code: "AT",
  },
  {
    id: "286",
    name: "Oberosterreich",
    country_code: "AT",
  },
  {
    id: "287",
    name: "Salzburg",
    country_code: "AT",
  },
  {
    id: "288",
    name: "Schleswig-Holstein",
    country_code: "AT",
  },
  {
    id: "289",
    name: "Steiermark",
    country_code: "AT",
  },
  {
    id: "290",
    name: "Styria",
    country_code: "AT",
  },
  {
    id: "291",
    name: "Tirol",
    country_code: "AT",
  },
  {
    id: "292",
    name: "Upper Austria",
    country_code: "AT",
  },
  {
    id: "293",
    name: "Vorarlberg",
    country_code: "AT",
  },
  {
    id: "294",
    name: "Wien",
    country_code: "AT",
  },
  {
    id: "295",
    name: "Abseron",
    country_code: "AZ",
  },
  {
    id: "296",
    name: "Baki Sahari",
    country_code: "AZ",
  },
  {
    id: "297",
    name: "Ganca",
    country_code: "AZ",
  },
  {
    id: "298",
    name: "Ganja",
    country_code: "AZ",
  },
  {
    id: "299",
    name: "Kalbacar",
    country_code: "AZ",
  },
  {
    id: "300",
    name: "Lankaran",
    country_code: "AZ",
  },
  {
    id: "301",
    name: "Mil-Qarabax",
    country_code: "AZ",
  },
  {
    id: "302",
    name: "Mugan-Salyan",
    country_code: "AZ",
  },
  {
    id: "303",
    name: "Nagorni-Qarabax",
    country_code: "AZ",
  },
  {
    id: "304",
    name: "Naxcivan",
    country_code: "AZ",
  },
  {
    id: "305",
    name: "Priaraks",
    country_code: "AZ",
  },
  {
    id: "306",
    name: "Qazax",
    country_code: "AZ",
  },
  {
    id: "307",
    name: "Saki",
    country_code: "AZ",
  },
  {
    id: "308",
    name: "Sirvan",
    country_code: "AZ",
  },
  {
    id: "309",
    name: "Xacmaz",
    country_code: "AZ",
  },
  {
    id: "310",
    name: "Abaco",
    country_code: "BS",
  },
  {
    id: "311",
    name: "Acklins Island",
    country_code: "BS",
  },
  {
    id: "312",
    name: "Andros",
    country_code: "BS",
  },
  {
    id: "313",
    name: "Berry Islands",
    country_code: "BS",
  },
  {
    id: "314",
    name: "Biminis",
    country_code: "BS",
  },
  {
    id: "315",
    name: "Cat Island",
    country_code: "BS",
  },
  {
    id: "316",
    name: "Crooked Island",
    country_code: "BS",
  },
  {
    id: "317",
    name: "Eleuthera",
    country_code: "BS",
  },
  {
    id: "318",
    name: "Exuma and Cays",
    country_code: "BS",
  },
  {
    id: "319",
    name: "Grand Bahama",
    country_code: "BS",
  },
  {
    id: "320",
    name: "Inagua Islands",
    country_code: "BS",
  },
  {
    id: "321",
    name: "Long Island",
    country_code: "BS",
  },
  {
    id: "322",
    name: "Mayaguana",
    country_code: "BS",
  },
  {
    id: "323",
    name: "New Providence",
    country_code: "BS",
  },
  {
    id: "324",
    name: "Ragged Island",
    country_code: "BS",
  },
  {
    id: "325",
    name: "Rum Cay",
    country_code: "BS",
  },
  {
    id: "326",
    name: "San Salvador",
    country_code: "BS",
  },
  {
    id: "327",
    name: "Isa",
    country_code: "BH",
  },
  {
    id: "328",
    name: "Badiyah",
    country_code: "BH",
  },
  {
    id: "329",
    name: "Hidd",
    country_code: "BH",
  },
  {
    id: "330",
    name: "Jidd Hafs",
    country_code: "BH",
  },
  {
    id: "331",
    name: "Mahama",
    country_code: "BH",
  },
  {
    id: "332",
    name: "Manama",
    country_code: "BH",
  },
  {
    id: "333",
    name: "Sitrah",
    country_code: "BH",
  },
  {
    id: "334",
    name: "al-Manamah",
    country_code: "BH",
  },
  {
    id: "335",
    name: "al-Muharraq",
    country_code: "BH",
  },
  {
    id: "336",
    name: "ar-Rifa''a",
    country_code: "BH",
  },
  {
    id: "337",
    name: "Bagar Hat",
    country_code: "BD",
  },
  {
    id: "338",
    name: "Bandarban",
    country_code: "BD",
  },
  {
    id: "339",
    name: "Barguna",
    country_code: "BD",
  },
  {
    id: "340",
    name: "Barisal",
    country_code: "BD",
  },
  {
    id: "341",
    name: "Bhola",
    country_code: "BD",
  },
  {
    id: "342",
    name: "Bogora",
    country_code: "BD",
  },
  {
    id: "343",
    name: "Brahman Bariya",
    country_code: "BD",
  },
  {
    id: "344",
    name: "Chandpur",
    country_code: "BD",
  },
  {
    id: "345",
    name: "Chattagam",
    country_code: "BD",
  },
  {
    id: "346",
    name: "Chittagong Division",
    country_code: "BD",
  },
  {
    id: "347",
    name: "Chuadanga",
    country_code: "BD",
  },
  {
    id: "348",
    name: "Dhaka",
    country_code: "BD",
  },
  {
    id: "349",
    name: "Dinajpur",
    country_code: "BD",
  },
  {
    id: "350",
    name: "Faridpur",
    country_code: "BD",
  },
  {
    id: "351",
    name: "Feni",
    country_code: "BD",
  },
  {
    id: "352",
    name: "Gaybanda",
    country_code: "BD",
  },
  {
    id: "353",
    name: "Gazipur",
    country_code: "BD",
  },
  {
    id: "354",
    name: "Gopalganj",
    country_code: "BD",
  },
  {
    id: "355",
    name: "Habiganj",
    country_code: "BD",
  },
  {
    id: "356",
    name: "Jaipur Hat",
    country_code: "BD",
  },
  {
    id: "357",
    name: "Jamalpur",
    country_code: "BD",
  },
  {
    id: "358",
    name: "Jessor",
    country_code: "BD",
  },
  {
    id: "359",
    name: "Jhalakati",
    country_code: "BD",
  },
  {
    id: "360",
    name: "Jhanaydah",
    country_code: "BD",
  },
  {
    id: "361",
    name: "Khagrachhari",
    country_code: "BD",
  },
  {
    id: "362",
    name: "Khulna",
    country_code: "BD",
  },
  {
    id: "363",
    name: "Kishorganj",
    country_code: "BD",
  },
  {
    id: "364",
    name: "Koks Bazar",
    country_code: "BD",
  },
  {
    id: "365",
    name: "Komilla",
    country_code: "BD",
  },
  {
    id: "366",
    name: "Kurigram",
    country_code: "BD",
  },
  {
    id: "367",
    name: "Kushtiya",
    country_code: "BD",
  },
  {
    id: "368",
    name: "Lakshmipur",
    country_code: "BD",
  },
  {
    id: "369",
    name: "Lalmanir Hat",
    country_code: "BD",
  },
  {
    id: "370",
    name: "Madaripur",
    country_code: "BD",
  },
  {
    id: "371",
    name: "Magura",
    country_code: "BD",
  },
  {
    id: "372",
    name: "Maimansingh",
    country_code: "BD",
  },
  {
    id: "373",
    name: "Manikganj",
    country_code: "BD",
  },
  {
    id: "374",
    name: "Maulvi Bazar",
    country_code: "BD",
  },
  {
    id: "375",
    name: "Meherpur",
    country_code: "BD",
  },
  {
    id: "376",
    name: "Munshiganj",
    country_code: "BD",
  },
  {
    id: "377",
    name: "Naral",
    country_code: "BD",
  },
  {
    id: "378",
    name: "Narayanganj",
    country_code: "BD",
  },
  {
    id: "379",
    name: "Narsingdi",
    country_code: "BD",
  },
  {
    id: "380",
    name: "Nator",
    country_code: "BD",
  },
  {
    id: "381",
    name: "Naugaon",
    country_code: "BD",
  },
  {
    id: "382",
    name: "Nawabganj",
    country_code: "BD",
  },
  {
    id: "383",
    name: "Netrakona",
    country_code: "BD",
  },
  {
    id: "384",
    name: "Nilphamari",
    country_code: "BD",
  },
  {
    id: "385",
    name: "Noakhali",
    country_code: "BD",
  },
  {
    id: "386",
    name: "Pabna",
    country_code: "BD",
  },
  {
    id: "387",
    name: "Panchagarh",
    country_code: "BD",
  },
  {
    id: "388",
    name: "Patuakhali",
    country_code: "BD",
  },
  {
    id: "389",
    name: "Pirojpur",
    country_code: "BD",
  },
  {
    id: "390",
    name: "Rajbari",
    country_code: "BD",
  },
  {
    id: "391",
    name: "Rajshahi",
    country_code: "BD",
  },
  {
    id: "392",
    name: "Rangamati",
    country_code: "BD",
  },
  {
    id: "393",
    name: "Rangpur",
    country_code: "BD",
  },
  {
    id: "394",
    name: "Satkhira",
    country_code: "BD",
  },
  {
    id: "395",
    name: "Shariatpur",
    country_code: "BD",
  },
  {
    id: "396",
    name: "Sherpur",
    country_code: "BD",
  },
  {
    id: "397",
    name: "Silhat",
    country_code: "BD",
  },
  {
    id: "398",
    name: "Sirajganj",
    country_code: "BD",
  },
  {
    id: "399",
    name: "Sunamganj",
    country_code: "BD",
  },
  {
    id: "400",
    name: "Tangayal",
    country_code: "BD",
  },
  {
    id: "401",
    name: "Thakurgaon",
    country_code: "BD",
  },
  {
    id: "402",
    name: "Christ Church",
    country_code: "BB",
  },
  {
    id: "403",
    name: "Saint Andrew",
    country_code: "BB",
  },
  {
    id: "404",
    name: "Saint George",
    country_code: "BB",
  },
  {
    id: "405",
    name: "Saint James",
    country_code: "BB",
  },
  {
    id: "406",
    name: "Saint John",
    country_code: "BB",
  },
  {
    id: "407",
    name: "Saint Joseph",
    country_code: "BB",
  },
  {
    id: "408",
    name: "Saint Lucy",
    country_code: "BB",
  },
  {
    id: "409",
    name: "Saint Michael",
    country_code: "BB",
  },
  {
    id: "410",
    name: "Saint Peter",
    country_code: "BB",
  },
  {
    id: "411",
    name: "Saint Philip",
    country_code: "BB",
  },
  {
    id: "412",
    name: "Saint Thomas",
    country_code: "BB",
  },
  {
    id: "413",
    name: "Brest",
    country_code: "BY",
  },
  {
    id: "414",
    name: "Homjel",
    country_code: "BY",
  },
  {
    id: "415",
    name: "Hrodna",
    country_code: "BY",
  },
  {
    id: "416",
    name: "Mahiljow",
    country_code: "BY",
  },
  {
    id: "417",
    name: "Mahilyowskaya Voblasts",
    country_code: "BY",
  },
  {
    id: "418",
    name: "Minsk",
    country_code: "BY",
  },
  {
    id: "419",
    name: "Minskaja Voblasts",
    country_code: "BY",
  },
  {
    id: "420",
    name: "Petrik",
    country_code: "BY",
  },
  {
    id: "421",
    name: "Vicebsk",
    country_code: "BY",
  },
  {
    id: "422",
    name: "Antwerpen",
    country_code: "BE",
  },
  {
    id: "423",
    name: "Berchem",
    country_code: "BE",
  },
  {
    id: "424",
    name: "Brabant",
    country_code: "BE",
  },
  {
    id: "425",
    name: "Brabant Wallon",
    country_code: "BE",
  },
  {
    id: "426",
    name: "Brussel",
    country_code: "BE",
  },
  {
    id: "427",
    name: "East Flanders",
    country_code: "BE",
  },
  {
    id: "428",
    name: "Hainaut",
    country_code: "BE",
  },
  {
    id: "429",
    name: "Liege",
    country_code: "BE",
  },
  {
    id: "430",
    name: "Limburg",
    country_code: "BE",
  },
  {
    id: "431",
    name: "Luxembourg",
    country_code: "BE",
  },
  {
    id: "432",
    name: "Namur",
    country_code: "BE",
  },
  {
    id: "433",
    name: "Ontario",
    country_code: "BE",
  },
  {
    id: "434",
    name: "Oost-Vlaanderen",
    country_code: "BE",
  },
  {
    id: "435",
    name: "Provincie Brabant",
    country_code: "BE",
  },
  {
    id: "436",
    name: "Vlaams-Brabant",
    country_code: "BE",
  },
  {
    id: "437",
    name: "Wallonne",
    country_code: "BE",
  },
  {
    id: "438",
    name: "West-Vlaanderen",
    country_code: "BE",
  },
  {
    id: "439",
    name: "Belize",
    country_code: "BZ",
  },
  {
    id: "440",
    name: "Cayo",
    country_code: "BZ",
  },
  {
    id: "441",
    name: "Corozal",
    country_code: "BZ",
  },
  {
    id: "442",
    name: "Orange Walk",
    country_code: "BZ",
  },
  {
    id: "443",
    name: "Stann Creek",
    country_code: "BZ",
  },
  {
    id: "444",
    name: "Toledo",
    country_code: "BZ",
  },
  {
    id: "445",
    name: "Alibori",
    country_code: "BJ",
  },
  {
    id: "446",
    name: "Atacora",
    country_code: "BJ",
  },
  {
    id: "447",
    name: "Atlantique",
    country_code: "BJ",
  },
  {
    id: "448",
    name: "Borgou",
    country_code: "BJ",
  },
  {
    id: "449",
    name: "Collines",
    country_code: "BJ",
  },
  {
    id: "450",
    name: "Couffo",
    country_code: "BJ",
  },
  {
    id: "451",
    name: "Donga",
    country_code: "BJ",
  },
  {
    id: "452",
    name: "Littoral",
    country_code: "BJ",
  },
  {
    id: "453",
    name: "Mono",
    country_code: "BJ",
  },
  {
    id: "454",
    name: "Oueme",
    country_code: "BJ",
  },
  {
    id: "455",
    name: "Plateau",
    country_code: "BJ",
  },
  {
    id: "456",
    name: "Zou",
    country_code: "BJ",
  },
  {
    id: "459",
    name: "Bumthang",
    country_code: "BT",
  },
  {
    id: "460",
    name: "Chhukha",
    country_code: "BT",
  },
  {
    id: "461",
    name: "Chirang",
    country_code: "BT",
  },
  {
    id: "462",
    name: "Daga",
    country_code: "BT",
  },
  {
    id: "463",
    name: "Geylegphug",
    country_code: "BT",
  },
  {
    id: "464",
    name: "Ha",
    country_code: "BT",
  },
  {
    id: "465",
    name: "Lhuntshi",
    country_code: "BT",
  },
  {
    id: "466",
    name: "Mongar",
    country_code: "BT",
  },
  {
    id: "467",
    name: "Pemagatsel",
    country_code: "BT",
  },
  {
    id: "468",
    name: "Punakha",
    country_code: "BT",
  },
  {
    id: "469",
    name: "Rinpung",
    country_code: "BT",
  },
  {
    id: "470",
    name: "Samchi",
    country_code: "BT",
  },
  {
    id: "471",
    name: "Samdrup Jongkhar",
    country_code: "BT",
  },
  {
    id: "472",
    name: "Shemgang",
    country_code: "BT",
  },
  {
    id: "473",
    name: "Tashigang",
    country_code: "BT",
  },
  {
    id: "474",
    name: "Timphu",
    country_code: "BT",
  },
  {
    id: "475",
    name: "Tongsa",
    country_code: "BT",
  },
  {
    id: "476",
    name: "Wangdiphodrang",
    country_code: "BT",
  },
  {
    id: "477",
    name: "Beni",
    country_code: "BO",
  },
  {
    id: "478",
    name: "Chuquisaca",
    country_code: "BO",
  },
  {
    id: "479",
    name: "Cochabamba",
    country_code: "BO",
  },
  {
    id: "480",
    name: "La Paz",
    country_code: "BO",
  },
  {
    id: "481",
    name: "Oruro",
    country_code: "BO",
  },
  {
    id: "482",
    name: "Pando",
    country_code: "BO",
  },
  {
    id: "483",
    name: "Potosi",
    country_code: "BO",
  },
  {
    id: "484",
    name: "Santa Cruz",
    country_code: "BO",
  },
  {
    id: "485",
    name: "Tarija",
    country_code: "BO",
  },
  {
    id: "486",
    name: "Federacija Bosna i Hercegovina",
    country_code: "BA",
  },
  {
    id: "487",
    name: "Republika Srpska",
    country_code: "BA",
  },
  {
    id: "488",
    name: "Central Bobonong",
    country_code: "BW",
  },
  {
    id: "489",
    name: "Central Boteti",
    country_code: "BW",
  },
  {
    id: "490",
    name: "Central Mahalapye",
    country_code: "BW",
  },
  {
    id: "491",
    name: "Central Serowe-Palapye",
    country_code: "BW",
  },
  {
    id: "492",
    name: "Central Tutume",
    country_code: "BW",
  },
  {
    id: "493",
    name: "Chobe",
    country_code: "BW",
  },
  {
    id: "494",
    name: "Francistown",
    country_code: "BW",
  },
  {
    id: "495",
    name: "Gaborone",
    country_code: "BW",
  },
  {
    id: "496",
    name: "Ghanzi",
    country_code: "BW",
  },
  {
    id: "497",
    name: "Jwaneng",
    country_code: "BW",
  },
  {
    id: "498",
    name: "Kgalagadi North",
    country_code: "BW",
  },
  {
    id: "499",
    name: "Kgalagadi South",
    country_code: "BW",
  },
  {
    id: "500",
    name: "Kgatleng",
    country_code: "BW",
  },
  {
    id: "501",
    name: "Kweneng",
    country_code: "BW",
  },
  {
    id: "502",
    name: "Lobatse",
    country_code: "BW",
  },
  {
    id: "503",
    name: "Ngamiland",
    country_code: "BW",
  },
  {
    id: "504",
    name: "Ngwaketse",
    country_code: "BW",
  },
  {
    id: "505",
    name: "North East",
    country_code: "BW",
  },
  {
    id: "506",
    name: "Okavango",
    country_code: "BW",
  },
  {
    id: "507",
    name: "Orapa",
    country_code: "BW",
  },
  {
    id: "508",
    name: "Selibe Phikwe",
    country_code: "BW",
  },
  {
    id: "509",
    name: "South East",
    country_code: "BW",
  },
  {
    id: "510",
    name: "Sowa",
    country_code: "BW",
  },
  {
    id: "512",
    name: "Acre",
    country_code: "BR",
  },
  {
    id: "513",
    name: "Alagoas",
    country_code: "BR",
  },
  {
    id: "514",
    name: "Amapa",
    country_code: "BR",
  },
  {
    id: "515",
    name: "Amazonas",
    country_code: "BR",
  },
  {
    id: "516",
    name: "Bahia",
    country_code: "BR",
  },
  {
    id: "517",
    name: "Ceara",
    country_code: "BR",
  },
  {
    id: "518",
    name: "Distrito Federal",
    country_code: "BR",
  },
  {
    id: "519",
    name: "Espirito Santo",
    country_code: "BR",
  },
  {
    id: "520",
    name: "Estado de Sao Paulo",
    country_code: "BR",
  },
  {
    id: "521",
    name: "Goias",
    country_code: "BR",
  },
  {
    id: "522",
    name: "Maranhao",
    country_code: "BR",
  },
  {
    id: "523",
    name: "Mato Grosso",
    country_code: "BR",
  },
  {
    id: "524",
    name: "Mato Grosso do Sul",
    country_code: "BR",
  },
  {
    id: "525",
    name: "Minas Gerais",
    country_code: "BR",
  },
  {
    id: "526",
    name: "Para",
    country_code: "BR",
  },
  {
    id: "527",
    name: "Paraiba",
    country_code: "BR",
  },
  {
    id: "528",
    name: "Parana",
    country_code: "BR",
  },
  {
    id: "529",
    name: "Pernambuco",
    country_code: "BR",
  },
  {
    id: "530",
    name: "Piaui",
    country_code: "BR",
  },
  {
    id: "531",
    name: "Rio Grande do Norte",
    country_code: "BR",
  },
  {
    id: "532",
    name: "Rio Grande do Sul",
    country_code: "BR",
  },
  {
    id: "533",
    name: "Rio de Janeiro",
    country_code: "BR",
  },
  {
    id: "534",
    name: "Rondonia",
    country_code: "BR",
  },
  {
    id: "535",
    name: "Roraima",
    country_code: "BR",
  },
  {
    id: "536",
    name: "Santa Catarina",
    country_code: "BR",
  },
  {
    id: "537",
    name: "Sao Paulo",
    country_code: "BR",
  },
  {
    id: "538",
    name: "Sergipe",
    country_code: "BR",
  },
  {
    id: "539",
    name: "Tocantins",
    country_code: "BR",
  },
  {
    id: "541",
    name: "Belait",
    country_code: "BN",
  },
  {
    id: "542",
    name: "Brunei-Muara",
    country_code: "BN",
  },
  {
    id: "543",
    name: "Temburong",
    country_code: "BN",
  },
  {
    id: "544",
    name: "Tutong",
    country_code: "BN",
  },
  {
    id: "545",
    name: "Blagoevgrad",
    country_code: "BG",
  },
  {
    id: "546",
    name: "Burgas",
    country_code: "BG",
  },
  {
    id: "547",
    name: "Dobrich",
    country_code: "BG",
  },
  {
    id: "548",
    name: "Gabrovo",
    country_code: "BG",
  },
  {
    id: "549",
    name: "Haskovo",
    country_code: "BG",
  },
  {
    id: "550",
    name: "Jambol",
    country_code: "BG",
  },
  {
    id: "551",
    name: "Kardzhali",
    country_code: "BG",
  },
  {
    id: "552",
    name: "Kjustendil",
    country_code: "BG",
  },
  {
    id: "553",
    name: "Lovech",
    country_code: "BG",
  },
  {
    id: "554",
    name: "Montana",
    country_code: "BG",
  },
  {
    id: "555",
    name: "Oblast Sofiya-Grad",
    country_code: "BG",
  },
  {
    id: "556",
    name: "Pazardzhik",
    country_code: "BG",
  },
  {
    id: "557",
    name: "Pernik",
    country_code: "BG",
  },
  {
    id: "558",
    name: "Pleven",
    country_code: "BG",
  },
  {
    id: "559",
    name: "Plovdiv",
    country_code: "BG",
  },
  {
    id: "560",
    name: "Razgrad",
    country_code: "BG",
  },
  {
    id: "561",
    name: "Ruse",
    country_code: "BG",
  },
  {
    id: "562",
    name: "Shumen",
    country_code: "BG",
  },
  {
    id: "563",
    name: "Silistra",
    country_code: "BG",
  },
  {
    id: "564",
    name: "Sliven",
    country_code: "BG",
  },
  {
    id: "565",
    name: "Smoljan",
    country_code: "BG",
  },
  {
    id: "566",
    name: "Sofija grad",
    country_code: "BG",
  },
  {
    id: "567",
    name: "Sofijska oblast",
    country_code: "BG",
  },
  {
    id: "568",
    name: "Stara Zagora",
    country_code: "BG",
  },
  {
    id: "569",
    name: "Targovishte",
    country_code: "BG",
  },
  {
    id: "570",
    name: "Varna",
    country_code: "BG",
  },
  {
    id: "571",
    name: "Veliko Tarnovo",
    country_code: "BG",
  },
  {
    id: "572",
    name: "Vidin",
    country_code: "BG",
  },
  {
    id: "573",
    name: "Vraca",
    country_code: "BG",
  },
  {
    id: "574",
    name: "Yablaniza",
    country_code: "BG",
  },
  {
    id: "575",
    name: "Bale",
    country_code: "BF",
  },
  {
    id: "576",
    name: "Bam",
    country_code: "BF",
  },
  {
    id: "577",
    name: "Bazega",
    country_code: "BF",
  },
  {
    id: "578",
    name: "Bougouriba",
    country_code: "BF",
  },
  {
    id: "579",
    name: "Boulgou",
    country_code: "BF",
  },
  {
    id: "580",
    name: "Boulkiemde",
    country_code: "BF",
  },
  {
    id: "581",
    name: "Comoe",
    country_code: "BF",
  },
  {
    id: "582",
    name: "Ganzourgou",
    country_code: "BF",
  },
  {
    id: "583",
    name: "Gnagna",
    country_code: "BF",
  },
  {
    id: "584",
    name: "Gourma",
    country_code: "BF",
  },
  {
    id: "585",
    name: "Houet",
    country_code: "BF",
  },
  {
    id: "586",
    name: "Ioba",
    country_code: "BF",
  },
  {
    id: "587",
    name: "Kadiogo",
    country_code: "BF",
  },
  {
    id: "588",
    name: "Kenedougou",
    country_code: "BF",
  },
  {
    id: "589",
    name: "Komandjari",
    country_code: "BF",
  },
  {
    id: "590",
    name: "Kompienga",
    country_code: "BF",
  },
  {
    id: "591",
    name: "Kossi",
    country_code: "BF",
  },
  {
    id: "592",
    name: "Kouritenga",
    country_code: "BF",
  },
  {
    id: "593",
    name: "Kourweogo",
    country_code: "BF",
  },
  {
    id: "594",
    name: "Leraba",
    country_code: "BF",
  },
  {
    id: "595",
    name: "Mouhoun",
    country_code: "BF",
  },
  {
    id: "596",
    name: "Nahouri",
    country_code: "BF",
  },
  {
    id: "597",
    name: "Namentenga",
    country_code: "BF",
  },
  {
    id: "598",
    name: "Noumbiel",
    country_code: "BF",
  },
  {
    id: "599",
    name: "Oubritenga",
    country_code: "BF",
  },
  {
    id: "600",
    name: "Oudalan",
    country_code: "BF",
  },
  {
    id: "601",
    name: "Passore",
    country_code: "BF",
  },
  {
    id: "602",
    name: "Poni",
    country_code: "BF",
  },
  {
    id: "603",
    name: "Sanguie",
    country_code: "BF",
  },
  {
    id: "604",
    name: "Sanmatenga",
    country_code: "BF",
  },
  {
    id: "605",
    name: "Seno",
    country_code: "BF",
  },
  {
    id: "606",
    name: "Sissili",
    country_code: "BF",
  },
  {
    id: "607",
    name: "Soum",
    country_code: "BF",
  },
  {
    id: "608",
    name: "Sourou",
    country_code: "BF",
  },
  {
    id: "609",
    name: "Tapoa",
    country_code: "BF",
  },
  {
    id: "610",
    name: "Tuy",
    country_code: "BF",
  },
  {
    id: "611",
    name: "Yatenga",
    country_code: "BF",
  },
  {
    id: "612",
    name: "Zondoma",
    country_code: "BF",
  },
  {
    id: "613",
    name: "Zoundweogo",
    country_code: "BF",
  },
  {
    id: "614",
    name: "Bubanza",
    country_code: "BI",
  },
  {
    id: "615",
    name: "Bujumbura",
    country_code: "BI",
  },
  {
    id: "616",
    name: "Bururi",
    country_code: "BI",
  },
  {
    id: "617",
    name: "Cankuzo",
    country_code: "BI",
  },
  {
    id: "618",
    name: "Cibitoke",
    country_code: "BI",
  },
  {
    id: "619",
    name: "Gitega",
    country_code: "BI",
  },
  {
    id: "620",
    name: "Karuzi",
    country_code: "BI",
  },
  {
    id: "621",
    name: "Kayanza",
    country_code: "BI",
  },
  {
    id: "622",
    name: "Kirundo",
    country_code: "BI",
  },
  {
    id: "623",
    name: "Makamba",
    country_code: "BI",
  },
  {
    id: "624",
    name: "Muramvya",
    country_code: "BI",
  },
  {
    id: "625",
    name: "Muyinga",
    country_code: "BI",
  },
  {
    id: "626",
    name: "Ngozi",
    country_code: "BI",
  },
  {
    id: "627",
    name: "Rutana",
    country_code: "BI",
  },
  {
    id: "628",
    name: "Ruyigi",
    country_code: "BI",
  },
  {
    id: "629",
    name: "Banteay Mean Chey",
    country_code: "KH",
  },
  {
    id: "630",
    name: "Bat Dambang",
    country_code: "KH",
  },
  {
    id: "631",
    name: "Kampong Cham",
    country_code: "KH",
  },
  {
    id: "632",
    name: "Kampong Chhnang",
    country_code: "KH",
  },
  {
    id: "633",
    name: "Kampong Spoeu",
    country_code: "KH",
  },
  {
    id: "634",
    name: "Kampong Thum",
    country_code: "KH",
  },
  {
    id: "635",
    name: "Kampot",
    country_code: "KH",
  },
  {
    id: "636",
    name: "Kandal",
    country_code: "KH",
  },
  {
    id: "637",
    name: "Kaoh Kong",
    country_code: "KH",
  },
  {
    id: "638",
    name: "Kracheh",
    country_code: "KH",
  },
  {
    id: "639",
    name: "Krong Kaeb",
    country_code: "KH",
  },
  {
    id: "640",
    name: "Krong Pailin",
    country_code: "KH",
  },
  {
    id: "641",
    name: "Krong Preah Sihanouk",
    country_code: "KH",
  },
  {
    id: "642",
    name: "Mondol Kiri",
    country_code: "KH",
  },
  {
    id: "643",
    name: "Otdar Mean Chey",
    country_code: "KH",
  },
  {
    id: "644",
    name: "Phnum Penh",
    country_code: "KH",
  },
  {
    id: "645",
    name: "Pousat",
    country_code: "KH",
  },
  {
    id: "646",
    name: "Preah Vihear",
    country_code: "KH",
  },
  {
    id: "647",
    name: "Prey Veaeng",
    country_code: "KH",
  },
  {
    id: "648",
    name: "Rotanak Kiri",
    country_code: "KH",
  },
  {
    id: "649",
    name: "Siem Reab",
    country_code: "KH",
  },
  {
    id: "650",
    name: "Stueng Traeng",
    country_code: "KH",
  },
  {
    id: "651",
    name: "Svay Rieng",
    country_code: "KH",
  },
  {
    id: "652",
    name: "Takaev",
    country_code: "KH",
  },
  {
    id: "653",
    name: "Adamaoua",
    country_code: "CM",
  },
  {
    id: "654",
    name: "Centre",
    country_code: "CM",
  },
  {
    id: "655",
    name: "Est",
    country_code: "CM",
  },
  {
    id: "656",
    name: "Littoral",
    country_code: "CM",
  },
  {
    id: "657",
    name: "Nord",
    country_code: "CM",
  },
  {
    id: "658",
    name: "Nord Extreme",
    country_code: "CM",
  },
  {
    id: "659",
    name: "Nordouest",
    country_code: "CM",
  },
  {
    id: "660",
    name: "Ouest",
    country_code: "CM",
  },
  {
    id: "661",
    name: "Sud",
    country_code: "CM",
  },
  {
    id: "662",
    name: "Sudouest",
    country_code: "CM",
  },
  {
    id: "663",
    name: "Alberta",
    country_code: "CA",
  },
  {
    id: "664",
    name: "British Columbia",
    country_code: "CA",
  },
  {
    id: "665",
    name: "Manitoba",
    country_code: "CA",
  },
  {
    id: "666",
    name: "New Brunswick",
    country_code: "CA",
  },
  {
    id: "667",
    name: "Newfoundland and Labrador",
    country_code: "CA",
  },
  {
    id: "668",
    name: "Northwest Territories",
    country_code: "CA",
  },
  {
    id: "669",
    name: "Nova Scotia",
    country_code: "CA",
  },
  {
    id: "670",
    name: "Nunavut",
    country_code: "CA",
  },
  {
    id: "671",
    name: "Ontario",
    country_code: "CA",
  },
  {
    id: "672",
    name: "Prince Edward Island",
    country_code: "CA",
  },
  {
    id: "673",
    name: "Quebec",
    country_code: "CA",
  },
  {
    id: "674",
    name: "Saskatchewan",
    country_code: "CA",
  },
  {
    id: "675",
    name: "Yukon",
    country_code: "CA",
  },
  {
    id: "676",
    name: "Boavista",
    country_code: "CV",
  },
  {
    id: "677",
    name: "Brava",
    country_code: "CV",
  },
  {
    id: "678",
    name: "Fogo",
    country_code: "CV",
  },
  {
    id: "679",
    name: "Maio",
    country_code: "CV",
  },
  {
    id: "680",
    name: "Sal",
    country_code: "CV",
  },
  {
    id: "681",
    name: "Santo Antao",
    country_code: "CV",
  },
  {
    id: "682",
    name: "Sao Nicolau",
    country_code: "CV",
  },
  {
    id: "683",
    name: "Sao Tiago",
    country_code: "CV",
  },
  {
    id: "684",
    name: "Sao Vicente",
    country_code: "CV",
  },
  {
    id: "686",
    name: "Bamingui-Bangoran",
    country_code: "CF",
  },
  {
    id: "687",
    name: "Bangui",
    country_code: "CF",
  },
  {
    id: "688",
    name: "Basse-Kotto",
    country_code: "CF",
  },
  {
    id: "689",
    name: "Haut-Mbomou",
    country_code: "CF",
  },
  {
    id: "690",
    name: "Haute-Kotto",
    country_code: "CF",
  },
  {
    id: "691",
    name: "Kemo",
    country_code: "CF",
  },
  {
    id: "692",
    name: "Lobaye",
    country_code: "CF",
  },
  {
    id: "693",
    name: "Mambere-Kadei",
    country_code: "CF",
  },
  {
    id: "694",
    name: "Mbomou",
    country_code: "CF",
  },
  {
    id: "695",
    name: "Nana-Gribizi",
    country_code: "CF",
  },
  {
    id: "696",
    name: "Nana-Mambere",
    country_code: "CF",
  },
  {
    id: "697",
    name: "Ombella Mpoko",
    country_code: "CF",
  },
  {
    id: "698",
    name: "Ouaka",
    country_code: "CF",
  },
  {
    id: "699",
    name: "Ouham",
    country_code: "CF",
  },
  {
    id: "700",
    name: "Ouham-Pende",
    country_code: "CF",
  },
  {
    id: "701",
    name: "Sangha-Mbaere",
    country_code: "CF",
  },
  {
    id: "702",
    name: "Vakaga",
    country_code: "CF",
  },
  {
    id: "703",
    name: "Batha",
    country_code: "TD",
  },
  {
    id: "704",
    name: "Biltine",
    country_code: "TD",
  },
  {
    id: "705",
    name: "Bourkou-Ennedi-Tibesti",
    country_code: "TD",
  },
  {
    id: "706",
    name: "Chari-Baguirmi",
    country_code: "TD",
  },
  {
    id: "707",
    name: "Guera",
    country_code: "TD",
  },
  {
    id: "708",
    name: "Kanem",
    country_code: "TD",
  },
  {
    id: "709",
    name: "Lac",
    country_code: "TD",
  },
  {
    id: "710",
    name: "Logone Occidental",
    country_code: "TD",
  },
  {
    id: "711",
    name: "Logone Oriental",
    country_code: "TD",
  },
  {
    id: "712",
    name: "Mayo-Kebbi",
    country_code: "TD",
  },
  {
    id: "713",
    name: "Moyen-Chari",
    country_code: "TD",
  },
  {
    id: "714",
    name: "Ouaddai",
    country_code: "TD",
  },
  {
    id: "715",
    name: "Salamat",
    country_code: "TD",
  },
  {
    id: "716",
    name: "Tandjile",
    country_code: "TD",
  },
  {
    id: "717",
    name: "Aisen",
    country_code: "CL",
  },
  {
    id: "718",
    name: "Antofagasta",
    country_code: "CL",
  },
  {
    id: "719",
    name: "Araucania",
    country_code: "CL",
  },
  {
    id: "720",
    name: "Atacama",
    country_code: "CL",
  },
  {
    id: "721",
    name: "Bio Bio",
    country_code: "CL",
  },
  {
    id: "722",
    name: "Coquimbo",
    country_code: "CL",
  },
  {
    id: "723",
    name: "Libertador General Bernardo O",
    country_code: "CL",
  },
  {
    id: "724",
    name: "Los Lagos",
    country_code: "CL",
  },
  {
    id: "725",
    name: "Magellanes",
    country_code: "CL",
  },
  {
    id: "726",
    name: "Maule",
    country_code: "CL",
  },
  {
    id: "727",
    name: "Metropolitana",
    country_code: "CL",
  },
  {
    id: "728",
    name: "Metropolitana de Santiago",
    country_code: "CL",
  },
  {
    id: "729",
    name: "Tarapaca",
    country_code: "CL",
  },
  {
    id: "730",
    name: "Valparaiso",
    country_code: "CL",
  },
  {
    id: "731",
    name: "Anhui",
    country_code: "CN",
  },
  {
    id: "732",
    name: "Anhui Province",
    country_code: "CN",
  },
  {
    id: "733",
    name: "Anhui Sheng",
    country_code: "CN",
  },
  {
    id: "734",
    name: "Aomen",
    country_code: "CN",
  },
  {
    id: "735",
    name: "Beijing",
    country_code: "CN",
  },
  {
    id: "736",
    name: "Beijing Shi",
    country_code: "CN",
  },
  {
    id: "737",
    name: "Chongqing",
    country_code: "CN",
  },
  {
    id: "738",
    name: "Fujian",
    country_code: "CN",
  },
  {
    id: "739",
    name: "Fujian Sheng",
    country_code: "CN",
  },
  {
    id: "740",
    name: "Gansu",
    country_code: "CN",
  },
  {
    id: "741",
    name: "Guangdong",
    country_code: "CN",
  },
  {
    id: "742",
    name: "Guangdong Sheng",
    country_code: "CN",
  },
  {
    id: "743",
    name: "Guangxi",
    country_code: "CN",
  },
  {
    id: "744",
    name: "Guizhou",
    country_code: "CN",
  },
  {
    id: "745",
    name: "Hainan",
    country_code: "CN",
  },
  {
    id: "746",
    name: "Hebei",
    country_code: "CN",
  },
  {
    id: "747",
    name: "Heilongjiang",
    country_code: "CN",
  },
  {
    id: "748",
    name: "Henan",
    country_code: "CN",
  },
  {
    id: "749",
    name: "Hubei",
    country_code: "CN",
  },
  {
    id: "750",
    name: "Hunan",
    country_code: "CN",
  },
  {
    id: "751",
    name: "Jiangsu",
    country_code: "CN",
  },
  {
    id: "752",
    name: "Jiangsu Sheng",
    country_code: "CN",
  },
  {
    id: "753",
    name: "Jiangxi",
    country_code: "CN",
  },
  {
    id: "754",
    name: "Jilin",
    country_code: "CN",
  },
  {
    id: "755",
    name: "Liaoning",
    country_code: "CN",
  },
  {
    id: "756",
    name: "Liaoning Sheng",
    country_code: "CN",
  },
  {
    id: "757",
    name: "Nei Monggol",
    country_code: "CN",
  },
  {
    id: "758",
    name: "Ningxia Hui",
    country_code: "CN",
  },
  {
    id: "759",
    name: "Qinghai",
    country_code: "CN",
  },
  {
    id: "760",
    name: "Shaanxi",
    country_code: "CN",
  },
  {
    id: "761",
    name: "Shandong",
    country_code: "CN",
  },
  {
    id: "762",
    name: "Shandong Sheng",
    country_code: "CN",
  },
  {
    id: "763",
    name: "Shanghai",
    country_code: "CN",
  },
  {
    id: "764",
    name: "Shanxi",
    country_code: "CN",
  },
  {
    id: "765",
    name: "Sichuan",
    country_code: "CN",
  },
  {
    id: "766",
    name: "Tianjin",
    country_code: "CN",
  },
  {
    id: "767",
    name: "Xianggang",
    country_code: "CN",
  },
  {
    id: "768",
    name: "Xinjiang",
    country_code: "CN",
  },
  {
    id: "769",
    name: "Xizang",
    country_code: "CN",
  },
  {
    id: "770",
    name: "Yunnan",
    country_code: "CN",
  },
  {
    id: "771",
    name: "Zhejiang",
    country_code: "CN",
  },
  {
    id: "772",
    name: "Zhejiang Sheng",
    country_code: "CN",
  },
  {
    id: "775",
    name: "Amazonas",
    country_code: "CO",
  },
  {
    id: "776",
    name: "Antioquia",
    country_code: "CO",
  },
  {
    id: "777",
    name: "Arauca",
    country_code: "CO",
  },
  {
    id: "778",
    name: "Atlantico",
    country_code: "CO",
  },
  {
    id: "779",
    name: "Bogota",
    country_code: "CO",
  },
  {
    id: "780",
    name: "Bolivar",
    country_code: "CO",
  },
  {
    id: "781",
    name: "Boyaca",
    country_code: "CO",
  },
  {
    id: "782",
    name: "Caldas",
    country_code: "CO",
  },
  {
    id: "783",
    name: "Caqueta",
    country_code: "CO",
  },
  {
    id: "784",
    name: "Casanare",
    country_code: "CO",
  },
  {
    id: "785",
    name: "Cauca",
    country_code: "CO",
  },
  {
    id: "786",
    name: "Cesar",
    country_code: "CO",
  },
  {
    id: "787",
    name: "Choco",
    country_code: "CO",
  },
  {
    id: "788",
    name: "Cordoba",
    country_code: "CO",
  },
  {
    id: "789",
    name: "Cundinamarca",
    country_code: "CO",
  },
  {
    id: "790",
    name: "Guainia",
    country_code: "CO",
  },
  {
    id: "791",
    name: "Guaviare",
    country_code: "CO",
  },
  {
    id: "792",
    name: "Huila",
    country_code: "CO",
  },
  {
    id: "793",
    name: "La Guajira",
    country_code: "CO",
  },
  {
    id: "794",
    name: "Magdalena",
    country_code: "CO",
  },
  {
    id: "795",
    name: "Meta",
    country_code: "CO",
  },
  {
    id: "796",
    name: "Narino",
    country_code: "CO",
  },
  {
    id: "797",
    name: "Norte de Santander",
    country_code: "CO",
  },
  {
    id: "798",
    name: "Putumayo",
    country_code: "CO",
  },
  {
    id: "799",
    name: "Quindio",
    country_code: "CO",
  },
  {
    id: "800",
    name: "Risaralda",
    country_code: "CO",
  },
  {
    id: "801",
    name: "San Andres y Providencia",
    country_code: "CO",
  },
  {
    id: "802",
    name: "Santander",
    country_code: "CO",
  },
  {
    id: "803",
    name: "Sucre",
    country_code: "CO",
  },
  {
    id: "804",
    name: "Tolima",
    country_code: "CO",
  },
  {
    id: "805",
    name: "Valle del Cauca",
    country_code: "CO",
  },
  {
    id: "806",
    name: "Vaupes",
    country_code: "CO",
  },
  {
    id: "807",
    name: "Vichada",
    country_code: "CO",
  },
  {
    id: "808",
    name: "Mwali",
    country_code: "KM",
  },
  {
    id: "809",
    name: "Njazidja",
    country_code: "KM",
  },
  {
    id: "810",
    name: "Nzwani",
    country_code: "KM",
  },
  {
    id: "811",
    name: "Bouenza",
    country_code: "CG",
  },
  {
    id: "812",
    name: "Brazzaville",
    country_code: "CG",
  },
  {
    id: "813",
    name: "Cuvette",
    country_code: "CG",
  },
  {
    id: "814",
    name: "Kouilou",
    country_code: "CG",
  },
  {
    id: "815",
    name: "Lekoumou",
    country_code: "CG",
  },
  {
    id: "816",
    name: "Likouala",
    country_code: "CG",
  },
  {
    id: "817",
    name: "Niari",
    country_code: "CG",
  },
  {
    id: "818",
    name: "Plateaux",
    country_code: "CG",
  },
  {
    id: "819",
    name: "Pool",
    country_code: "CG",
  },
  {
    id: "820",
    name: "Sangha",
    country_code: "CG",
  },
  {
    id: "843",
    name: "Alajuela",
    country_code: "CR",
  },
  {
    id: "844",
    name: "Cartago",
    country_code: "CR",
  },
  {
    id: "845",
    name: "Guanacaste",
    country_code: "CR",
  },
  {
    id: "846",
    name: "Heredia",
    country_code: "CR",
  },
  {
    id: "847",
    name: "Limon",
    country_code: "CR",
  },
  {
    id: "848",
    name: "Puntarenas",
    country_code: "CR",
  },
  {
    id: "849",
    name: "San Jose",
    country_code: "CR",
  },
  {
    id: "850",
    name: "Abidjan",
    country_code: "CI",
  },
  {
    id: "851",
    name: "Agneby",
    country_code: "CI",
  },
  {
    id: "852",
    name: "Bafing",
    country_code: "CI",
  },
  {
    id: "853",
    name: "Denguele",
    country_code: "CI",
  },
  {
    id: "854",
    name: "Dix-huit Montagnes",
    country_code: "CI",
  },
  {
    id: "855",
    name: "Fromager",
    country_code: "CI",
  },
  {
    id: "856",
    name: "Haut-Sassandra",
    country_code: "CI",
  },
  {
    id: "857",
    name: "Lacs",
    country_code: "CI",
  },
  {
    id: "858",
    name: "Lagunes",
    country_code: "CI",
  },
  {
    id: "859",
    name: "Marahoue",
    country_code: "CI",
  },
  {
    id: "860",
    name: "Moyen-Cavally",
    country_code: "CI",
  },
  {
    id: "861",
    name: "Moyen-Comoe",
    country_code: "CI",
  },
  {
    id: "862",
    name: "N''zi-Comoe",
    country_code: "CI",
  },
  {
    id: "863",
    name: "Sassandra",
    country_code: "CI",
  },
  {
    id: "864",
    name: "Savanes",
    country_code: "CI",
  },
  {
    id: "865",
    name: "Sud-Bandama",
    country_code: "CI",
  },
  {
    id: "866",
    name: "Sud-Comoe",
    country_code: "CI",
  },
  {
    id: "867",
    name: "Vallee du Bandama",
    country_code: "CI",
  },
  {
    id: "868",
    name: "Worodougou",
    country_code: "CI",
  },
  {
    id: "869",
    name: "Zanzan",
    country_code: "CI",
  },
  {
    id: "870",
    name: "Bjelovar-Bilogora",
    country_code: "HR",
  },
  {
    id: "871",
    name: "Dubrovnik-Neretva",
    country_code: "HR",
  },
  {
    id: "872",
    name: "Grad Zagreb",
    country_code: "HR",
  },
  {
    id: "873",
    name: "Istra",
    country_code: "HR",
  },
  {
    id: "874",
    name: "Karlovac",
    country_code: "HR",
  },
  {
    id: "875",
    name: "Koprivnica-Krizhevci",
    country_code: "HR",
  },
  {
    id: "876",
    name: "Krapina-Zagorje",
    country_code: "HR",
  },
  {
    id: "877",
    name: "Lika-Senj",
    country_code: "HR",
  },
  {
    id: "878",
    name: "Medhimurje",
    country_code: "HR",
  },
  {
    id: "879",
    name: "Medimurska Zupanija",
    country_code: "HR",
  },
  {
    id: "880",
    name: "Osijek-Baranja",
    country_code: "HR",
  },
  {
    id: "881",
    name: "Osjecko-Baranjska Zupanija",
    country_code: "HR",
  },
  {
    id: "882",
    name: "Pozhega-Slavonija",
    country_code: "HR",
  },
  {
    id: "883",
    name: "Primorje-Gorski Kotar",
    country_code: "HR",
  },
  {
    id: "884",
    name: "Shibenik-Knin",
    country_code: "HR",
  },
  {
    id: "885",
    name: "Sisak-Moslavina",
    country_code: "HR",
  },
  {
    id: "886",
    name: "Slavonski Brod-Posavina",
    country_code: "HR",
  },
  {
    id: "887",
    name: "Split-Dalmacija",
    country_code: "HR",
  },
  {
    id: "888",
    name: "Varazhdin",
    country_code: "HR",
  },
  {
    id: "889",
    name: "Virovitica-Podravina",
    country_code: "HR",
  },
  {
    id: "890",
    name: "Vukovar-Srijem",
    country_code: "HR",
  },
  {
    id: "891",
    name: "Zadar",
    country_code: "HR",
  },
  {
    id: "892",
    name: "Zagreb",
    country_code: "HR",
  },
  {
    id: "893",
    name: "Camaguey",
    country_code: "CU",
  },
  {
    id: "894",
    name: "Ciego de Avila",
    country_code: "CU",
  },
  {
    id: "895",
    name: "Cienfuegos",
    country_code: "CU",
  },
  {
    id: "896",
    name: "Ciudad de la Habana",
    country_code: "CU",
  },
  {
    id: "897",
    name: "Granma",
    country_code: "CU",
  },
  {
    id: "898",
    name: "Guantanamo",
    country_code: "CU",
  },
  {
    id: "899",
    name: "Habana",
    country_code: "CU",
  },
  {
    id: "900",
    name: "Holguin",
    country_code: "CU",
  },
  {
    id: "901",
    name: "Isla de la Juventud",
    country_code: "CU",
  },
  {
    id: "902",
    name: "La Habana",
    country_code: "CU",
  },
  {
    id: "903",
    name: "Las Tunas",
    country_code: "CU",
  },
  {
    id: "904",
    name: "Matanzas",
    country_code: "CU",
  },
  {
    id: "905",
    name: "Pinar del Rio",
    country_code: "CU",
  },
  {
    id: "906",
    name: "Sancti Spiritus",
    country_code: "CU",
  },
  {
    id: "907",
    name: "Santiago de Cuba",
    country_code: "CU",
  },
  {
    id: "908",
    name: "Villa Clara",
    country_code: "CU",
  },
  {
    id: "909",
    name: "Government controlled area",
    country_code: "CY",
  },
  {
    id: "910",
    name: "Limassol",
    country_code: "CY",
  },
  {
    id: "911",
    name: "Nicosia District",
    country_code: "CY",
  },
  {
    id: "912",
    name: "Paphos",
    country_code: "CY",
  },
  {
    id: "913",
    name: "Turkish controlled area",
    country_code: "CY",
  },
  {
    id: "914",
    name: "Central Bohemian",
    country_code: "CZ",
  },
  {
    id: "915",
    name: "Frycovice",
    country_code: "CZ",
  },
  {
    id: "916",
    name: "Jihocesky Kraj",
    country_code: "CZ",
  },
  {
    id: "917",
    name: "Jihochesky",
    country_code: "CZ",
  },
  {
    id: "918",
    name: "Jihomoravsky",
    country_code: "CZ",
  },
  {
    id: "919",
    name: "Karlovarsky",
    country_code: "CZ",
  },
  {
    id: "920",
    name: "Klecany",
    country_code: "CZ",
  },
  {
    id: "921",
    name: "Kralovehradecky",
    country_code: "CZ",
  },
  {
    id: "922",
    name: "Liberecky",
    country_code: "CZ",
  },
  {
    id: "923",
    name: "Lipov",
    country_code: "CZ",
  },
  {
    id: "924",
    name: "Moravskoslezsky",
    country_code: "CZ",
  },
  {
    id: "925",
    name: "Olomoucky",
    country_code: "CZ",
  },
  {
    id: "926",
    name: "Olomoucky Kraj",
    country_code: "CZ",
  },
  {
    id: "927",
    name: "Pardubicky",
    country_code: "CZ",
  },
  {
    id: "928",
    name: "Plzensky",
    country_code: "CZ",
  },
  {
    id: "929",
    name: "Praha",
    country_code: "CZ",
  },
  {
    id: "930",
    name: "Rajhrad",
    country_code: "CZ",
  },
  {
    id: "931",
    name: "Smirice",
    country_code: "CZ",
  },
  {
    id: "932",
    name: "South Moravian",
    country_code: "CZ",
  },
  {
    id: "933",
    name: "Straz nad Nisou",
    country_code: "CZ",
  },
  {
    id: "934",
    name: "Stredochesky",
    country_code: "CZ",
  },
  {
    id: "935",
    name: "Unicov",
    country_code: "CZ",
  },
  {
    id: "936",
    name: "Ustecky",
    country_code: "CZ",
  },
  {
    id: "937",
    name: "Valletta",
    country_code: "CZ",
  },
  {
    id: "938",
    name: "Velesin",
    country_code: "CZ",
  },
  {
    id: "939",
    name: "Vysochina",
    country_code: "CZ",
  },
  {
    id: "940",
    name: "Zlinsky",
    country_code: "CZ",
  },
  {
    id: "941",
    name: "Arhus",
    country_code: "DK",
  },
  {
    id: "942",
    name: "Bornholm",
    country_code: "DK",
  },
  {
    id: "943",
    name: "Frederiksborg",
    country_code: "DK",
  },
  {
    id: "944",
    name: "Fyn",
    country_code: "DK",
  },
  {
    id: "945",
    name: "Hovedstaden",
    country_code: "DK",
  },
  {
    id: "946",
    name: "Kobenhavn",
    country_code: "DK",
  },
  {
    id: "947",
    name: "Kobenhavns Amt",
    country_code: "DK",
  },
  {
    id: "948",
    name: "Kobenhavns Kommune",
    country_code: "DK",
  },
  {
    id: "949",
    name: "Nordjylland",
    country_code: "DK",
  },
  {
    id: "950",
    name: "Ribe",
    country_code: "DK",
  },
  {
    id: "951",
    name: "Ringkobing",
    country_code: "DK",
  },
  {
    id: "952",
    name: "Roervig",
    country_code: "DK",
  },
  {
    id: "953",
    name: "Roskilde",
    country_code: "DK",
  },
  {
    id: "954",
    name: "Roslev",
    country_code: "DK",
  },
  {
    id: "955",
    name: "Sjaelland",
    country_code: "DK",
  },
  {
    id: "956",
    name: "Soeborg",
    country_code: "DK",
  },
  {
    id: "957",
    name: "Sonderjylland",
    country_code: "DK",
  },
  {
    id: "958",
    name: "Storstrom",
    country_code: "DK",
  },
  {
    id: "959",
    name: "Syddanmark",
    country_code: "DK",
  },
  {
    id: "960",
    name: "Toelloese",
    country_code: "DK",
  },
  {
    id: "961",
    name: "Vejle",
    country_code: "DK",
  },
  {
    id: "962",
    name: "Vestsjalland",
    country_code: "DK",
  },
  {
    id: "963",
    name: "Viborg",
    country_code: "DK",
  },
  {
    id: "964",
    name: "Ali Sabih",
    country_code: "DJ",
  },
  {
    id: "965",
    name: "Dikhil",
    country_code: "DJ",
  },
  {
    id: "966",
    name: "Jibuti",
    country_code: "DJ",
  },
  {
    id: "967",
    name: "Tajurah",
    country_code: "DJ",
  },
  {
    id: "968",
    name: "Ubuk",
    country_code: "DJ",
  },
  {
    id: "969",
    name: "Saint Andrew",
    country_code: "DM",
  },
  {
    id: "970",
    name: "Saint David",
    country_code: "DM",
  },
  {
    id: "971",
    name: "Saint George",
    country_code: "DM",
  },
  {
    id: "972",
    name: "Saint John",
    country_code: "DM",
  },
  {
    id: "973",
    name: "Saint Joseph",
    country_code: "DM",
  },
  {
    id: "974",
    name: "Saint Luke",
    country_code: "DM",
  },
  {
    id: "975",
    name: "Saint Mark",
    country_code: "DM",
  },
  {
    id: "976",
    name: "Saint Patrick",
    country_code: "DM",
  },
  {
    id: "977",
    name: "Saint Paul",
    country_code: "DM",
  },
  {
    id: "978",
    name: "Saint Peter",
    country_code: "DM",
  },
  {
    id: "979",
    name: "Azua",
    country_code: "DO",
  },
  {
    id: "980",
    name: "Bahoruco",
    country_code: "DO",
  },
  {
    id: "981",
    name: "Barahona",
    country_code: "DO",
  },
  {
    id: "982",
    name: "Dajabon",
    country_code: "DO",
  },
  {
    id: "983",
    name: "Distrito Nacional",
    country_code: "DO",
  },
  {
    id: "984",
    name: "Duarte",
    country_code: "DO",
  },
  {
    id: "985",
    name: "El Seybo",
    country_code: "DO",
  },
  {
    id: "986",
    name: "Elias Pina",
    country_code: "DO",
  },
  {
    id: "987",
    name: "Espaillat",
    country_code: "DO",
  },
  {
    id: "988",
    name: "Hato Mayor",
    country_code: "DO",
  },
  {
    id: "989",
    name: "Independencia",
    country_code: "DO",
  },
  {
    id: "990",
    name: "La Altagracia",
    country_code: "DO",
  },
  {
    id: "991",
    name: "La Romana",
    country_code: "DO",
  },
  {
    id: "992",
    name: "La Vega",
    country_code: "DO",
  },
  {
    id: "993",
    name: "Maria Trinidad Sanchez",
    country_code: "DO",
  },
  {
    id: "994",
    name: "Monsenor Nouel",
    country_code: "DO",
  },
  {
    id: "995",
    name: "Monte Cristi",
    country_code: "DO",
  },
  {
    id: "996",
    name: "Monte Plata",
    country_code: "DO",
  },
  {
    id: "997",
    name: "Pedernales",
    country_code: "DO",
  },
  {
    id: "998",
    name: "Peravia",
    country_code: "DO",
  },
  {
    id: "999",
    name: "Puerto Plata",
    country_code: "DO",
  },
  {
    id: "1000",
    name: "Salcedo",
    country_code: "DO",
  },
  {
    id: "1001",
    name: "Samana",
    country_code: "DO",
  },
  {
    id: "1002",
    name: "San Cristobal",
    country_code: "DO",
  },
  {
    id: "1003",
    name: "San Juan",
    country_code: "DO",
  },
  {
    id: "1004",
    name: "San Pedro de Macoris",
    country_code: "DO",
  },
  {
    id: "1005",
    name: "Sanchez Ramirez",
    country_code: "DO",
  },
  {
    id: "1006",
    name: "Santiago",
    country_code: "DO",
  },
  {
    id: "1007",
    name: "Santiago Rodriguez",
    country_code: "DO",
  },
  {
    id: "1008",
    name: "Valverde",
    country_code: "DO",
  },
  {
    id: "1022",
    name: "Azuay",
    country_code: "EC",
  },
  {
    id: "1023",
    name: "Bolivar",
    country_code: "EC",
  },
  {
    id: "1024",
    name: "Canar",
    country_code: "EC",
  },
  {
    id: "1025",
    name: "Carchi",
    country_code: "EC",
  },
  {
    id: "1026",
    name: "Chimborazo",
    country_code: "EC",
  },
  {
    id: "1027",
    name: "Cotopaxi",
    country_code: "EC",
  },
  {
    id: "1028",
    name: "El Oro",
    country_code: "EC",
  },
  {
    id: "1029",
    name: "Esmeraldas",
    country_code: "EC",
  },
  {
    id: "1030",
    name: "Galapagos",
    country_code: "EC",
  },
  {
    id: "1031",
    name: "Guayas",
    country_code: "EC",
  },
  {
    id: "1032",
    name: "Imbabura",
    country_code: "EC",
  },
  {
    id: "1033",
    name: "Loja",
    country_code: "EC",
  },
  {
    id: "1034",
    name: "Los Rios",
    country_code: "EC",
  },
  {
    id: "1035",
    name: "Manabi",
    country_code: "EC",
  },
  {
    id: "1036",
    name: "Morona Santiago",
    country_code: "EC",
  },
  {
    id: "1037",
    name: "Napo",
    country_code: "EC",
  },
  {
    id: "1038",
    name: "Orellana",
    country_code: "EC",
  },
  {
    id: "1039",
    name: "Pastaza",
    country_code: "EC",
  },
  {
    id: "1040",
    name: "Pichincha",
    country_code: "EC",
  },
  {
    id: "1041",
    name: "Sucumbios",
    country_code: "EC",
  },
  {
    id: "1042",
    name: "Tungurahua",
    country_code: "EC",
  },
  {
    id: "1043",
    name: "Zamora Chinchipe",
    country_code: "EC",
  },
  {
    id: "1044",
    name: "Aswan",
    country_code: "EG",
  },
  {
    id: "1045",
    name: "Asyut",
    country_code: "EG",
  },
  {
    id: "1046",
    name: "Bani Suwayf",
    country_code: "EG",
  },
  {
    id: "1047",
    name: "Bur Sa''id",
    country_code: "EG",
  },
  {
    id: "1048",
    name: "Cairo",
    country_code: "EG",
  },
  {
    id: "1049",
    name: "Dumyat",
    country_code: "EG",
  },
  {
    id: "1050",
    name: "Kafr-ash-Shaykh",
    country_code: "EG",
  },
  {
    id: "1051",
    name: "Matruh",
    country_code: "EG",
  },
  {
    id: "1052",
    name: "Muhafazat ad Daqahliyah",
    country_code: "EG",
  },
  {
    id: "1053",
    name: "Muhafazat al Fayyum",
    country_code: "EG",
  },
  {
    id: "1054",
    name: "Muhafazat al Gharbiyah",
    country_code: "EG",
  },
  {
    id: "1055",
    name: "Muhafazat al Iskandariyah",
    country_code: "EG",
  },
  {
    id: "1056",
    name: "Muhafazat al Qahirah",
    country_code: "EG",
  },
  {
    id: "1057",
    name: "Qina",
    country_code: "EG",
  },
  {
    id: "1058",
    name: "Sawhaj",
    country_code: "EG",
  },
  {
    id: "1059",
    name: "Sina al-Janubiyah",
    country_code: "EG",
  },
  {
    id: "1060",
    name: "Sina ash-Shamaliyah",
    country_code: "EG",
  },
  {
    id: "1061",
    name: "ad-Daqahliyah",
    country_code: "EG",
  },
  {
    id: "1062",
    name: "al-Bahr-al-Ahmar",
    country_code: "EG",
  },
  {
    id: "1063",
    name: "al-Buhayrah",
    country_code: "EG",
  },
  {
    id: "1064",
    name: "al-Fayyum",
    country_code: "EG",
  },
  {
    id: "1065",
    name: "al-Gharbiyah",
    country_code: "EG",
  },
  {
    id: "1066",
    name: "al-Iskandariyah",
    country_code: "EG",
  },
  {
    id: "1067",
    name: "al-Ismailiyah",
    country_code: "EG",
  },
  {
    id: "1068",
    name: "al-Jizah",
    country_code: "EG",
  },
  {
    id: "1069",
    name: "al-Minufiyah",
    country_code: "EG",
  },
  {
    id: "1070",
    name: "al-Minya",
    country_code: "EG",
  },
  {
    id: "1071",
    name: "al-Qahira",
    country_code: "EG",
  },
  {
    id: "1072",
    name: "al-Qalyubiyah",
    country_code: "EG",
  },
  {
    id: "1073",
    name: "al-Uqsur",
    country_code: "EG",
  },
  {
    id: "1074",
    name: "al-Wadi al-Jadid",
    country_code: "EG",
  },
  {
    id: "1075",
    name: "as-Suways",
    country_code: "EG",
  },
  {
    id: "1076",
    name: "ash-Sharqiyah",
    country_code: "EG",
  },
  {
    id: "1077",
    name: "Ahuachapan",
    country_code: "SV",
  },
  {
    id: "1078",
    name: "Cabanas",
    country_code: "SV",
  },
  {
    id: "1079",
    name: "Chalatenango",
    country_code: "SV",
  },
  {
    id: "1080",
    name: "Cuscatlan",
    country_code: "SV",
  },
  {
    id: "1081",
    name: "La Libertad",
    country_code: "SV",
  },
  {
    id: "1082",
    name: "La Paz",
    country_code: "SV",
  },
  {
    id: "1083",
    name: "La Union",
    country_code: "SV",
  },
  {
    id: "1084",
    name: "Morazan",
    country_code: "SV",
  },
  {
    id: "1085",
    name: "San Miguel",
    country_code: "SV",
  },
  {
    id: "1086",
    name: "San Salvador",
    country_code: "SV",
  },
  {
    id: "1087",
    name: "San Vicente",
    country_code: "SV",
  },
  {
    id: "1088",
    name: "Santa Ana",
    country_code: "SV",
  },
  {
    id: "1089",
    name: "Sonsonate",
    country_code: "SV",
  },
  {
    id: "1090",
    name: "Usulutan",
    country_code: "SV",
  },
  {
    id: "1091",
    name: "Annobon",
    country_code: "GQ",
  },
  {
    id: "1092",
    name: "Bioko Norte",
    country_code: "GQ",
  },
  {
    id: "1093",
    name: "Bioko Sur",
    country_code: "GQ",
  },
  {
    id: "1094",
    name: "Centro Sur",
    country_code: "GQ",
  },
  {
    id: "1095",
    name: "Kie-Ntem",
    country_code: "GQ",
  },
  {
    id: "1096",
    name: "Litoral",
    country_code: "GQ",
  },
  {
    id: "1097",
    name: "Wele-Nzas",
    country_code: "GQ",
  },
  {
    id: "1098",
    name: "Anseba",
    country_code: "ER",
  },
  {
    id: "1099",
    name: "Debub",
    country_code: "ER",
  },
  {
    id: "1100",
    name: "Debub-Keih-Bahri",
    country_code: "ER",
  },
  {
    id: "1101",
    name: "Gash-Barka",
    country_code: "ER",
  },
  {
    id: "1102",
    name: "Maekel",
    country_code: "ER",
  },
  {
    id: "1103",
    name: "Semien-Keih-Bahri",
    country_code: "ER",
  },
  {
    id: "1104",
    name: "Harju",
    country_code: "EE",
  },
  {
    id: "1105",
    name: "Hiiu",
    country_code: "EE",
  },
  {
    id: "1106",
    name: "Ida-Viru",
    country_code: "EE",
  },
  {
    id: "1107",
    name: "Jarva",
    country_code: "EE",
  },
  {
    id: "1108",
    name: "Jogeva",
    country_code: "EE",
  },
  {
    id: "1109",
    name: "Laane",
    country_code: "EE",
  },
  {
    id: "1110",
    name: "Laane-Viru",
    country_code: "EE",
  },
  {
    id: "1111",
    name: "Parnu",
    country_code: "EE",
  },
  {
    id: "1112",
    name: "Polva",
    country_code: "EE",
  },
  {
    id: "1113",
    name: "Rapla",
    country_code: "EE",
  },
  {
    id: "1114",
    name: "Saare",
    country_code: "EE",
  },
  {
    id: "1115",
    name: "Tartu",
    country_code: "EE",
  },
  {
    id: "1116",
    name: "Valga",
    country_code: "EE",
  },
  {
    id: "1117",
    name: "Viljandi",
    country_code: "EE",
  },
  {
    id: "1118",
    name: "Voru",
    country_code: "EE",
  },
  {
    id: "1119",
    name: "Addis Abeba",
    country_code: "ET",
  },
  {
    id: "1120",
    name: "Afar",
    country_code: "ET",
  },
  {
    id: "1121",
    name: "Amhara",
    country_code: "ET",
  },
  {
    id: "1122",
    name: "Benishangul",
    country_code: "ET",
  },
  {
    id: "1123",
    name: "Diredawa",
    country_code: "ET",
  },
  {
    id: "1124",
    name: "Gambella",
    country_code: "ET",
  },
  {
    id: "1125",
    name: "Harar",
    country_code: "ET",
  },
  {
    id: "1126",
    name: "Jigjiga",
    country_code: "ET",
  },
  {
    id: "1127",
    name: "Mekele",
    country_code: "ET",
  },
  {
    id: "1128",
    name: "Oromia",
    country_code: "ET",
  },
  {
    id: "1129",
    name: "Somali",
    country_code: "ET",
  },
  {
    id: "1130",
    name: "Southern",
    country_code: "ET",
  },
  {
    id: "1131",
    name: "Tigray",
    country_code: "ET",
  },
  {
    id: "1146",
    name: "Central",
    country_code: "FJ",
  },
  {
    id: "1147",
    name: "Eastern",
    country_code: "FJ",
  },
  {
    id: "1148",
    name: "Northern",
    country_code: "FJ",
  },
  {
    id: "1149",
    name: "South Pacific",
    country_code: "FJ",
  },
  {
    id: "1150",
    name: "Western",
    country_code: "FJ",
  },
  {
    id: "1151",
    name: "Ahvenanmaa",
    country_code: "FI",
  },
  {
    id: "1152",
    name: "Etela-Karjala",
    country_code: "FI",
  },
  {
    id: "1153",
    name: "Etela-Pohjanmaa",
    country_code: "FI",
  },
  {
    id: "1154",
    name: "Etela-Savo",
    country_code: "FI",
  },
  {
    id: "1155",
    name: "Etela-Suomen Laani",
    country_code: "FI",
  },
  {
    id: "1156",
    name: "Ita-Suomen Laani",
    country_code: "FI",
  },
  {
    id: "1157",
    name: "Ita-Uusimaa",
    country_code: "FI",
  },
  {
    id: "1158",
    name: "Kainuu",
    country_code: "FI",
  },
  {
    id: "1159",
    name: "Kanta-Hame",
    country_code: "FI",
  },
  {
    id: "1160",
    name: "Keski-Pohjanmaa",
    country_code: "FI",
  },
  {
    id: "1161",
    name: "Keski-Suomi",
    country_code: "FI",
  },
  {
    id: "1162",
    name: "Kymenlaakso",
    country_code: "FI",
  },
  {
    id: "1163",
    name: "Lansi-Suomen Laani",
    country_code: "FI",
  },
  {
    id: "1164",
    name: "Lappi",
    country_code: "FI",
  },
  {
    id: "1165",
    name: "Northern Savonia",
    country_code: "FI",
  },
  {
    id: "1166",
    name: "Ostrobothnia",
    country_code: "FI",
  },
  {
    id: "1167",
    name: "Oulun Laani",
    country_code: "FI",
  },
  {
    id: "1168",
    name: "Paijat-Hame",
    country_code: "FI",
  },
  {
    id: "1169",
    name: "Pirkanmaa",
    country_code: "FI",
  },
  {
    id: "1170",
    name: "Pohjanmaa",
    country_code: "FI",
  },
  {
    id: "1171",
    name: "Pohjois-Karjala",
    country_code: "FI",
  },
  {
    id: "1172",
    name: "Pohjois-Pohjanmaa",
    country_code: "FI",
  },
  {
    id: "1173",
    name: "Pohjois-Savo",
    country_code: "FI",
  },
  {
    id: "1174",
    name: "Saarijarvi",
    country_code: "FI",
  },
  {
    id: "1175",
    name: "Satakunta",
    country_code: "FI",
  },
  {
    id: "1176",
    name: "Southern Savonia",
    country_code: "FI",
  },
  {
    id: "1177",
    name: "Tavastia Proper",
    country_code: "FI",
  },
  {
    id: "1178",
    name: "Uleaborgs Lan",
    country_code: "FI",
  },
  {
    id: "1179",
    name: "Uusimaa",
    country_code: "FI",
  },
  {
    id: "1180",
    name: "Varsinais-Suomi",
    country_code: "FI",
  },
  {
    id: "1181",
    name: "Ain",
    country_code: "FR",
  },
  {
    id: "1182",
    name: "Aisne",
    country_code: "FR",
  },
  {
    id: "1183",
    name: "Albi Le Sequestre",
    country_code: "FR",
  },
  {
    id: "1184",
    name: "Allier",
    country_code: "FR",
  },
  {
    id: "1185",
    name: "Alpes-Cote dAzur",
    country_code: "FR",
  },
  {
    id: "1186",
    name: "Alpes-Maritimes",
    country_code: "FR",
  },
  {
    id: "1187",
    name: "Alpes-de-Haute-Provence",
    country_code: "FR",
  },
  {
    id: "1188",
    name: "Alsace",
    country_code: "FR",
  },
  {
    id: "1189",
    name: "Aquitaine",
    country_code: "FR",
  },
  {
    id: "1190",
    name: "Ardeche",
    country_code: "FR",
  },
  {
    id: "1191",
    name: "Ardennes",
    country_code: "FR",
  },
  {
    id: "1192",
    name: "Ariege",
    country_code: "FR",
  },
  {
    id: "1193",
    name: "Aube",
    country_code: "FR",
  },
  {
    id: "1194",
    name: "Aude",
    country_code: "FR",
  },
  {
    id: "1195",
    name: "Auvergne",
    country_code: "FR",
  },
  {
    id: "1196",
    name: "Aveyron",
    country_code: "FR",
  },
  {
    id: "1197",
    name: "Bas-Rhin",
    country_code: "FR",
  },
  {
    id: "1198",
    name: "Basse-Normandie",
    country_code: "FR",
  },
  {
    id: "1199",
    name: "Bouches-du-Rhone",
    country_code: "FR",
  },
  {
    id: "1200",
    name: "Bourgogne",
    country_code: "FR",
  },
  {
    id: "1201",
    name: "Bretagne",
    country_code: "FR",
  },
  {
    id: "1202",
    name: "Brittany",
    country_code: "FR",
  },
  {
    id: "1203",
    name: "Burgundy",
    country_code: "FR",
  },
  {
    id: "1204",
    name: "Calvados",
    country_code: "FR",
  },
  {
    id: "1205",
    name: "Cantal",
    country_code: "FR",
  },
  {
    id: "1206",
    name: "Cedex",
    country_code: "FR",
  },
  {
    id: "1207",
    name: "Centre",
    country_code: "FR",
  },
  {
    id: "1208",
    name: "Charente",
    country_code: "FR",
  },
  {
    id: "1209",
    name: "Charente-Maritime",
    country_code: "FR",
  },
  {
    id: "1210",
    name: "Cher",
    country_code: "FR",
  },
  {
    id: "1211",
    name: "Correze",
    country_code: "FR",
  },
  {
    id: "1212",
    name: "Corse-du-Sud",
    country_code: "FR",
  },
  {
    id: "1213",
    name: "Cote-d''Or",
    country_code: "FR",
  },
  {
    id: "1214",
    name: "Cotes-d''Armor",
    country_code: "FR",
  },
  {
    id: "1215",
    name: "Creuse",
    country_code: "FR",
  },
  {
    id: "1216",
    name: "Crolles",
    country_code: "FR",
  },
  {
    id: "1217",
    name: "Deux-Sevres",
    country_code: "FR",
  },
  {
    id: "1218",
    name: "Dordogne",
    country_code: "FR",
  },
  {
    id: "1219",
    name: "Doubs",
    country_code: "FR",
  },
  {
    id: "1220",
    name: "Drome",
    country_code: "FR",
  },
  {
    id: "1221",
    name: "Essonne",
    country_code: "FR",
  },
  {
    id: "1222",
    name: "Eure",
    country_code: "FR",
  },
  {
    id: "1223",
    name: "Eure-et-Loir",
    country_code: "FR",
  },
  {
    id: "1224",
    name: "Feucherolles",
    country_code: "FR",
  },
  {
    id: "1225",
    name: "Finistere",
    country_code: "FR",
  },
  {
    id: "1226",
    name: "Franche-Comte",
    country_code: "FR",
  },
  {
    id: "1227",
    name: "Gard",
    country_code: "FR",
  },
  {
    id: "1228",
    name: "Gers",
    country_code: "FR",
  },
  {
    id: "1229",
    name: "Gironde",
    country_code: "FR",
  },
  {
    id: "1230",
    name: "Haut-Rhin",
    country_code: "FR",
  },
  {
    id: "1231",
    name: "Haute-Corse",
    country_code: "FR",
  },
  {
    id: "1232",
    name: "Haute-Garonne",
    country_code: "FR",
  },
  {
    id: "1233",
    name: "Haute-Loire",
    country_code: "FR",
  },
  {
    id: "1234",
    name: "Haute-Marne",
    country_code: "FR",
  },
  {
    id: "1235",
    name: "Haute-Saone",
    country_code: "FR",
  },
  {
    id: "1236",
    name: "Haute-Savoie",
    country_code: "FR",
  },
  {
    id: "1237",
    name: "Haute-Vienne",
    country_code: "FR",
  },
  {
    id: "1238",
    name: "Hautes-Alpes",
    country_code: "FR",
  },
  {
    id: "1239",
    name: "Hautes-Pyrenees",
    country_code: "FR",
  },
  {
    id: "1240",
    name: "Hauts-de-Seine",
    country_code: "FR",
  },
  {
    id: "1241",
    name: "Herault",
    country_code: "FR",
  },
  {
    id: "1242",
    name: "Ile-de-France",
    country_code: "FR",
  },
  {
    id: "1243",
    name: "Ille-et-Vilaine",
    country_code: "FR",
  },
  {
    id: "1244",
    name: "Indre",
    country_code: "FR",
  },
  {
    id: "1245",
    name: "Indre-et-Loire",
    country_code: "FR",
  },
  {
    id: "1246",
    name: "Isere",
    country_code: "FR",
  },
  {
    id: "1247",
    name: "Jura",
    country_code: "FR",
  },
  {
    id: "1248",
    name: "Klagenfurt",
    country_code: "FR",
  },
  {
    id: "1249",
    name: "Landes",
    country_code: "FR",
  },
  {
    id: "1250",
    name: "Languedoc-Roussillon",
    country_code: "FR",
  },
  {
    id: "1251",
    name: "Larcay",
    country_code: "FR",
  },
  {
    id: "1252",
    name: "Le Castellet",
    country_code: "FR",
  },
  {
    id: "1253",
    name: "Le Creusot",
    country_code: "FR",
  },
  {
    id: "1254",
    name: "Limousin",
    country_code: "FR",
  },
  {
    id: "1255",
    name: "Loir-et-Cher",
    country_code: "FR",
  },
  {
    id: "1256",
    name: "Loire",
    country_code: "FR",
  },
  {
    id: "1257",
    name: "Loire-Atlantique",
    country_code: "FR",
  },
  {
    id: "1258",
    name: "Loiret",
    country_code: "FR",
  },
  {
    id: "1259",
    name: "Lorraine",
    country_code: "FR",
  },
  {
    id: "1260",
    name: "Lot",
    country_code: "FR",
  },
  {
    id: "1261",
    name: "Lot-et-Garonne",
    country_code: "FR",
  },
  {
    id: "1262",
    name: "Lower Normandy",
    country_code: "FR",
  },
  {
    id: "1263",
    name: "Lozere",
    country_code: "FR",
  },
  {
    id: "1264",
    name: "Maine-et-Loire",
    country_code: "FR",
  },
  {
    id: "1265",
    name: "Manche",
    country_code: "FR",
  },
  {
    id: "1266",
    name: "Marne",
    country_code: "FR",
  },
  {
    id: "1267",
    name: "Mayenne",
    country_code: "FR",
  },
  {
    id: "1268",
    name: "Meurthe-et-Moselle",
    country_code: "FR",
  },
  {
    id: "1269",
    name: "Meuse",
    country_code: "FR",
  },
  {
    id: "1270",
    name: "Midi-Pyrenees",
    country_code: "FR",
  },
  {
    id: "1271",
    name: "Morbihan",
    country_code: "FR",
  },
  {
    id: "1272",
    name: "Moselle",
    country_code: "FR",
  },
  {
    id: "1273",
    name: "Nievre",
    country_code: "FR",
  },
  {
    id: "1274",
    name: "Nord",
    country_code: "FR",
  },
  {
    id: "1275",
    name: "Nord-Pas-de-Calais",
    country_code: "FR",
  },
  {
    id: "1276",
    name: "Oise",
    country_code: "FR",
  },
  {
    id: "1277",
    name: "Orne",
    country_code: "FR",
  },
  {
    id: "1278",
    name: "Paris",
    country_code: "FR",
  },
  {
    id: "1279",
    name: "Pas-de-Calais",
    country_code: "FR",
  },
  {
    id: "1280",
    name: "Pays de la Loire",
    country_code: "FR",
  },
  {
    id: "1281",
    name: "Pays-de-la-Loire",
    country_code: "FR",
  },
  {
    id: "1282",
    name: "Picardy",
    country_code: "FR",
  },
  {
    id: "1283",
    name: "Puy-de-Dome",
    country_code: "FR",
  },
  {
    id: "1284",
    name: "Pyrenees-Atlantiques",
    country_code: "FR",
  },
  {
    id: "1285",
    name: "Pyrenees-Orientales",
    country_code: "FR",
  },
  {
    id: "1286",
    name: "Quelmes",
    country_code: "FR",
  },
  {
    id: "1287",
    name: "Rhone",
    country_code: "FR",
  },
  {
    id: "1288",
    name: "Rhone-Alpes",
    country_code: "FR",
  },
  {
    id: "1289",
    name: "Saint Ouen",
    country_code: "FR",
  },
  {
    id: "1290",
    name: "Saint Viatre",
    country_code: "FR",
  },
  {
    id: "1291",
    name: "Saone-et-Loire",
    country_code: "FR",
  },
  {
    id: "1292",
    name: "Sarthe",
    country_code: "FR",
  },
  {
    id: "1293",
    name: "Savoie",
    country_code: "FR",
  },
  {
    id: "1294",
    name: "Seine-Maritime",
    country_code: "FR",
  },
  {
    id: "1295",
    name: "Seine-Saint-Denis",
    country_code: "FR",
  },
  {
    id: "1296",
    name: "Seine-et-Marne",
    country_code: "FR",
  },
  {
    id: "1297",
    name: "Somme",
    country_code: "FR",
  },
  {
    id: "1298",
    name: "Sophia Antipolis",
    country_code: "FR",
  },
  {
    id: "1299",
    name: "Souvans",
    country_code: "FR",
  },
  {
    id: "1300",
    name: "Tarn",
    country_code: "FR",
  },
  {
    id: "1301",
    name: "Tarn-et-Garonne",
    country_code: "FR",
  },
  {
    id: "1302",
    name: "Territoire de Belfort",
    country_code: "FR",
  },
  {
    id: "1303",
    name: "Treignac",
    country_code: "FR",
  },
  {
    id: "1304",
    name: "Upper Normandy",
    country_code: "FR",
  },
  {
    id: "1305",
    name: "Val-d''Oise",
    country_code: "FR",
  },
  {
    id: "1306",
    name: "Val-de-Marne",
    country_code: "FR",
  },
  {
    id: "1307",
    name: "Var",
    country_code: "FR",
  },
  {
    id: "1308",
    name: "Vaucluse",
    country_code: "FR",
  },
  {
    id: "1309",
    name: "Vellise",
    country_code: "FR",
  },
  {
    id: "1310",
    name: "Vendee",
    country_code: "FR",
  },
  {
    id: "1311",
    name: "Vienne",
    country_code: "FR",
  },
  {
    id: "1312",
    name: "Vosges",
    country_code: "FR",
  },
  {
    id: "1313",
    name: "Yonne",
    country_code: "FR",
  },
  {
    id: "1314",
    name: "Yvelines",
    country_code: "FR",
  },
  {
    id: "1325",
    name: "Estuaire",
    country_code: "GA",
  },
  {
    id: "1326",
    name: "Haut-Ogooue",
    country_code: "GA",
  },
  {
    id: "1327",
    name: "Moyen-Ogooue",
    country_code: "GA",
  },
  {
    id: "1328",
    name: "Ngounie",
    country_code: "GA",
  },
  {
    id: "1329",
    name: "Nyanga",
    country_code: "GA",
  },
  {
    id: "1330",
    name: "Ogooue-Ivindo",
    country_code: "GA",
  },
  {
    id: "1331",
    name: "Ogooue-Lolo",
    country_code: "GA",
  },
  {
    id: "1332",
    name: "Ogooue-Maritime",
    country_code: "GA",
  },
  {
    id: "1333",
    name: "Woleu-Ntem",
    country_code: "GA",
  },
  {
    id: "1334",
    name: "Banjul",
    country_code: "GM",
  },
  {
    id: "1335",
    name: "Basse",
    country_code: "GM",
  },
  {
    id: "1336",
    name: "Brikama",
    country_code: "GM",
  },
  {
    id: "1337",
    name: "Janjanbureh",
    country_code: "GM",
  },
  {
    id: "1338",
    name: "Kanifing",
    country_code: "GM",
  },
  {
    id: "1339",
    name: "Kerewan",
    country_code: "GM",
  },
  {
    id: "1340",
    name: "Kuntaur",
    country_code: "GM",
  },
  {
    id: "1341",
    name: "Mansakonko",
    country_code: "GM",
  },
  {
    id: "1342",
    name: "Abhasia",
    country_code: "GE",
  },
  {
    id: "1343",
    name: "Ajaria",
    country_code: "GE",
  },
  {
    id: "1344",
    name: "Guria",
    country_code: "GE",
  },
  {
    id: "1345",
    name: "Imereti",
    country_code: "GE",
  },
  {
    id: "1346",
    name: "Kaheti",
    country_code: "GE",
  },
  {
    id: "1347",
    name: "Kvemo Kartli",
    country_code: "GE",
  },
  {
    id: "1348",
    name: "Mcheta-Mtianeti",
    country_code: "GE",
  },
  {
    id: "1349",
    name: "Racha",
    country_code: "GE",
  },
  {
    id: "1350",
    name: "Samagrelo-Zemo Svaneti",
    country_code: "GE",
  },
  {
    id: "1351",
    name: "Samche-Zhavaheti",
    country_code: "GE",
  },
  {
    id: "1352",
    name: "Shida Kartli",
    country_code: "GE",
  },
  {
    id: "1353",
    name: "Tbilisi",
    country_code: "GE",
  },
  {
    id: "1354",
    name: "Auvergne",
    country_code: "DE",
  },
  {
    id: "1355",
    name: "Baden-Wurttemberg",
    country_code: "DE",
  },
  {
    id: "1356",
    name: "Bavaria",
    country_code: "DE",
  },
  {
    id: "1357",
    name: "Bayern",
    country_code: "DE",
  },
  {
    id: "1358",
    name: "Beilstein Wurtt",
    country_code: "DE",
  },
  {
    id: "1359",
    name: "Berlin",
    country_code: "DE",
  },
  {
    id: "1360",
    name: "Brandenburg",
    country_code: "DE",
  },
  {
    id: "1361",
    name: "Bremen",
    country_code: "DE",
  },
  {
    id: "1362",
    name: "Dreisbach",
    country_code: "DE",
  },
  {
    id: "1363",
    name: "Freistaat Bayern",
    country_code: "DE",
  },
  {
    id: "1364",
    name: "Hamburg",
    country_code: "DE",
  },
  {
    id: "1365",
    name: "Hannover",
    country_code: "DE",
  },
  {
    id: "1366",
    name: "Heroldstatt",
    country_code: "DE",
  },
  {
    id: "1367",
    name: "Hessen",
    country_code: "DE",
  },
  {
    id: "1368",
    name: "Kortenberg",
    country_code: "DE",
  },
  {
    id: "1369",
    name: "Laasdorf",
    country_code: "DE",
  },
  {
    id: "1370",
    name: "Land Baden-Wurttemberg",
    country_code: "DE",
  },
  {
    id: "1371",
    name: "Land Bayern",
    country_code: "DE",
  },
  {
    id: "1372",
    name: "Land Brandenburg",
    country_code: "DE",
  },
  {
    id: "1373",
    name: "Land Hessen",
    country_code: "DE",
  },
  {
    id: "1374",
    name: "Land Mecklenburg-Vorpommern",
    country_code: "DE",
  },
  {
    id: "1375",
    name: "Land Nordrhein-Westfalen",
    country_code: "DE",
  },
  {
    id: "1376",
    name: "Land Rheinland-Pfalz",
    country_code: "DE",
  },
  {
    id: "1377",
    name: "Land Sachsen",
    country_code: "DE",
  },
  {
    id: "1378",
    name: "Land Sachsen-Anhalt",
    country_code: "DE",
  },
  {
    id: "1379",
    name: "Land Thuringen",
    country_code: "DE",
  },
  {
    id: "1380",
    name: "Lower Saxony",
    country_code: "DE",
  },
  {
    id: "1381",
    name: "Mecklenburg-Vorpommern",
    country_code: "DE",
  },
  {
    id: "1382",
    name: "Mulfingen",
    country_code: "DE",
  },
  {
    id: "1383",
    name: "Munich",
    country_code: "DE",
  },
  {
    id: "1384",
    name: "Neubeuern",
    country_code: "DE",
  },
  {
    id: "1385",
    name: "Niedersachsen",
    country_code: "DE",
  },
  {
    id: "1386",
    name: "Noord-Holland",
    country_code: "DE",
  },
  {
    id: "1387",
    name: "Nordrhein-Westfalen",
    country_code: "DE",
  },
  {
    id: "1388",
    name: "North Rhine-Westphalia",
    country_code: "DE",
  },
  {
    id: "1389",
    name: "Osterode",
    country_code: "DE",
  },
  {
    id: "1390",
    name: "Rheinland-Pfalz",
    country_code: "DE",
  },
  {
    id: "1391",
    name: "Rhineland-Palatinate",
    country_code: "DE",
  },
  {
    id: "1392",
    name: "Saarland",
    country_code: "DE",
  },
  {
    id: "1393",
    name: "Sachsen",
    country_code: "DE",
  },
  {
    id: "1394",
    name: "Sachsen-Anhalt",
    country_code: "DE",
  },
  {
    id: "1395",
    name: "Saxony",
    country_code: "DE",
  },
  {
    id: "1396",
    name: "Schleswig-Holstein",
    country_code: "DE",
  },
  {
    id: "1397",
    name: "Thuringia",
    country_code: "DE",
  },
  {
    id: "1398",
    name: "Webling",
    country_code: "DE",
  },
  {
    id: "1399",
    name: "Weinstrabe",
    country_code: "DE",
  },
  {
    id: "1400",
    name: "schlobborn",
    country_code: "DE",
  },
  {
    id: "1401",
    name: "Ashanti",
    country_code: "GH",
  },
  {
    id: "1402",
    name: "Brong-Ahafo",
    country_code: "GH",
  },
  {
    id: "1403",
    name: "Central",
    country_code: "GH",
  },
  {
    id: "1404",
    name: "Eastern",
    country_code: "GH",
  },
  {
    id: "1405",
    name: "Greater Accra",
    country_code: "GH",
  },
  {
    id: "1406",
    name: "Northern",
    country_code: "GH",
  },
  {
    id: "1407",
    name: "Upper East",
    country_code: "GH",
  },
  {
    id: "1408",
    name: "Upper West",
    country_code: "GH",
  },
  {
    id: "1409",
    name: "Volta",
    country_code: "GH",
  },
  {
    id: "1410",
    name: "Western",
    country_code: "GH",
  },
  {
    id: "1412",
    name: "Acharnes",
    country_code: "GR",
  },
  {
    id: "1413",
    name: "Ahaia",
    country_code: "GR",
  },
  {
    id: "1414",
    name: "Aitolia kai Akarnania",
    country_code: "GR",
  },
  {
    id: "1415",
    name: "Argolis",
    country_code: "GR",
  },
  {
    id: "1416",
    name: "Arkadia",
    country_code: "GR",
  },
  {
    id: "1417",
    name: "Arta",
    country_code: "GR",
  },
  {
    id: "1418",
    name: "Attica",
    country_code: "GR",
  },
  {
    id: "1419",
    name: "Attiki",
    country_code: "GR",
  },
  {
    id: "1420",
    name: "Ayion Oros",
    country_code: "GR",
  },
  {
    id: "1421",
    name: "Crete",
    country_code: "GR",
  },
  {
    id: "1422",
    name: "Dodekanisos",
    country_code: "GR",
  },
  {
    id: "1423",
    name: "Drama",
    country_code: "GR",
  },
  {
    id: "1424",
    name: "Evia",
    country_code: "GR",
  },
  {
    id: "1425",
    name: "Evritania",
    country_code: "GR",
  },
  {
    id: "1426",
    name: "Evros",
    country_code: "GR",
  },
  {
    id: "1427",
    name: "Evvoia",
    country_code: "GR",
  },
  {
    id: "1428",
    name: "Florina",
    country_code: "GR",
  },
  {
    id: "1429",
    name: "Fokis",
    country_code: "GR",
  },
  {
    id: "1430",
    name: "Fthiotis",
    country_code: "GR",
  },
  {
    id: "1431",
    name: "Grevena",
    country_code: "GR",
  },
  {
    id: "1432",
    name: "Halandri",
    country_code: "GR",
  },
  {
    id: "1433",
    name: "Halkidiki",
    country_code: "GR",
  },
  {
    id: "1434",
    name: "Hania",
    country_code: "GR",
  },
  {
    id: "1435",
    name: "Heraklion",
    country_code: "GR",
  },
  {
    id: "1436",
    name: "Hios",
    country_code: "GR",
  },
  {
    id: "1437",
    name: "Ilia",
    country_code: "GR",
  },
  {
    id: "1438",
    name: "Imathia",
    country_code: "GR",
  },
  {
    id: "1439",
    name: "Ioannina",
    country_code: "GR",
  },
  {
    id: "1440",
    name: "Iraklion",
    country_code: "GR",
  },
  {
    id: "1441",
    name: "Karditsa",
    country_code: "GR",
  },
  {
    id: "1442",
    name: "Kastoria",
    country_code: "GR",
  },
  {
    id: "1443",
    name: "Kavala",
    country_code: "GR",
  },
  {
    id: "1444",
    name: "Kefallinia",
    country_code: "GR",
  },
  {
    id: "1445",
    name: "Kerkira",
    country_code: "GR",
  },
  {
    id: "1446",
    name: "Kiklades",
    country_code: "GR",
  },
  {
    id: "1447",
    name: "Kilkis",
    country_code: "GR",
  },
  {
    id: "1448",
    name: "Korinthia",
    country_code: "GR",
  },
  {
    id: "1449",
    name: "Kozani",
    country_code: "GR",
  },
  {
    id: "1450",
    name: "Lakonia",
    country_code: "GR",
  },
  {
    id: "1451",
    name: "Larisa",
    country_code: "GR",
  },
  {
    id: "1452",
    name: "Lasithi",
    country_code: "GR",
  },
  {
    id: "1453",
    name: "Lesvos",
    country_code: "GR",
  },
  {
    id: "1454",
    name: "Levkas",
    country_code: "GR",
  },
  {
    id: "1455",
    name: "Magnisia",
    country_code: "GR",
  },
  {
    id: "1456",
    name: "Messinia",
    country_code: "GR",
  },
  {
    id: "1457",
    name: "Nomos Attikis",
    country_code: "GR",
  },
  {
    id: "1458",
    name: "Nomos Zakynthou",
    country_code: "GR",
  },
  {
    id: "1459",
    name: "Pella",
    country_code: "GR",
  },
  {
    id: "1460",
    name: "Pieria",
    country_code: "GR",
  },
  {
    id: "1461",
    name: "Piraios",
    country_code: "GR",
  },
  {
    id: "1462",
    name: "Preveza",
    country_code: "GR",
  },
  {
    id: "1463",
    name: "Rethimni",
    country_code: "GR",
  },
  {
    id: "1464",
    name: "Rodopi",
    country_code: "GR",
  },
  {
    id: "1465",
    name: "Samos",
    country_code: "GR",
  },
  {
    id: "1466",
    name: "Serrai",
    country_code: "GR",
  },
  {
    id: "1467",
    name: "Thesprotia",
    country_code: "GR",
  },
  {
    id: "1468",
    name: "Thessaloniki",
    country_code: "GR",
  },
  {
    id: "1469",
    name: "Trikala",
    country_code: "GR",
  },
  {
    id: "1470",
    name: "Voiotia",
    country_code: "GR",
  },
  {
    id: "1471",
    name: "West Greece",
    country_code: "GR",
  },
  {
    id: "1472",
    name: "Xanthi",
    country_code: "GR",
  },
  {
    id: "1473",
    name: "Zakinthos",
    country_code: "GR",
  },
  {
    id: "1493",
    name: "Carriacou-Petite Martinique",
    country_code: "GD",
  },
  {
    id: "1494",
    name: "Saint Andrew",
    country_code: "GD",
  },
  {
    id: "1495",
    name: "Saint Davids",
    country_code: "GD",
  },
  {
    id: "1496",
    name: "Saint George''s",
    country_code: "GD",
  },
  {
    id: "1497",
    name: "Saint John",
    country_code: "GD",
  },
  {
    id: "1498",
    name: "Saint Mark",
    country_code: "GD",
  },
  {
    id: "1499",
    name: "Saint Patrick",
    country_code: "GD",
  },
  {
    id: "1523",
    name: "Alta Verapaz",
    country_code: "GT",
  },
  {
    id: "1524",
    name: "Baja Verapaz",
    country_code: "GT",
  },
  {
    id: "1525",
    name: "Chimaltenango",
    country_code: "GT",
  },
  {
    id: "1526",
    name: "Chiquimula",
    country_code: "GT",
  },
  {
    id: "1527",
    name: "El Progreso",
    country_code: "GT",
  },
  {
    id: "1528",
    name: "Escuintla",
    country_code: "GT",
  },
  {
    id: "1529",
    name: "Guatemala",
    country_code: "GT",
  },
  {
    id: "1530",
    name: "Huehuetenango",
    country_code: "GT",
  },
  {
    id: "1531",
    name: "Izabal",
    country_code: "GT",
  },
  {
    id: "1532",
    name: "Jalapa",
    country_code: "GT",
  },
  {
    id: "1533",
    name: "Jutiapa",
    country_code: "GT",
  },
  {
    id: "1534",
    name: "Peten",
    country_code: "GT",
  },
  {
    id: "1535",
    name: "Quezaltenango",
    country_code: "GT",
  },
  {
    id: "1536",
    name: "Quiche",
    country_code: "GT",
  },
  {
    id: "1537",
    name: "Retalhuleu",
    country_code: "GT",
  },
  {
    id: "1538",
    name: "Sacatepequez",
    country_code: "GT",
  },
  {
    id: "1539",
    name: "San Marcos",
    country_code: "GT",
  },
  {
    id: "1540",
    name: "Santa Rosa",
    country_code: "GT",
  },
  {
    id: "1541",
    name: "Solola",
    country_code: "GT",
  },
  {
    id: "1542",
    name: "Suchitepequez",
    country_code: "GT",
  },
  {
    id: "1543",
    name: "Totonicapan",
    country_code: "GT",
  },
  {
    id: "1544",
    name: "Zacapa",
    country_code: "GT",
  },
  {
    id: "1557",
    name: "Beyla",
    country_code: "GN",
  },
  {
    id: "1558",
    name: "Boffa",
    country_code: "GN",
  },
  {
    id: "1559",
    name: "Boke",
    country_code: "GN",
  },
  {
    id: "1560",
    name: "Conakry",
    country_code: "GN",
  },
  {
    id: "1561",
    name: "Coyah",
    country_code: "GN",
  },
  {
    id: "1562",
    name: "Dabola",
    country_code: "GN",
  },
  {
    id: "1563",
    name: "Dalaba",
    country_code: "GN",
  },
  {
    id: "1564",
    name: "Dinguiraye",
    country_code: "GN",
  },
  {
    id: "1565",
    name: "Faranah",
    country_code: "GN",
  },
  {
    id: "1566",
    name: "Forecariah",
    country_code: "GN",
  },
  {
    id: "1567",
    name: "Fria",
    country_code: "GN",
  },
  {
    id: "1568",
    name: "Gaoual",
    country_code: "GN",
  },
  {
    id: "1569",
    name: "Gueckedou",
    country_code: "GN",
  },
  {
    id: "1570",
    name: "Kankan",
    country_code: "GN",
  },
  {
    id: "1571",
    name: "Kerouane",
    country_code: "GN",
  },
  {
    id: "1572",
    name: "Kindia",
    country_code: "GN",
  },
  {
    id: "1573",
    name: "Kissidougou",
    country_code: "GN",
  },
  {
    id: "1574",
    name: "Koubia",
    country_code: "GN",
  },
  {
    id: "1575",
    name: "Koundara",
    country_code: "GN",
  },
  {
    id: "1576",
    name: "Kouroussa",
    country_code: "GN",
  },
  {
    id: "1577",
    name: "Labe",
    country_code: "GN",
  },
  {
    id: "1578",
    name: "Lola",
    country_code: "GN",
  },
  {
    id: "1579",
    name: "Macenta",
    country_code: "GN",
  },
  {
    id: "1580",
    name: "Mali",
    country_code: "GN",
  },
  {
    id: "1581",
    name: "Mamou",
    country_code: "GN",
  },
  {
    id: "1582",
    name: "Mandiana",
    country_code: "GN",
  },
  {
    id: "1583",
    name: "Nzerekore",
    country_code: "GN",
  },
  {
    id: "1584",
    name: "Pita",
    country_code: "GN",
  },
  {
    id: "1585",
    name: "Siguiri",
    country_code: "GN",
  },
  {
    id: "1586",
    name: "Telimele",
    country_code: "GN",
  },
  {
    id: "1587",
    name: "Tougue",
    country_code: "GN",
  },
  {
    id: "1588",
    name: "Yomou",
    country_code: "GN",
  },
  {
    id: "1589",
    name: "Bafata",
    country_code: "GW",
  },
  {
    id: "1590",
    name: "Bissau",
    country_code: "GW",
  },
  {
    id: "1591",
    name: "Bolama",
    country_code: "GW",
  },
  {
    id: "1592",
    name: "Cacheu",
    country_code: "GW",
  },
  {
    id: "1593",
    name: "Gabu",
    country_code: "GW",
  },
  {
    id: "1594",
    name: "Oio",
    country_code: "GW",
  },
  {
    id: "1595",
    name: "Quinara",
    country_code: "GW",
  },
  {
    id: "1596",
    name: "Tombali",
    country_code: "GW",
  },
  {
    id: "1597",
    name: "Barima-Waini",
    country_code: "GY",
  },
  {
    id: "1598",
    name: "Cuyuni-Mazaruni",
    country_code: "GY",
  },
  {
    id: "1599",
    name: "Demerara-Mahaica",
    country_code: "GY",
  },
  {
    id: "1600",
    name: "East Berbice-Corentyne",
    country_code: "GY",
  },
  {
    id: "1601",
    name: "Essequibo Islands-West Demerar",
    country_code: "GY",
  },
  {
    id: "1602",
    name: "Mahaica-Berbice",
    country_code: "GY",
  },
  {
    id: "1603",
    name: "Pomeroon-Supenaam",
    country_code: "GY",
  },
  {
    id: "1604",
    name: "Potaro-Siparuni",
    country_code: "GY",
  },
  {
    id: "1605",
    name: "Upper Demerara-Berbice",
    country_code: "GY",
  },
  {
    id: "1606",
    name: "Upper Takutu-Upper Essequibo",
    country_code: "GY",
  },
  {
    id: "1607",
    name: "Artibonite",
    country_code: "HT",
  },
  {
    id: "1608",
    name: "Centre",
    country_code: "HT",
  },
  {
    id: "1609",
    name: "Grand''Anse",
    country_code: "HT",
  },
  {
    id: "1610",
    name: "Nord",
    country_code: "HT",
  },
  {
    id: "1611",
    name: "Nord-Est",
    country_code: "HT",
  },
  {
    id: "1612",
    name: "Nord-Ouest",
    country_code: "HT",
  },
  {
    id: "1613",
    name: "Ouest",
    country_code: "HT",
  },
  {
    id: "1614",
    name: "Sud",
    country_code: "HT",
  },
  {
    id: "1615",
    name: "Sud-Est",
    country_code: "HT",
  },
  {
    id: "1617",
    name: "Atlantida",
    country_code: "HN",
  },
  {
    id: "1618",
    name: "Choluteca",
    country_code: "HN",
  },
  {
    id: "1619",
    name: "Colon",
    country_code: "HN",
  },
  {
    id: "1620",
    name: "Comayagua",
    country_code: "HN",
  },
  {
    id: "1621",
    name: "Copan",
    country_code: "HN",
  },
  {
    id: "1622",
    name: "Cortes",
    country_code: "HN",
  },
  {
    id: "1623",
    name: "Distrito Central",
    country_code: "HN",
  },
  {
    id: "1624",
    name: "El Paraiso",
    country_code: "HN",
  },
  {
    id: "1625",
    name: "Francisco Morazan",
    country_code: "HN",
  },
  {
    id: "1626",
    name: "Gracias a Dios",
    country_code: "HN",
  },
  {
    id: "1627",
    name: "Intibuca",
    country_code: "HN",
  },
  {
    id: "1628",
    name: "Islas de la Bahia",
    country_code: "HN",
  },
  {
    id: "1629",
    name: "La Paz",
    country_code: "HN",
  },
  {
    id: "1630",
    name: "Lempira",
    country_code: "HN",
  },
  {
    id: "1631",
    name: "Ocotepeque",
    country_code: "HN",
  },
  {
    id: "1632",
    name: "Olancho",
    country_code: "HN",
  },
  {
    id: "1633",
    name: "Santa Barbara",
    country_code: "HN",
  },
  {
    id: "1634",
    name: "Valle",
    country_code: "HN",
  },
  {
    id: "1635",
    name: "Yoro",
    country_code: "HN",
  },
  {
    id: "1637",
    name: "Bacs-Kiskun",
    country_code: "HU",
  },
  {
    id: "1638",
    name: "Baranya",
    country_code: "HU",
  },
  {
    id: "1639",
    name: "Bekes",
    country_code: "HU",
  },
  {
    id: "1640",
    name: "Borsod-Abauj-Zemplen",
    country_code: "HU",
  },
  {
    id: "1641",
    name: "Budapest",
    country_code: "HU",
  },
  {
    id: "1642",
    name: "Csongrad",
    country_code: "HU",
  },
  {
    id: "1643",
    name: "Fejer",
    country_code: "HU",
  },
  {
    id: "1644",
    name: "Gyor-Moson-Sopron",
    country_code: "HU",
  },
  {
    id: "1645",
    name: "Hajdu-Bihar",
    country_code: "HU",
  },
  {
    id: "1646",
    name: "Heves",
    country_code: "HU",
  },
  {
    id: "1647",
    name: "Jasz-Nagykun-Szolnok",
    country_code: "HU",
  },
  {
    id: "1648",
    name: "Komarom-Esztergom",
    country_code: "HU",
  },
  {
    id: "1649",
    name: "Nograd",
    country_code: "HU",
  },
  {
    id: "1650",
    name: "Pest",
    country_code: "HU",
  },
  {
    id: "1651",
    name: "Somogy",
    country_code: "HU",
  },
  {
    id: "1652",
    name: "Szabolcs-Szatmar-Bereg",
    country_code: "HU",
  },
  {
    id: "1653",
    name: "Tolna",
    country_code: "HU",
  },
  {
    id: "1654",
    name: "Vas",
    country_code: "HU",
  },
  {
    id: "1655",
    name: "Veszprem",
    country_code: "HU",
  },
  {
    id: "1656",
    name: "Zala",
    country_code: "HU",
  },
  {
    id: "1657",
    name: "Austurland",
    country_code: "IS",
  },
  {
    id: "1658",
    name: "Gullbringusysla",
    country_code: "IS",
  },
  {
    id: "1659",
    name: "Hofu borgarsva i",
    country_code: "IS",
  },
  {
    id: "1660",
    name: "Nor urland eystra",
    country_code: "IS",
  },
  {
    id: "1661",
    name: "Nor urland vestra",
    country_code: "IS",
  },
  {
    id: "1662",
    name: "Su urland",
    country_code: "IS",
  },
  {
    id: "1663",
    name: "Su urnes",
    country_code: "IS",
  },
  {
    id: "1664",
    name: "Vestfir ir",
    country_code: "IS",
  },
  {
    id: "1665",
    name: "Vesturland",
    country_code: "IS",
  },
  {
    id: "1666",
    name: "Aceh",
    country_code: "ID",
  },
  {
    id: "1667",
    name: "Bali",
    country_code: "ID",
  },
  {
    id: "1668",
    name: "Bangka-Belitung",
    country_code: "ID",
  },
  {
    id: "1669",
    name: "Banten",
    country_code: "ID",
  },
  {
    id: "1670",
    name: "Bengkulu",
    country_code: "ID",
  },
  {
    id: "1671",
    name: "Gandaria",
    country_code: "ID",
  },
  {
    id: "1672",
    name: "Gorontalo",
    country_code: "ID",
  },
  {
    id: "1673",
    name: "Jakarta",
    country_code: "ID",
  },
  {
    id: "1674",
    name: "Jambi",
    country_code: "ID",
  },
  {
    id: "1675",
    name: "Jawa Barat",
    country_code: "ID",
  },
  {
    id: "1676",
    name: "Jawa Tengah",
    country_code: "ID",
  },
  {
    id: "1677",
    name: "Jawa Timur",
    country_code: "ID",
  },
  {
    id: "1678",
    name: "Kalimantan Barat",
    country_code: "ID",
  },
  {
    id: "1679",
    name: "Kalimantan Selatan",
    country_code: "ID",
  },
  {
    id: "1680",
    name: "Kalimantan Tengah",
    country_code: "ID",
  },
  {
    id: "1681",
    name: "Kalimantan Timur",
    country_code: "ID",
  },
  {
    id: "1682",
    name: "Kendal",
    country_code: "ID",
  },
  {
    id: "1683",
    name: "Lampung",
    country_code: "ID",
  },
  {
    id: "1684",
    name: "Maluku",
    country_code: "ID",
  },
  {
    id: "1685",
    name: "Maluku Utara",
    country_code: "ID",
  },
  {
    id: "1686",
    name: "Nusa Tenggara Barat",
    country_code: "ID",
  },
  {
    id: "1687",
    name: "Nusa Tenggara Timur",
    country_code: "ID",
  },
  {
    id: "1688",
    name: "Papua",
    country_code: "ID",
  },
  {
    id: "1689",
    name: "Riau",
    country_code: "ID",
  },
  {
    id: "1690",
    name: "Riau Kepulauan",
    country_code: "ID",
  },
  {
    id: "1691",
    name: "Solo",
    country_code: "ID",
  },
  {
    id: "1692",
    name: "Sulawesi Selatan",
    country_code: "ID",
  },
  {
    id: "1693",
    name: "Sulawesi Tengah",
    country_code: "ID",
  },
  {
    id: "1694",
    name: "Sulawesi Tenggara",
    country_code: "ID",
  },
  {
    id: "1695",
    name: "Sulawesi Utara",
    country_code: "ID",
  },
  {
    id: "1696",
    name: "Sumatera Barat",
    country_code: "ID",
  },
  {
    id: "1697",
    name: "Sumatera Selatan",
    country_code: "ID",
  },
  {
    id: "1698",
    name: "Sumatera Utara",
    country_code: "ID",
  },
  {
    id: "1699",
    name: "Yogyakarta",
    country_code: "ID",
  },
  {
    id: "1700",
    name: "Ardabil",
    country_code: "IR",
  },
  {
    id: "1701",
    name: "Azarbayjan-e Bakhtari",
    country_code: "IR",
  },
  {
    id: "1702",
    name: "Azarbayjan-e Khavari",
    country_code: "IR",
  },
  {
    id: "1703",
    name: "Bushehr",
    country_code: "IR",
  },
  {
    id: "1704",
    name: "Chahar Mahal-e Bakhtiari",
    country_code: "IR",
  },
  {
    id: "1705",
    name: "Esfahan",
    country_code: "IR",
  },
  {
    id: "1706",
    name: "Fars",
    country_code: "IR",
  },
  {
    id: "1707",
    name: "Gilan",
    country_code: "IR",
  },
  {
    id: "1708",
    name: "Golestan",
    country_code: "IR",
  },
  {
    id: "1709",
    name: "Hamadan",
    country_code: "IR",
  },
  {
    id: "1710",
    name: "Hormozgan",
    country_code: "IR",
  },
  {
    id: "1711",
    name: "Ilam",
    country_code: "IR",
  },
  {
    id: "1712",
    name: "Kerman",
    country_code: "IR",
  },
  {
    id: "1713",
    name: "Kermanshah",
    country_code: "IR",
  },
  {
    id: "1714",
    name: "Khorasan",
    country_code: "IR",
  },
  {
    id: "1715",
    name: "Khuzestan",
    country_code: "IR",
  },
  {
    id: "1716",
    name: "Kohgiluyeh-e Boyerahmad",
    country_code: "IR",
  },
  {
    id: "1717",
    name: "Kordestan",
    country_code: "IR",
  },
  {
    id: "1718",
    name: "Lorestan",
    country_code: "IR",
  },
  {
    id: "1719",
    name: "Markazi",
    country_code: "IR",
  },
  {
    id: "1720",
    name: "Mazandaran",
    country_code: "IR",
  },
  {
    id: "1721",
    name: "Ostan-e Esfahan",
    country_code: "IR",
  },
  {
    id: "1722",
    name: "Qazvin",
    country_code: "IR",
  },
  {
    id: "1723",
    name: "Qom",
    country_code: "IR",
  },
  {
    id: "1724",
    name: "Semnan",
    country_code: "IR",
  },
  {
    id: "1725",
    name: "Sistan-e Baluchestan",
    country_code: "IR",
  },
  {
    id: "1726",
    name: "Tehran",
    country_code: "IR",
  },
  {
    id: "1727",
    name: "Yazd",
    country_code: "IR",
  },
  {
    id: "1728",
    name: "Zanjan",
    country_code: "IR",
  },
  {
    id: "1729",
    name: "Babil",
    country_code: "IQ",
  },
  {
    id: "1730",
    name: "Baghdad",
    country_code: "IQ",
  },
  {
    id: "1731",
    name: "Dahuk",
    country_code: "IQ",
  },
  {
    id: "1732",
    name: "Dhi Qar",
    country_code: "IQ",
  },
  {
    id: "1733",
    name: "Diyala",
    country_code: "IQ",
  },
  {
    id: "1734",
    name: "Erbil",
    country_code: "IQ",
  },
  {
    id: "1735",
    name: "Irbil",
    country_code: "IQ",
  },
  {
    id: "1736",
    name: "Karbala",
    country_code: "IQ",
  },
  {
    id: "1737",
    name: "Kurdistan",
    country_code: "IQ",
  },
  {
    id: "1738",
    name: "Maysan",
    country_code: "IQ",
  },
  {
    id: "1739",
    name: "Ninawa",
    country_code: "IQ",
  },
  {
    id: "1740",
    name: "Salah-ad-Din",
    country_code: "IQ",
  },
  {
    id: "1741",
    name: "Wasit",
    country_code: "IQ",
  },
  {
    id: "1742",
    name: "al-Anbar",
    country_code: "IQ",
  },
  {
    id: "1743",
    name: "al-Basrah",
    country_code: "IQ",
  },
  {
    id: "1744",
    name: "al-Muthanna",
    country_code: "IQ",
  },
  {
    id: "1745",
    name: "al-Qadisiyah",
    country_code: "IQ",
  },
  {
    id: "1746",
    name: "an-Najaf",
    country_code: "IQ",
  },
  {
    id: "1747",
    name: "as-Sulaymaniyah",
    country_code: "IQ",
  },
  {
    id: "1748",
    name: "at-Ta''mim",
    country_code: "IQ",
  },
  {
    id: "1749",
    name: "Armagh",
    country_code: "IE",
  },
  {
    id: "1750",
    name: "Carlow",
    country_code: "IE",
  },
  {
    id: "1751",
    name: "Cavan",
    country_code: "IE",
  },
  {
    id: "1752",
    name: "Clare",
    country_code: "IE",
  },
  {
    id: "1753",
    name: "Cork",
    country_code: "IE",
  },
  {
    id: "1754",
    name: "Donegal",
    country_code: "IE",
  },
  {
    id: "1755",
    name: "Dublin",
    country_code: "IE",
  },
  {
    id: "1756",
    name: "Galway",
    country_code: "IE",
  },
  {
    id: "1757",
    name: "Kerry",
    country_code: "IE",
  },
  {
    id: "1758",
    name: "Kildare",
    country_code: "IE",
  },
  {
    id: "1759",
    name: "Kilkenny",
    country_code: "IE",
  },
  {
    id: "1760",
    name: "Laois",
    country_code: "IE",
  },
  {
    id: "1761",
    name: "Leinster",
    country_code: "IE",
  },
  {
    id: "1762",
    name: "Leitrim",
    country_code: "IE",
  },
  {
    id: "1763",
    name: "Limerick",
    country_code: "IE",
  },
  {
    id: "1764",
    name: "Loch Garman",
    country_code: "IE",
  },
  {
    id: "1765",
    name: "Longford",
    country_code: "IE",
  },
  {
    id: "1766",
    name: "Louth",
    country_code: "IE",
  },
  {
    id: "1767",
    name: "Mayo",
    country_code: "IE",
  },
  {
    id: "1768",
    name: "Meath",
    country_code: "IE",
  },
  {
    id: "1769",
    name: "Monaghan",
    country_code: "IE",
  },
  {
    id: "1770",
    name: "Offaly",
    country_code: "IE",
  },
  {
    id: "1771",
    name: "Roscommon",
    country_code: "IE",
  },
  {
    id: "1772",
    name: "Sligo",
    country_code: "IE",
  },
  {
    id: "1773",
    name: "Tipperary North Riding",
    country_code: "IE",
  },
  {
    id: "1774",
    name: "Tipperary South Riding",
    country_code: "IE",
  },
  {
    id: "1775",
    name: "Ulster",
    country_code: "IE",
  },
  {
    id: "1776",
    name: "Waterford",
    country_code: "IE",
  },
  {
    id: "1777",
    name: "Westmeath",
    country_code: "IE",
  },
  {
    id: "1778",
    name: "Wexford",
    country_code: "IE",
  },
  {
    id: "1779",
    name: "Wicklow",
    country_code: "IE",
  },
  {
    id: "1780",
    name: "Beit Hanania",
    country_code: "IL",
  },
  {
    id: "1781",
    name: "Ben Gurion Airport",
    country_code: "IL",
  },
  {
    id: "1782",
    name: "Bethlehem",
    country_code: "IL",
  },
  {
    id: "1783",
    name: "Caesarea",
    country_code: "IL",
  },
  {
    id: "1784",
    name: "Centre",
    country_code: "IL",
  },
  {
    id: "1785",
    name: "Gaza",
    country_code: "IL",
  },
  {
    id: "1786",
    name: "Hadaron",
    country_code: "IL",
  },
  {
    id: "1787",
    name: "Haifa District",
    country_code: "IL",
  },
  {
    id: "1788",
    name: "Hamerkaz",
    country_code: "IL",
  },
  {
    id: "1789",
    name: "Hazafon",
    country_code: "IL",
  },
  {
    id: "1790",
    name: "Hebron",
    country_code: "IL",
  },
  {
    id: "1791",
    name: "Jaffa",
    country_code: "IL",
  },
  {
    id: "1792",
    name: "Jerusalem",
    country_code: "IL",
  },
  {
    id: "1793",
    name: "Khefa",
    country_code: "IL",
  },
  {
    id: "1794",
    name: "Kiryat Yam",
    country_code: "IL",
  },
  {
    id: "1795",
    name: "Lower Galilee",
    country_code: "IL",
  },
  {
    id: "1796",
    name: "Qalqilya",
    country_code: "IL",
  },
  {
    id: "1797",
    name: "Talme Elazar",
    country_code: "IL",
  },
  {
    id: "1798",
    name: "Tel Aviv",
    country_code: "IL",
  },
  {
    id: "1799",
    name: "Tsafon",
    country_code: "IL",
  },
  {
    id: "1800",
    name: "Umm El Fahem",
    country_code: "IL",
  },
  {
    id: "1801",
    name: "Yerushalayim",
    country_code: "IL",
  },
  {
    id: "1802",
    name: "Abruzzi",
    country_code: "IT",
  },
  {
    id: "1803",
    name: "Abruzzo",
    country_code: "IT",
  },
  {
    id: "1804",
    name: "Agrigento",
    country_code: "IT",
  },
  {
    id: "1805",
    name: "Alessandria",
    country_code: "IT",
  },
  {
    id: "1806",
    name: "Ancona",
    country_code: "IT",
  },
  {
    id: "1807",
    name: "Arezzo",
    country_code: "IT",
  },
  {
    id: "1808",
    name: "Ascoli Piceno",
    country_code: "IT",
  },
  {
    id: "1809",
    name: "Asti",
    country_code: "IT",
  },
  {
    id: "1810",
    name: "Avellino",
    country_code: "IT",
  },
  {
    id: "1811",
    name: "Bari",
    country_code: "IT",
  },
  {
    id: "1812",
    name: "Basilicata",
    country_code: "IT",
  },
  {
    id: "1813",
    name: "Belluno",
    country_code: "IT",
  },
  {
    id: "1814",
    name: "Benevento",
    country_code: "IT",
  },
  {
    id: "1815",
    name: "Bergamo",
    country_code: "IT",
  },
  {
    id: "1816",
    name: "Biella",
    country_code: "IT",
  },
  {
    id: "1817",
    name: "Bologna",
    country_code: "IT",
  },
  {
    id: "1818",
    name: "Bolzano",
    country_code: "IT",
  },
  {
    id: "1819",
    name: "Brescia",
    country_code: "IT",
  },
  {
    id: "1820",
    name: "Brindisi",
    country_code: "IT",
  },
  {
    id: "1821",
    name: "Calabria",
    country_code: "IT",
  },
  {
    id: "1822",
    name: "Campania",
    country_code: "IT",
  },
  {
    id: "1823",
    name: "Cartoceto",
    country_code: "IT",
  },
  {
    id: "1824",
    name: "Caserta",
    country_code: "IT",
  },
  {
    id: "1825",
    name: "Catania",
    country_code: "IT",
  },
  {
    id: "1826",
    name: "Chieti",
    country_code: "IT",
  },
  {
    id: "1827",
    name: "Como",
    country_code: "IT",
  },
  {
    id: "1828",
    name: "Cosenza",
    country_code: "IT",
  },
  {
    id: "1829",
    name: "Cremona",
    country_code: "IT",
  },
  {
    id: "1830",
    name: "Cuneo",
    country_code: "IT",
  },
  {
    id: "1831",
    name: "Emilia-Romagna",
    country_code: "IT",
  },
  {
    id: "1832",
    name: "Ferrara",
    country_code: "IT",
  },
  {
    id: "1833",
    name: "Firenze",
    country_code: "IT",
  },
  {
    id: "1834",
    name: "Florence",
    country_code: "IT",
  },
  {
    id: "1835",
    name: "Forli-Cesena",
    country_code: "IT",
  },
  {
    id: "1836",
    name: "Friuli-Venezia Giulia",
    country_code: "IT",
  },
  {
    id: "1837",
    name: "Frosinone",
    country_code: "IT",
  },
  {
    id: "1838",
    name: "Genoa",
    country_code: "IT",
  },
  {
    id: "1839",
    name: "Gorizia",
    country_code: "IT",
  },
  {
    id: "1840",
    name: "L''Aquila",
    country_code: "IT",
  },
  {
    id: "1841",
    name: "Lazio",
    country_code: "IT",
  },
  {
    id: "1842",
    name: "Lecce",
    country_code: "IT",
  },
  {
    id: "1843",
    name: "Lecco",
    country_code: "IT",
  },
  {
    id: "1844",
    name: "Lecco Province",
    country_code: "IT",
  },
  {
    id: "1845",
    name: "Liguria",
    country_code: "IT",
  },
  {
    id: "1846",
    name: "Lodi",
    country_code: "IT",
  },
  {
    id: "1847",
    name: "Lombardia",
    country_code: "IT",
  },
  {
    id: "1848",
    name: "Lombardy",
    country_code: "IT",
  },
  {
    id: "1849",
    name: "Macerata",
    country_code: "IT",
  },
  {
    id: "1850",
    name: "Mantova",
    country_code: "IT",
  },
  {
    id: "1851",
    name: "Marche",
    country_code: "IT",
  },
  {
    id: "1852",
    name: "Messina",
    country_code: "IT",
  },
  {
    id: "1853",
    name: "Milan",
    country_code: "IT",
  },
  {
    id: "1854",
    name: "Modena",
    country_code: "IT",
  },
  {
    id: "1855",
    name: "Molise",
    country_code: "IT",
  },
  {
    id: "1856",
    name: "Molteno",
    country_code: "IT",
  },
  {
    id: "1857",
    name: "Montenegro",
    country_code: "IT",
  },
  {
    id: "1858",
    name: "Monza and Brianza",
    country_code: "IT",
  },
  {
    id: "1859",
    name: "Naples",
    country_code: "IT",
  },
  {
    id: "1860",
    name: "Novara",
    country_code: "IT",
  },
  {
    id: "1861",
    name: "Padova",
    country_code: "IT",
  },
  {
    id: "1862",
    name: "Parma",
    country_code: "IT",
  },
  {
    id: "1863",
    name: "Pavia",
    country_code: "IT",
  },
  {
    id: "1864",
    name: "Perugia",
    country_code: "IT",
  },
  {
    id: "1865",
    name: "Pesaro-Urbino",
    country_code: "IT",
  },
  {
    id: "1866",
    name: "Piacenza",
    country_code: "IT",
  },
  {
    id: "1867",
    name: "Piedmont",
    country_code: "IT",
  },
  {
    id: "1868",
    name: "Piemonte",
    country_code: "IT",
  },
  {
    id: "1869",
    name: "Pisa",
    country_code: "IT",
  },
  {
    id: "1870",
    name: "Pordenone",
    country_code: "IT",
  },
  {
    id: "1871",
    name: "Potenza",
    country_code: "IT",
  },
  {
    id: "1872",
    name: "Puglia",
    country_code: "IT",
  },
  {
    id: "1873",
    name: "Reggio Emilia",
    country_code: "IT",
  },
  {
    id: "1874",
    name: "Rimini",
    country_code: "IT",
  },
  {
    id: "1875",
    name: "Roma",
    country_code: "IT",
  },
  {
    id: "1876",
    name: "Salerno",
    country_code: "IT",
  },
  {
    id: "1877",
    name: "Sardegna",
    country_code: "IT",
  },
  {
    id: "1878",
    name: "Sassari",
    country_code: "IT",
  },
  {
    id: "1879",
    name: "Savona",
    country_code: "IT",
  },
  {
    id: "1880",
    name: "Sicilia",
    country_code: "IT",
  },
  {
    id: "1881",
    name: "Siena",
    country_code: "IT",
  },
  {
    id: "1882",
    name: "Sondrio",
    country_code: "IT",
  },
  {
    id: "1883",
    name: "South Tyrol",
    country_code: "IT",
  },
  {
    id: "1884",
    name: "Taranto",
    country_code: "IT",
  },
  {
    id: "1885",
    name: "Teramo",
    country_code: "IT",
  },
  {
    id: "1886",
    name: "Torino",
    country_code: "IT",
  },
  {
    id: "1887",
    name: "Toscana",
    country_code: "IT",
  },
  {
    id: "1888",
    name: "Trapani",
    country_code: "IT",
  },
  {
    id: "1889",
    name: "Trentino-Alto Adige",
    country_code: "IT",
  },
  {
    id: "1890",
    name: "Trento",
    country_code: "IT",
  },
  {
    id: "1891",
    name: "Treviso",
    country_code: "IT",
  },
  {
    id: "1892",
    name: "Udine",
    country_code: "IT",
  },
  {
    id: "1893",
    name: "Umbria",
    country_code: "IT",
  },
  {
    id: "1894",
    name: "Valle d''Aosta",
    country_code: "IT",
  },
  {
    id: "1895",
    name: "Varese",
    country_code: "IT",
  },
  {
    id: "1896",
    name: "Veneto",
    country_code: "IT",
  },
  {
    id: "1897",
    name: "Venezia",
    country_code: "IT",
  },
  {
    id: "1898",
    name: "Verbano-Cusio-Ossola",
    country_code: "IT",
  },
  {
    id: "1899",
    name: "Vercelli",
    country_code: "IT",
  },
  {
    id: "1900",
    name: "Verona",
    country_code: "IT",
  },
  {
    id: "1901",
    name: "Vicenza",
    country_code: "IT",
  },
  {
    id: "1902",
    name: "Viterbo",
    country_code: "IT",
  },
  {
    id: "1903",
    name: "Buxoro Viloyati",
    country_code: "JM",
  },
  {
    id: "1904",
    name: "Clarendon",
    country_code: "JM",
  },
  {
    id: "1905",
    name: "Hanover",
    country_code: "JM",
  },
  {
    id: "1906",
    name: "Kingston",
    country_code: "JM",
  },
  {
    id: "1907",
    name: "Manchester",
    country_code: "JM",
  },
  {
    id: "1908",
    name: "Portland",
    country_code: "JM",
  },
  {
    id: "1909",
    name: "Saint Andrews",
    country_code: "JM",
  },
  {
    id: "1910",
    name: "Saint Ann",
    country_code: "JM",
  },
  {
    id: "1911",
    name: "Saint Catherine",
    country_code: "JM",
  },
  {
    id: "1912",
    name: "Saint Elizabeth",
    country_code: "JM",
  },
  {
    id: "1913",
    name: "Saint James",
    country_code: "JM",
  },
  {
    id: "1914",
    name: "Saint Mary",
    country_code: "JM",
  },
  {
    id: "1915",
    name: "Saint Thomas",
    country_code: "JM",
  },
  {
    id: "1916",
    name: "Trelawney",
    country_code: "JM",
  },
  {
    id: "1917",
    name: "Westmoreland",
    country_code: "JM",
  },
  {
    id: "1918",
    name: "Aichi",
    country_code: "JP",
  },
  {
    id: "1919",
    name: "Akita",
    country_code: "JP",
  },
  {
    id: "1920",
    name: "Aomori",
    country_code: "JP",
  },
  {
    id: "1921",
    name: "Chiba",
    country_code: "JP",
  },
  {
    id: "1922",
    name: "Ehime",
    country_code: "JP",
  },
  {
    id: "1923",
    name: "Fukui",
    country_code: "JP",
  },
  {
    id: "1924",
    name: "Fukuoka",
    country_code: "JP",
  },
  {
    id: "1925",
    name: "Fukushima",
    country_code: "JP",
  },
  {
    id: "1926",
    name: "Gifu",
    country_code: "JP",
  },
  {
    id: "1927",
    name: "Gumma",
    country_code: "JP",
  },
  {
    id: "1928",
    name: "Hiroshima",
    country_code: "JP",
  },
  {
    id: "1929",
    name: "Hokkaido",
    country_code: "JP",
  },
  {
    id: "1930",
    name: "Hyogo",
    country_code: "JP",
  },
  {
    id: "1931",
    name: "Ibaraki",
    country_code: "JP",
  },
  {
    id: "1932",
    name: "Ishikawa",
    country_code: "JP",
  },
  {
    id: "1933",
    name: "Iwate",
    country_code: "JP",
  },
  {
    id: "1934",
    name: "Kagawa",
    country_code: "JP",
  },
  {
    id: "1935",
    name: "Kagoshima",
    country_code: "JP",
  },
  {
    id: "1936",
    name: "Kanagawa",
    country_code: "JP",
  },
  {
    id: "1937",
    name: "Kanto",
    country_code: "JP",
  },
  {
    id: "1938",
    name: "Kochi",
    country_code: "JP",
  },
  {
    id: "1939",
    name: "Kumamoto",
    country_code: "JP",
  },
  {
    id: "1940",
    name: "Kyoto",
    country_code: "JP",
  },
  {
    id: "1941",
    name: "Mie",
    country_code: "JP",
  },
  {
    id: "1942",
    name: "Miyagi",
    country_code: "JP",
  },
  {
    id: "1943",
    name: "Miyazaki",
    country_code: "JP",
  },
  {
    id: "1944",
    name: "Nagano",
    country_code: "JP",
  },
  {
    id: "1945",
    name: "Nagasaki",
    country_code: "JP",
  },
  {
    id: "1946",
    name: "Nara",
    country_code: "JP",
  },
  {
    id: "1947",
    name: "Niigata",
    country_code: "JP",
  },
  {
    id: "1948",
    name: "Oita",
    country_code: "JP",
  },
  {
    id: "1949",
    name: "Okayama",
    country_code: "JP",
  },
  {
    id: "1950",
    name: "Okinawa",
    country_code: "JP",
  },
  {
    id: "1951",
    name: "Osaka",
    country_code: "JP",
  },
  {
    id: "1952",
    name: "Saga",
    country_code: "JP",
  },
  {
    id: "1953",
    name: "Saitama",
    country_code: "JP",
  },
  {
    id: "1954",
    name: "Shiga",
    country_code: "JP",
  },
  {
    id: "1955",
    name: "Shimane",
    country_code: "JP",
  },
  {
    id: "1956",
    name: "Shizuoka",
    country_code: "JP",
  },
  {
    id: "1957",
    name: "Tochigi",
    country_code: "JP",
  },
  {
    id: "1958",
    name: "Tokushima",
    country_code: "JP",
  },
  {
    id: "1959",
    name: "Tokyo",
    country_code: "JP",
  },
  {
    id: "1960",
    name: "Tottori",
    country_code: "JP",
  },
  {
    id: "1961",
    name: "Toyama",
    country_code: "JP",
  },
  {
    id: "1962",
    name: "Wakayama",
    country_code: "JP",
  },
  {
    id: "1963",
    name: "Yamagata",
    country_code: "JP",
  },
  {
    id: "1964",
    name: "Yamaguchi",
    country_code: "JP",
  },
  {
    id: "1965",
    name: "Yamanashi",
    country_code: "JP",
  },
  {
    id: "1977",
    name: "Ajlun",
    country_code: "JO",
  },
  {
    id: "1978",
    name: "Amman",
    country_code: "JO",
  },
  {
    id: "1979",
    name: "Irbid",
    country_code: "JO",
  },
  {
    id: "1980",
    name: "Jarash",
    country_code: "JO",
  },
  {
    id: "1981",
    name: "Ma''an",
    country_code: "JO",
  },
  {
    id: "1982",
    name: "Madaba",
    country_code: "JO",
  },
  {
    id: "1983",
    name: "al-''Aqabah",
    country_code: "JO",
  },
  {
    id: "1984",
    name: "al-Balqa",
    country_code: "JO",
  },
  {
    id: "1985",
    name: "al-Karak",
    country_code: "JO",
  },
  {
    id: "1986",
    name: "al-Mafraq",
    country_code: "JO",
  },
  {
    id: "1987",
    name: "at-Tafilah",
    country_code: "JO",
  },
  {
    id: "1988",
    name: "az-Zarqa",
    country_code: "JO",
  },
  {
    id: "1989",
    name: "Akmecet",
    country_code: "KZ",
  },
  {
    id: "1990",
    name: "Akmola",
    country_code: "KZ",
  },
  {
    id: "1991",
    name: "Aktobe",
    country_code: "KZ",
  },
  {
    id: "1992",
    name: "Almati",
    country_code: "KZ",
  },
  {
    id: "1993",
    name: "Atirau",
    country_code: "KZ",
  },
  {
    id: "1994",
    name: "Batis Kazakstan",
    country_code: "KZ",
  },
  {
    id: "1995",
    name: "Burlinsky Region",
    country_code: "KZ",
  },
  {
    id: "1996",
    name: "Karagandi",
    country_code: "KZ",
  },
  {
    id: "1997",
    name: "Kostanay",
    country_code: "KZ",
  },
  {
    id: "1998",
    name: "Mankistau",
    country_code: "KZ",
  },
  {
    id: "1999",
    name: "Ontustik Kazakstan",
    country_code: "KZ",
  },
  {
    id: "2000",
    name: "Pavlodar",
    country_code: "KZ",
  },
  {
    id: "2001",
    name: "Sigis Kazakstan",
    country_code: "KZ",
  },
  {
    id: "2002",
    name: "Soltustik Kazakstan",
    country_code: "KZ",
  },
  {
    id: "2003",
    name: "Taraz",
    country_code: "KZ",
  },
  {
    id: "2004",
    name: "Central",
    country_code: "KE",
  },
  {
    id: "2005",
    name: "Coast",
    country_code: "KE",
  },
  {
    id: "2006",
    name: "Eastern",
    country_code: "KE",
  },
  {
    id: "2007",
    name: "Nairobi",
    country_code: "KE",
  },
  {
    id: "2008",
    name: "North Eastern",
    country_code: "KE",
  },
  {
    id: "2009",
    name: "Nyanza",
    country_code: "KE",
  },
  {
    id: "2010",
    name: "Rift Valley",
    country_code: "KE",
  },
  {
    id: "2011",
    name: "Western",
    country_code: "KE",
  },
  {
    id: "2012",
    name: "Abaiang",
    country_code: "KI",
  },
  {
    id: "2013",
    name: "Abemana",
    country_code: "KI",
  },
  {
    id: "2014",
    name: "Aranuka",
    country_code: "KI",
  },
  {
    id: "2015",
    name: "Arorae",
    country_code: "KI",
  },
  {
    id: "2016",
    name: "Banaba",
    country_code: "KI",
  },
  {
    id: "2017",
    name: "Beru",
    country_code: "KI",
  },
  {
    id: "2018",
    name: "Butaritari",
    country_code: "KI",
  },
  {
    id: "2019",
    name: "Kiritimati",
    country_code: "KI",
  },
  {
    id: "2020",
    name: "Kuria",
    country_code: "KI",
  },
  {
    id: "2021",
    name: "Maiana",
    country_code: "KI",
  },
  {
    id: "2022",
    name: "Makin",
    country_code: "KI",
  },
  {
    id: "2023",
    name: "Marakei",
    country_code: "KI",
  },
  {
    id: "2024",
    name: "Nikunau",
    country_code: "KI",
  },
  {
    id: "2025",
    name: "Nonouti",
    country_code: "KI",
  },
  {
    id: "2026",
    name: "Onotoa",
    country_code: "KI",
  },
  {
    id: "2027",
    name: "Phoenix Islands",
    country_code: "KI",
  },
  {
    id: "2028",
    name: "Tabiteuea North",
    country_code: "KI",
  },
  {
    id: "2029",
    name: "Tabiteuea South",
    country_code: "KI",
  },
  {
    id: "2030",
    name: "Tabuaeran",
    country_code: "KI",
  },
  {
    id: "2031",
    name: "Tamana",
    country_code: "KI",
  },
  {
    id: "2032",
    name: "Tarawa North",
    country_code: "KI",
  },
  {
    id: "2033",
    name: "Tarawa South",
    country_code: "KI",
  },
  {
    id: "2034",
    name: "Teraina",
    country_code: "KI",
  },
  {
    id: "2082",
    name: "Al Asimah",
    country_code: "KW",
  },
  {
    id: "2083",
    name: "Hawalli",
    country_code: "KW",
  },
  {
    id: "2084",
    name: "Mishref",
    country_code: "KW",
  },
  {
    id: "2085",
    name: "Qadesiya",
    country_code: "KW",
  },
  {
    id: "2086",
    name: "Safat",
    country_code: "KW",
  },
  {
    id: "2087",
    name: "Salmiya",
    country_code: "KW",
  },
  {
    id: "2088",
    name: "al-Ahmadi",
    country_code: "KW",
  },
  {
    id: "2089",
    name: "al-Farwaniyah",
    country_code: "KW",
  },
  {
    id: "2090",
    name: "al-Jahra",
    country_code: "KW",
  },
  {
    id: "2091",
    name: "al-Kuwayt",
    country_code: "KW",
  },
  {
    id: "2092",
    name: "Batken",
    country_code: "KG",
  },
  {
    id: "2093",
    name: "Bishkek",
    country_code: "KG",
  },
  {
    id: "2094",
    name: "Chui",
    country_code: "KG",
  },
  {
    id: "2095",
    name: "Issyk-Kul",
    country_code: "KG",
  },
  {
    id: "2096",
    name: "Jalal-Abad",
    country_code: "KG",
  },
  {
    id: "2097",
    name: "Naryn",
    country_code: "KG",
  },
  {
    id: "2098",
    name: "Osh",
    country_code: "KG",
  },
  {
    id: "2099",
    name: "Talas",
    country_code: "KG",
  },
  {
    id: "2100",
    name: "Attopu",
    country_code: "LA",
  },
  {
    id: "2101",
    name: "Bokeo",
    country_code: "LA",
  },
  {
    id: "2102",
    name: "Bolikhamsay",
    country_code: "LA",
  },
  {
    id: "2103",
    name: "Champasak",
    country_code: "LA",
  },
  {
    id: "2104",
    name: "Houaphanh",
    country_code: "LA",
  },
  {
    id: "2105",
    name: "Khammouane",
    country_code: "LA",
  },
  {
    id: "2106",
    name: "Luang Nam Tha",
    country_code: "LA",
  },
  {
    id: "2107",
    name: "Luang Prabang",
    country_code: "LA",
  },
  {
    id: "2108",
    name: "Oudomxay",
    country_code: "LA",
  },
  {
    id: "2109",
    name: "Phongsaly",
    country_code: "LA",
  },
  {
    id: "2110",
    name: "Saravan",
    country_code: "LA",
  },
  {
    id: "2111",
    name: "Savannakhet",
    country_code: "LA",
  },
  {
    id: "2112",
    name: "Sekong",
    country_code: "LA",
  },
  {
    id: "2113",
    name: "Viangchan Prefecture",
    country_code: "LA",
  },
  {
    id: "2114",
    name: "Viangchan Province",
    country_code: "LA",
  },
  {
    id: "2115",
    name: "Xaignabury",
    country_code: "LA",
  },
  {
    id: "2116",
    name: "Xiang Khuang",
    country_code: "LA",
  },
  {
    id: "2117",
    name: "Aizkraukles",
    country_code: "LV",
  },
  {
    id: "2118",
    name: "Aluksnes",
    country_code: "LV",
  },
  {
    id: "2119",
    name: "Balvu",
    country_code: "LV",
  },
  {
    id: "2120",
    name: "Bauskas",
    country_code: "LV",
  },
  {
    id: "2121",
    name: "Cesu",
    country_code: "LV",
  },
  {
    id: "2122",
    name: "Daugavpils",
    country_code: "LV",
  },
  {
    id: "2123",
    name: "Daugavpils City",
    country_code: "LV",
  },
  {
    id: "2124",
    name: "Dobeles",
    country_code: "LV",
  },
  {
    id: "2125",
    name: "Gulbenes",
    country_code: "LV",
  },
  {
    id: "2126",
    name: "Jekabspils",
    country_code: "LV",
  },
  {
    id: "2127",
    name: "Jelgava",
    country_code: "LV",
  },
  {
    id: "2128",
    name: "Jelgavas",
    country_code: "LV",
  },
  {
    id: "2129",
    name: "Jurmala City",
    country_code: "LV",
  },
  {
    id: "2130",
    name: "Kraslavas",
    country_code: "LV",
  },
  {
    id: "2131",
    name: "Kuldigas",
    country_code: "LV",
  },
  {
    id: "2132",
    name: "Liepaja",
    country_code: "LV",
  },
  {
    id: "2133",
    name: "Liepajas",
    country_code: "LV",
  },
  {
    id: "2134",
    name: "Limbazhu",
    country_code: "LV",
  },
  {
    id: "2135",
    name: "Ludzas",
    country_code: "LV",
  },
  {
    id: "2136",
    name: "Madonas",
    country_code: "LV",
  },
  {
    id: "2137",
    name: "Ogres",
    country_code: "LV",
  },
  {
    id: "2138",
    name: "Preilu",
    country_code: "LV",
  },
  {
    id: "2139",
    name: "Rezekne",
    country_code: "LV",
  },
  {
    id: "2140",
    name: "Rezeknes",
    country_code: "LV",
  },
  {
    id: "2141",
    name: "Riga",
    country_code: "LV",
  },
  {
    id: "2142",
    name: "Rigas",
    country_code: "LV",
  },
  {
    id: "2143",
    name: "Saldus",
    country_code: "LV",
  },
  {
    id: "2144",
    name: "Talsu",
    country_code: "LV",
  },
  {
    id: "2145",
    name: "Tukuma",
    country_code: "LV",
  },
  {
    id: "2146",
    name: "Valkas",
    country_code: "LV",
  },
  {
    id: "2147",
    name: "Valmieras",
    country_code: "LV",
  },
  {
    id: "2148",
    name: "Ventspils",
    country_code: "LV",
  },
  {
    id: "2149",
    name: "Ventspils City",
    country_code: "LV",
  },
  {
    id: "2150",
    name: "Beirut",
    country_code: "LB",
  },
  {
    id: "2151",
    name: "Jabal Lubnan",
    country_code: "LB",
  },
  {
    id: "2152",
    name: "Mohafazat Liban-Nord",
    country_code: "LB",
  },
  {
    id: "2153",
    name: "Mohafazat Mont-Liban",
    country_code: "LB",
  },
  {
    id: "2154",
    name: "Sidon",
    country_code: "LB",
  },
  {
    id: "2155",
    name: "al-Biqa",
    country_code: "LB",
  },
  {
    id: "2156",
    name: "al-Janub",
    country_code: "LB",
  },
  {
    id: "2157",
    name: "an-Nabatiyah",
    country_code: "LB",
  },
  {
    id: "2158",
    name: "ash-Shamal",
    country_code: "LB",
  },
  {
    id: "2159",
    name: "Berea",
    country_code: "LS",
  },
  {
    id: "2160",
    name: "Butha-Buthe",
    country_code: "LS",
  },
  {
    id: "2161",
    name: "Leribe",
    country_code: "LS",
  },
  {
    id: "2162",
    name: "Mafeteng",
    country_code: "LS",
  },
  {
    id: "2163",
    name: "Maseru",
    country_code: "LS",
  },
  {
    id: "2164",
    name: "Mohale''s Hoek",
    country_code: "LS",
  },
  {
    id: "2165",
    name: "Mokhotlong",
    country_code: "LS",
  },
  {
    id: "2166",
    name: "Qacha''s Nek",
    country_code: "LS",
  },
  {
    id: "2167",
    name: "Quthing",
    country_code: "LS",
  },
  {
    id: "2168",
    name: "Thaba-Tseka",
    country_code: "LS",
  },
  {
    id: "2169",
    name: "Bomi",
    country_code: "LR",
  },
  {
    id: "2170",
    name: "Bong",
    country_code: "LR",
  },
  {
    id: "2171",
    name: "Grand Bassa",
    country_code: "LR",
  },
  {
    id: "2172",
    name: "Grand Cape Mount",
    country_code: "LR",
  },
  {
    id: "2173",
    name: "Grand Gedeh",
    country_code: "LR",
  },
  {
    id: "2174",
    name: "Loffa",
    country_code: "LR",
  },
  {
    id: "2175",
    name: "Margibi",
    country_code: "LR",
  },
  {
    id: "2176",
    name: "Maryland and Grand Kru",
    country_code: "LR",
  },
  {
    id: "2177",
    name: "Montserrado",
    country_code: "LR",
  },
  {
    id: "2178",
    name: "Nimba",
    country_code: "LR",
  },
  {
    id: "2179",
    name: "Rivercess",
    country_code: "LR",
  },
  {
    id: "2180",
    name: "Sinoe",
    country_code: "LR",
  },
  {
    id: "2181",
    name: "Ajdabiya",
    country_code: "LY",
  },
  {
    id: "2182",
    name: "Fezzan",
    country_code: "LY",
  },
  {
    id: "2183",
    name: "Banghazi",
    country_code: "LY",
  },
  {
    id: "2184",
    name: "Darnah",
    country_code: "LY",
  },
  {
    id: "2185",
    name: "Ghadamis",
    country_code: "LY",
  },
  {
    id: "2186",
    name: "Gharyan",
    country_code: "LY",
  },
  {
    id: "2187",
    name: "Misratah",
    country_code: "LY",
  },
  {
    id: "2188",
    name: "Murzuq",
    country_code: "LY",
  },
  {
    id: "2189",
    name: "Sabha",
    country_code: "LY",
  },
  {
    id: "2190",
    name: "Sawfajjin",
    country_code: "LY",
  },
  {
    id: "2191",
    name: "Surt",
    country_code: "LY",
  },
  {
    id: "2192",
    name: "Tarabulus",
    country_code: "LY",
  },
  {
    id: "2193",
    name: "Tarhunah",
    country_code: "LY",
  },
  {
    id: "2194",
    name: "Tripolitania",
    country_code: "LY",
  },
  {
    id: "2195",
    name: "Tubruq",
    country_code: "LY",
  },
  {
    id: "2196",
    name: "Yafran",
    country_code: "LY",
  },
  {
    id: "2197",
    name: "Zlitan",
    country_code: "LY",
  },
  {
    id: "2198",
    name: "al-''Aziziyah",
    country_code: "LY",
  },
  {
    id: "2199",
    name: "al-Fatih",
    country_code: "LY",
  },
  {
    id: "2200",
    name: "al-Jabal al Akhdar",
    country_code: "LY",
  },
  {
    id: "2201",
    name: "al-Jufrah",
    country_code: "LY",
  },
  {
    id: "2202",
    name: "al-Khums",
    country_code: "LY",
  },
  {
    id: "2203",
    name: "al-Kufrah",
    country_code: "LY",
  },
  {
    id: "2204",
    name: "an-Nuqat al-Khams",
    country_code: "LY",
  },
  {
    id: "2205",
    name: "ash-Shati",
    country_code: "LY",
  },
  {
    id: "2206",
    name: "az-Zawiyah",
    country_code: "LY",
  },
  {
    id: "2207",
    name: "Balzers",
    country_code: "LI",
  },
  {
    id: "2208",
    name: "Eschen",
    country_code: "LI",
  },
  {
    id: "2209",
    name: "Gamprin",
    country_code: "LI",
  },
  {
    id: "2210",
    name: "Mauren",
    country_code: "LI",
  },
  {
    id: "2211",
    name: "Planken",
    country_code: "LI",
  },
  {
    id: "2212",
    name: "Ruggell",
    country_code: "LI",
  },
  {
    id: "2213",
    name: "Schaan",
    country_code: "LI",
  },
  {
    id: "2214",
    name: "Schellenberg",
    country_code: "LI",
  },
  {
    id: "2215",
    name: "Triesen",
    country_code: "LI",
  },
  {
    id: "2216",
    name: "Triesenberg",
    country_code: "LI",
  },
  {
    id: "2217",
    name: "Vaduz",
    country_code: "LI",
  },
  {
    id: "2218",
    name: "Alytaus",
    country_code: "LT",
  },
  {
    id: "2219",
    name: "Anyksciai",
    country_code: "LT",
  },
  {
    id: "2220",
    name: "Kauno",
    country_code: "LT",
  },
  {
    id: "2221",
    name: "Klaipedos",
    country_code: "LT",
  },
  {
    id: "2222",
    name: "Marijampoles",
    country_code: "LT",
  },
  {
    id: "2223",
    name: "Panevezhio",
    country_code: "LT",
  },
  {
    id: "2224",
    name: "Panevezys",
    country_code: "LT",
  },
  {
    id: "2225",
    name: "Shiauliu",
    country_code: "LT",
  },
  {
    id: "2226",
    name: "Taurages",
    country_code: "LT",
  },
  {
    id: "2227",
    name: "Telshiu",
    country_code: "LT",
  },
  {
    id: "2228",
    name: "Telsiai",
    country_code: "LT",
  },
  {
    id: "2229",
    name: "Utenos",
    country_code: "LT",
  },
  {
    id: "2230",
    name: "Vilniaus",
    country_code: "LT",
  },
  {
    id: "2231",
    name: "Capellen",
    country_code: "LU",
  },
  {
    id: "2232",
    name: "Clervaux",
    country_code: "LU",
  },
  {
    id: "2233",
    name: "Diekirch",
    country_code: "LU",
  },
  {
    id: "2234",
    name: "Echternach",
    country_code: "LU",
  },
  {
    id: "2235",
    name: "Esch-sur-Alzette",
    country_code: "LU",
  },
  {
    id: "2236",
    name: "Grevenmacher",
    country_code: "LU",
  },
  {
    id: "2237",
    name: "Luxembourg",
    country_code: "LU",
  },
  {
    id: "2238",
    name: "Mersch",
    country_code: "LU",
  },
  {
    id: "2239",
    name: "Redange",
    country_code: "LU",
  },
  {
    id: "2240",
    name: "Remich",
    country_code: "LU",
  },
  {
    id: "2241",
    name: "Vianden",
    country_code: "LU",
  },
  {
    id: "2242",
    name: "Wiltz",
    country_code: "LU",
  },
  {
    id: "2274",
    name: "Antananarivo",
    country_code: "MG",
  },
  {
    id: "2275",
    name: "Antsiranana",
    country_code: "MG",
  },
  {
    id: "2276",
    name: "Fianarantsoa",
    country_code: "MG",
  },
  {
    id: "2277",
    name: "Mahajanga",
    country_code: "MG",
  },
  {
    id: "2278",
    name: "Toamasina",
    country_code: "MG",
  },
  {
    id: "2279",
    name: "Toliary",
    country_code: "MG",
  },
  {
    id: "2280",
    name: "Balaka",
    country_code: "MW",
  },
  {
    id: "2281",
    name: "Blantyre City",
    country_code: "MW",
  },
  {
    id: "2282",
    name: "Chikwawa",
    country_code: "MW",
  },
  {
    id: "2283",
    name: "Chiradzulu",
    country_code: "MW",
  },
  {
    id: "2284",
    name: "Chitipa",
    country_code: "MW",
  },
  {
    id: "2285",
    name: "Dedza",
    country_code: "MW",
  },
  {
    id: "2286",
    name: "Dowa",
    country_code: "MW",
  },
  {
    id: "2287",
    name: "Karonga",
    country_code: "MW",
  },
  {
    id: "2288",
    name: "Kasungu",
    country_code: "MW",
  },
  {
    id: "2289",
    name: "Lilongwe City",
    country_code: "MW",
  },
  {
    id: "2290",
    name: "Machinga",
    country_code: "MW",
  },
  {
    id: "2291",
    name: "Mangochi",
    country_code: "MW",
  },
  {
    id: "2292",
    name: "Mchinji",
    country_code: "MW",
  },
  {
    id: "2293",
    name: "Mulanje",
    country_code: "MW",
  },
  {
    id: "2294",
    name: "Mwanza",
    country_code: "MW",
  },
  {
    id: "2295",
    name: "Mzimba",
    country_code: "MW",
  },
  {
    id: "2296",
    name: "Mzuzu City",
    country_code: "MW",
  },
  {
    id: "2297",
    name: "Nkhata Bay",
    country_code: "MW",
  },
  {
    id: "2298",
    name: "Nkhotakota",
    country_code: "MW",
  },
  {
    id: "2299",
    name: "Nsanje",
    country_code: "MW",
  },
  {
    id: "2300",
    name: "Ntcheu",
    country_code: "MW",
  },
  {
    id: "2301",
    name: "Ntchisi",
    country_code: "MW",
  },
  {
    id: "2302",
    name: "Phalombe",
    country_code: "MW",
  },
  {
    id: "2303",
    name: "Rumphi",
    country_code: "MW",
  },
  {
    id: "2304",
    name: "Salima",
    country_code: "MW",
  },
  {
    id: "2305",
    name: "Thyolo",
    country_code: "MW",
  },
  {
    id: "2306",
    name: "Zomba Municipality",
    country_code: "MW",
  },
  {
    id: "2307",
    name: "Johor",
    country_code: "MY",
  },
  {
    id: "2308",
    name: "Kedah",
    country_code: "MY",
  },
  {
    id: "2309",
    name: "Kelantan",
    country_code: "MY",
  },
  {
    id: "2310",
    name: "Kuala Lumpur",
    country_code: "MY",
  },
  {
    id: "2311",
    name: "Labuan",
    country_code: "MY",
  },
  {
    id: "2312",
    name: "Melaka",
    country_code: "MY",
  },
  {
    id: "2313",
    name: "Negeri Johor",
    country_code: "MY",
  },
  {
    id: "2314",
    name: "Negeri Sembilan",
    country_code: "MY",
  },
  {
    id: "2315",
    name: "Pahang",
    country_code: "MY",
  },
  {
    id: "2316",
    name: "Penang",
    country_code: "MY",
  },
  {
    id: "2317",
    name: "Perak",
    country_code: "MY",
  },
  {
    id: "2318",
    name: "Perlis",
    country_code: "MY",
  },
  {
    id: "2319",
    name: "Pulau Pinang",
    country_code: "MY",
  },
  {
    id: "2320",
    name: "Sabah",
    country_code: "MY",
  },
  {
    id: "2321",
    name: "Sarawak",
    country_code: "MY",
  },
  {
    id: "2322",
    name: "Selangor",
    country_code: "MY",
  },
  {
    id: "2323",
    name: "Sembilan",
    country_code: "MY",
  },
  {
    id: "2324",
    name: "Terengganu",
    country_code: "MY",
  },
  {
    id: "2325",
    name: "Alif Alif",
    country_code: "MV",
  },
  {
    id: "2326",
    name: "Alif Dhaal",
    country_code: "MV",
  },
  {
    id: "2327",
    name: "Baa",
    country_code: "MV",
  },
  {
    id: "2328",
    name: "Dhaal",
    country_code: "MV",
  },
  {
    id: "2329",
    name: "Faaf",
    country_code: "MV",
  },
  {
    id: "2330",
    name: "Gaaf Alif",
    country_code: "MV",
  },
  {
    id: "2331",
    name: "Gaaf Dhaal",
    country_code: "MV",
  },
  {
    id: "2332",
    name: "Ghaviyani",
    country_code: "MV",
  },
  {
    id: "2333",
    name: "Haa Alif",
    country_code: "MV",
  },
  {
    id: "2334",
    name: "Haa Dhaal",
    country_code: "MV",
  },
  {
    id: "2335",
    name: "Kaaf",
    country_code: "MV",
  },
  {
    id: "2336",
    name: "Laam",
    country_code: "MV",
  },
  {
    id: "2337",
    name: "Lhaviyani",
    country_code: "MV",
  },
  {
    id: "2338",
    name: "Male",
    country_code: "MV",
  },
  {
    id: "2339",
    name: "Miim",
    country_code: "MV",
  },
  {
    id: "2340",
    name: "Nuun",
    country_code: "MV",
  },
  {
    id: "2341",
    name: "Raa",
    country_code: "MV",
  },
  {
    id: "2342",
    name: "Shaviyani",
    country_code: "MV",
  },
  {
    id: "2343",
    name: "Siin",
    country_code: "MV",
  },
  {
    id: "2344",
    name: "Thaa",
    country_code: "MV",
  },
  {
    id: "2345",
    name: "Vaav",
    country_code: "MV",
  },
  {
    id: "2346",
    name: "Bamako",
    country_code: "ML",
  },
  {
    id: "2347",
    name: "Gao",
    country_code: "ML",
  },
  {
    id: "2348",
    name: "Kayes",
    country_code: "ML",
  },
  {
    id: "2349",
    name: "Kidal",
    country_code: "ML",
  },
  {
    id: "2350",
    name: "Koulikoro",
    country_code: "ML",
  },
  {
    id: "2351",
    name: "Mopti",
    country_code: "ML",
  },
  {
    id: "2352",
    name: "Segou",
    country_code: "ML",
  },
  {
    id: "2353",
    name: "Sikasso",
    country_code: "ML",
  },
  {
    id: "2354",
    name: "Tombouctou",
    country_code: "ML",
  },
  {
    id: "2355",
    name: "Gozo and Comino",
    country_code: "MT",
  },
  {
    id: "2356",
    name: "Inner Harbour",
    country_code: "MT",
  },
  {
    id: "2357",
    name: "Northern",
    country_code: "MT",
  },
  {
    id: "2358",
    name: "Outer Harbour",
    country_code: "MT",
  },
  {
    id: "2359",
    name: "South Eastern",
    country_code: "MT",
  },
  {
    id: "2360",
    name: "Valletta",
    country_code: "MT",
  },
  {
    id: "2361",
    name: "Western",
    country_code: "MT",
  },
  {
    id: "2370",
    name: "Ailinlaplap",
    country_code: "MH",
  },
  {
    id: "2371",
    name: "Ailuk",
    country_code: "MH",
  },
  {
    id: "2372",
    name: "Arno",
    country_code: "MH",
  },
  {
    id: "2373",
    name: "Aur",
    country_code: "MH",
  },
  {
    id: "2374",
    name: "Bikini",
    country_code: "MH",
  },
  {
    id: "2375",
    name: "Ebon",
    country_code: "MH",
  },
  {
    id: "2376",
    name: "Enewetak",
    country_code: "MH",
  },
  {
    id: "2377",
    name: "Jabat",
    country_code: "MH",
  },
  {
    id: "2378",
    name: "Jaluit",
    country_code: "MH",
  },
  {
    id: "2379",
    name: "Kili",
    country_code: "MH",
  },
  {
    id: "2380",
    name: "Kwajalein",
    country_code: "MH",
  },
  {
    id: "2381",
    name: "Lae",
    country_code: "MH",
  },
  {
    id: "2382",
    name: "Lib",
    country_code: "MH",
  },
  {
    id: "2383",
    name: "Likiep",
    country_code: "MH",
  },
  {
    id: "2384",
    name: "Majuro",
    country_code: "MH",
  },
  {
    id: "2385",
    name: "Maloelap",
    country_code: "MH",
  },
  {
    id: "2386",
    name: "Mejit",
    country_code: "MH",
  },
  {
    id: "2387",
    name: "Mili",
    country_code: "MH",
  },
  {
    id: "2388",
    name: "Namorik",
    country_code: "MH",
  },
  {
    id: "2389",
    name: "Namu",
    country_code: "MH",
  },
  {
    id: "2390",
    name: "Rongelap",
    country_code: "MH",
  },
  {
    id: "2391",
    name: "Ujae",
    country_code: "MH",
  },
  {
    id: "2392",
    name: "Utrik",
    country_code: "MH",
  },
  {
    id: "2393",
    name: "Wotho",
    country_code: "MH",
  },
  {
    id: "2394",
    name: "Wotje",
    country_code: "MH",
  },
  {
    id: "2399",
    name: "Adrar",
    country_code: "MR",
  },
  {
    id: "2400",
    name: "Assaba",
    country_code: "MR",
  },
  {
    id: "2401",
    name: "Brakna",
    country_code: "MR",
  },
  {
    id: "2402",
    name: "Dhakhlat Nawadibu",
    country_code: "MR",
  },
  {
    id: "2403",
    name: "Hudh-al-Gharbi",
    country_code: "MR",
  },
  {
    id: "2404",
    name: "Hudh-ash-Sharqi",
    country_code: "MR",
  },
  {
    id: "2405",
    name: "Inshiri",
    country_code: "MR",
  },
  {
    id: "2406",
    name: "Nawakshut",
    country_code: "MR",
  },
  {
    id: "2407",
    name: "Qidimagha",
    country_code: "MR",
  },
  {
    id: "2408",
    name: "Qurqul",
    country_code: "MR",
  },
  {
    id: "2409",
    name: "Taqant",
    country_code: "MR",
  },
  {
    id: "2410",
    name: "Tiris Zammur",
    country_code: "MR",
  },
  {
    id: "2411",
    name: "Trarza",
    country_code: "MR",
  },
  {
    id: "2412",
    name: "Black River",
    country_code: "MU",
  },
  {
    id: "2413",
    name: "Eau Coulee",
    country_code: "MU",
  },
  {
    id: "2414",
    name: "Flacq",
    country_code: "MU",
  },
  {
    id: "2415",
    name: "Floreal",
    country_code: "MU",
  },
  {
    id: "2416",
    name: "Grand Port",
    country_code: "MU",
  },
  {
    id: "2417",
    name: "Moka",
    country_code: "MU",
  },
  {
    id: "2418",
    name: "Pamplempousses",
    country_code: "MU",
  },
  {
    id: "2419",
    name: "Plaines Wilhelm",
    country_code: "MU",
  },
  {
    id: "2420",
    name: "Port Louis",
    country_code: "MU",
  },
  {
    id: "2421",
    name: "Riviere du Rempart",
    country_code: "MU",
  },
  {
    id: "2422",
    name: "Rodrigues",
    country_code: "MU",
  },
  {
    id: "2423",
    name: "Rose Hill",
    country_code: "MU",
  },
  {
    id: "2424",
    name: "Savanne",
    country_code: "MU",
  },
  {
    id: "2427",
    name: "Aguascalientes",
    country_code: "MX",
  },
  {
    id: "2428",
    name: "Baja California",
    country_code: "MX",
  },
  {
    id: "2429",
    name: "Baja California Sur",
    country_code: "MX",
  },
  {
    id: "2430",
    name: "Campeche",
    country_code: "MX",
  },
  {
    id: "2431",
    name: "Chiapas",
    country_code: "MX",
  },
  {
    id: "2432",
    name: "Chihuahua",
    country_code: "MX",
  },
  {
    id: "2433",
    name: "Coahuila",
    country_code: "MX",
  },
  {
    id: "2434",
    name: "Colima",
    country_code: "MX",
  },
  {
    id: "2435",
    name: "Distrito Federal",
    country_code: "MX",
  },
  {
    id: "2436",
    name: "Durango",
    country_code: "MX",
  },
  {
    id: "2437",
    name: "Estado de Mexico",
    country_code: "MX",
  },
  {
    id: "2438",
    name: "Guanajuato",
    country_code: "MX",
  },
  {
    id: "2439",
    name: "Guerrero",
    country_code: "MX",
  },
  {
    id: "2440",
    name: "Hidalgo",
    country_code: "MX",
  },
  {
    id: "2441",
    name: "Jalisco",
    country_code: "MX",
  },
  {
    id: "2442",
    name: "Mexico",
    country_code: "MX",
  },
  {
    id: "2443",
    name: "Michoacan",
    country_code: "MX",
  },
  {
    id: "2444",
    name: "Morelos",
    country_code: "MX",
  },
  {
    id: "2445",
    name: "Nayarit",
    country_code: "MX",
  },
  {
    id: "2446",
    name: "Nuevo Leon",
    country_code: "MX",
  },
  {
    id: "2447",
    name: "Oaxaca",
    country_code: "MX",
  },
  {
    id: "2448",
    name: "Puebla",
    country_code: "MX",
  },
  {
    id: "2449",
    name: "Queretaro",
    country_code: "MX",
  },
  {
    id: "2450",
    name: "Quintana Roo",
    country_code: "MX",
  },
  {
    id: "2451",
    name: "San Luis Potosi",
    country_code: "MX",
  },
  {
    id: "2452",
    name: "Sinaloa",
    country_code: "MX",
  },
  {
    id: "2453",
    name: "Sonora",
    country_code: "MX",
  },
  {
    id: "2454",
    name: "Tabasco",
    country_code: "MX",
  },
  {
    id: "2455",
    name: "Tamaulipas",
    country_code: "MX",
  },
  {
    id: "2456",
    name: "Tlaxcala",
    country_code: "MX",
  },
  {
    id: "2457",
    name: "Veracruz",
    country_code: "MX",
  },
  {
    id: "2458",
    name: "Yucatan",
    country_code: "MX",
  },
  {
    id: "2459",
    name: "Zacatecas",
    country_code: "MX",
  },
  {
    id: "2460",
    name: "Chuuk",
    country_code: "FM",
  },
  {
    id: "2461",
    name: "Kusaie",
    country_code: "FM",
  },
  {
    id: "2462",
    name: "Pohnpei",
    country_code: "FM",
  },
  {
    id: "2463",
    name: "Yap",
    country_code: "FM",
  },
  {
    id: "2464",
    name: "Balti",
    country_code: "MD",
  },
  {
    id: "2465",
    name: "Cahul",
    country_code: "MD",
  },
  {
    id: "2466",
    name: "Chisinau",
    country_code: "MD",
  },
  {
    id: "2467",
    name: "Chisinau Oras",
    country_code: "MD",
  },
  {
    id: "2468",
    name: "Edinet",
    country_code: "MD",
  },
  {
    id: "2469",
    name: "Gagauzia",
    country_code: "MD",
  },
  {
    id: "2470",
    name: "Lapusna",
    country_code: "MD",
  },
  {
    id: "2471",
    name: "Orhei",
    country_code: "MD",
  },
  {
    id: "2472",
    name: "Soroca",
    country_code: "MD",
  },
  {
    id: "2473",
    name: "Taraclia",
    country_code: "MD",
  },
  {
    id: "2474",
    name: "Tighina",
    country_code: "MD",
  },
  {
    id: "2475",
    name: "Transnistria",
    country_code: "MD",
  },
  {
    id: "2476",
    name: "Ungheni",
    country_code: "MD",
  },
  {
    id: "2477",
    name: "Fontvieille",
    country_code: "MC",
  },
  {
    id: "2478",
    name: "La Condamine",
    country_code: "MC",
  },
  {
    id: "2479",
    name: "Monaco-Ville",
    country_code: "MC",
  },
  {
    id: "2480",
    name: "Monte Carlo",
    country_code: "MC",
  },
  {
    id: "2481",
    name: "Arhangaj",
    country_code: "MN",
  },
  {
    id: "2482",
    name: "Bajan-Olgij",
    country_code: "MN",
  },
  {
    id: "2483",
    name: "Bajanhongor",
    country_code: "MN",
  },
  {
    id: "2484",
    name: "Bulgan",
    country_code: "MN",
  },
  {
    id: "2485",
    name: "Darhan-Uul",
    country_code: "MN",
  },
  {
    id: "2486",
    name: "Dornod",
    country_code: "MN",
  },
  {
    id: "2487",
    name: "Dornogovi",
    country_code: "MN",
  },
  {
    id: "2488",
    name: "Dundgovi",
    country_code: "MN",
  },
  {
    id: "2489",
    name: "Govi-Altaj",
    country_code: "MN",
  },
  {
    id: "2490",
    name: "Govisumber",
    country_code: "MN",
  },
  {
    id: "2491",
    name: "Hentij",
    country_code: "MN",
  },
  {
    id: "2492",
    name: "Hovd",
    country_code: "MN",
  },
  {
    id: "2493",
    name: "Hovsgol",
    country_code: "MN",
  },
  {
    id: "2494",
    name: "Omnogovi",
    country_code: "MN",
  },
  {
    id: "2495",
    name: "Orhon",
    country_code: "MN",
  },
  {
    id: "2496",
    name: "Ovorhangaj",
    country_code: "MN",
  },
  {
    id: "2497",
    name: "Selenge",
    country_code: "MN",
  },
  {
    id: "2498",
    name: "Suhbaatar",
    country_code: "MN",
  },
  {
    id: "2499",
    name: "Tov",
    country_code: "MN",
  },
  {
    id: "2500",
    name: "Ulaanbaatar",
    country_code: "MN",
  },
  {
    id: "2501",
    name: "Uvs",
    country_code: "MN",
  },
  {
    id: "2502",
    name: "Zavhan",
    country_code: "MN",
  },
  {
    id: "2504",
    name: "Agadir",
    country_code: "MA",
  },
  {
    id: "2505",
    name: "Casablanca",
    country_code: "MA",
  },
  {
    id: "2506",
    name: "Chaouia-Ouardigha",
    country_code: "MA",
  },
  {
    id: "2507",
    name: "Doukkala-Abda",
    country_code: "MA",
  },
  {
    id: "2508",
    name: "Fes-Boulemane",
    country_code: "MA",
  },
  {
    id: "2509",
    name: "Gharb-Chrarda-Beni Hssen",
    country_code: "MA",
  },
  {
    id: "2510",
    name: "Guelmim",
    country_code: "MA",
  },
  {
    id: "2511",
    name: "Kenitra",
    country_code: "MA",
  },
  {
    id: "2512",
    name: "Marrakech-Tensift-Al Haouz",
    country_code: "MA",
  },
  {
    id: "2513",
    name: "Meknes-Tafilalet",
    country_code: "MA",
  },
  {
    id: "2514",
    name: "Oriental",
    country_code: "MA",
  },
  {
    id: "2515",
    name: "Oujda",
    country_code: "MA",
  },
  {
    id: "2516",
    name: "Province de Tanger",
    country_code: "MA",
  },
  {
    id: "2517",
    name: "Rabat-Sale-Zammour-Zaer",
    country_code: "MA",
  },
  {
    id: "2518",
    name: "Sala Al Jadida",
    country_code: "MA",
  },
  {
    id: "2519",
    name: "Settat",
    country_code: "MA",
  },
  {
    id: "2520",
    name: "Souss Massa-Draa",
    country_code: "MA",
  },
  {
    id: "2521",
    name: "Tadla-Azilal",
    country_code: "MA",
  },
  {
    id: "2522",
    name: "Tangier-Tetouan",
    country_code: "MA",
  },
  {
    id: "2523",
    name: "Taza-Al Hoceima-Taounate",
    country_code: "MA",
  },
  {
    id: "2524",
    name: "Wilaya de Casablanca",
    country_code: "MA",
  },
  {
    id: "2525",
    name: "Wilaya de Rabat-Sale",
    country_code: "MA",
  },
  {
    id: "2526",
    name: "Cabo Delgado",
    country_code: "MZ",
  },
  {
    id: "2527",
    name: "Gaza",
    country_code: "MZ",
  },
  {
    id: "2528",
    name: "Inhambane",
    country_code: "MZ",
  },
  {
    id: "2529",
    name: "Manica",
    country_code: "MZ",
  },
  {
    id: "2530",
    name: "Maputo",
    country_code: "MZ",
  },
  {
    id: "2531",
    name: "Maputo Provincia",
    country_code: "MZ",
  },
  {
    id: "2532",
    name: "Nampula",
    country_code: "MZ",
  },
  {
    id: "2533",
    name: "Niassa",
    country_code: "MZ",
  },
  {
    id: "2534",
    name: "Sofala",
    country_code: "MZ",
  },
  {
    id: "2535",
    name: "Tete",
    country_code: "MZ",
  },
  {
    id: "2536",
    name: "Zambezia",
    country_code: "MZ",
  },
  {
    id: "2537",
    name: "Ayeyarwady",
    country_code: "MM",
  },
  {
    id: "2538",
    name: "Bago",
    country_code: "MM",
  },
  {
    id: "2539",
    name: "Chin",
    country_code: "MM",
  },
  {
    id: "2540",
    name: "Kachin",
    country_code: "MM",
  },
  {
    id: "2541",
    name: "Kayah",
    country_code: "MM",
  },
  {
    id: "2542",
    name: "Kayin",
    country_code: "MM",
  },
  {
    id: "2543",
    name: "Magway",
    country_code: "MM",
  },
  {
    id: "2544",
    name: "Mandalay",
    country_code: "MM",
  },
  {
    id: "2545",
    name: "Mon",
    country_code: "MM",
  },
  {
    id: "2546",
    name: "Nay Pyi Taw",
    country_code: "MM",
  },
  {
    id: "2547",
    name: "Rakhine",
    country_code: "MM",
  },
  {
    id: "2548",
    name: "Sagaing",
    country_code: "MM",
  },
  {
    id: "2549",
    name: "Shan",
    country_code: "MM",
  },
  {
    id: "2550",
    name: "Tanintharyi",
    country_code: "MM",
  },
  {
    id: "2551",
    name: "Yangon",
    country_code: "MM",
  },
  {
    id: "2552",
    name: "Caprivi",
    country_code: "NA",
  },
  {
    id: "2553",
    name: "Erongo",
    country_code: "NA",
  },
  {
    id: "2554",
    name: "Hardap",
    country_code: "NA",
  },
  {
    id: "2555",
    name: "Karas",
    country_code: "NA",
  },
  {
    id: "2556",
    name: "Kavango",
    country_code: "NA",
  },
  {
    id: "2557",
    name: "Khomas",
    country_code: "NA",
  },
  {
    id: "2558",
    name: "Kunene",
    country_code: "NA",
  },
  {
    id: "2559",
    name: "Ohangwena",
    country_code: "NA",
  },
  {
    id: "2560",
    name: "Omaheke",
    country_code: "NA",
  },
  {
    id: "2561",
    name: "Omusati",
    country_code: "NA",
  },
  {
    id: "2562",
    name: "Oshana",
    country_code: "NA",
  },
  {
    id: "2563",
    name: "Oshikoto",
    country_code: "NA",
  },
  {
    id: "2564",
    name: "Otjozondjupa",
    country_code: "NA",
  },
  {
    id: "2565",
    name: "Yaren",
    country_code: "NR",
  },
  {
    id: "2566",
    name: "Bagmati",
    country_code: "NP",
  },
  {
    id: "2567",
    name: "Bheri",
    country_code: "NP",
  },
  {
    id: "2568",
    name: "Dhawalagiri",
    country_code: "NP",
  },
  {
    id: "2569",
    name: "Gandaki",
    country_code: "NP",
  },
  {
    id: "2570",
    name: "Janakpur",
    country_code: "NP",
  },
  {
    id: "2571",
    name: "Karnali",
    country_code: "NP",
  },
  {
    id: "2572",
    name: "Koshi",
    country_code: "NP",
  },
  {
    id: "2573",
    name: "Lumbini",
    country_code: "NP",
  },
  {
    id: "2574",
    name: "Mahakali",
    country_code: "NP",
  },
  {
    id: "2575",
    name: "Mechi",
    country_code: "NP",
  },
  {
    id: "2576",
    name: "Narayani",
    country_code: "NP",
  },
  {
    id: "2577",
    name: "Rapti",
    country_code: "NP",
  },
  {
    id: "2578",
    name: "Sagarmatha",
    country_code: "NP",
  },
  {
    id: "2579",
    name: "Seti",
    country_code: "NP",
  },
  {
    id: "2585",
    name: "Amsterdam",
    country_code: "NL",
  },
  {
    id: "2586",
    name: "Benelux",
    country_code: "NL",
  },
  {
    id: "2587",
    name: "Drenthe",
    country_code: "NL",
  },
  {
    id: "2588",
    name: "Flevoland",
    country_code: "NL",
  },
  {
    id: "2589",
    name: "Friesland",
    country_code: "NL",
  },
  {
    id: "2590",
    name: "Gelderland",
    country_code: "NL",
  },
  {
    id: "2591",
    name: "Groningen",
    country_code: "NL",
  },
  {
    id: "2592",
    name: "Limburg",
    country_code: "NL",
  },
  {
    id: "2593",
    name: "Noord-Brabant",
    country_code: "NL",
  },
  {
    id: "2594",
    name: "Noord-Holland",
    country_code: "NL",
  },
  {
    id: "2595",
    name: "Overijssel",
    country_code: "NL",
  },
  {
    id: "2596",
    name: "South Holland",
    country_code: "NL",
  },
  {
    id: "2597",
    name: "Utrecht",
    country_code: "NL",
  },
  {
    id: "2598",
    name: "Zeeland",
    country_code: "NL",
  },
  {
    id: "2599",
    name: "Zuid-Holland",
    country_code: "NL",
  },
  {
    id: "2603",
    name: "Area Outside Region",
    country_code: "NZ",
  },
  {
    id: "2604",
    name: "Auckland",
    country_code: "NZ",
  },
  {
    id: "2605",
    name: "Bay of Plenty",
    country_code: "NZ",
  },
  {
    id: "2606",
    name: "Canterbury",
    country_code: "NZ",
  },
  {
    id: "2607",
    name: "Christchurch",
    country_code: "NZ",
  },
  {
    id: "2608",
    name: "Gisborne",
    country_code: "NZ",
  },
  {
    id: "2609",
    name: "Hawke''s Bay",
    country_code: "NZ",
  },
  {
    id: "2610",
    name: "Manawatu-Wanganui",
    country_code: "NZ",
  },
  {
    id: "2611",
    name: "Marlborough",
    country_code: "NZ",
  },
  {
    id: "2612",
    name: "Nelson",
    country_code: "NZ",
  },
  {
    id: "2613",
    name: "Northland",
    country_code: "NZ",
  },
  {
    id: "2614",
    name: "Otago",
    country_code: "NZ",
  },
  {
    id: "2615",
    name: "Rodney",
    country_code: "NZ",
  },
  {
    id: "2616",
    name: "Southland",
    country_code: "NZ",
  },
  {
    id: "2617",
    name: "Taranaki",
    country_code: "NZ",
  },
  {
    id: "2618",
    name: "Tasman",
    country_code: "NZ",
  },
  {
    id: "2619",
    name: "Waikato",
    country_code: "NZ",
  },
  {
    id: "2620",
    name: "Wellington",
    country_code: "NZ",
  },
  {
    id: "2621",
    name: "West Coast",
    country_code: "NZ",
  },
  {
    id: "2622",
    name: "Atlantico Norte",
    country_code: "NI",
  },
  {
    id: "2623",
    name: "Atlantico Sur",
    country_code: "NI",
  },
  {
    id: "2624",
    name: "Boaco",
    country_code: "NI",
  },
  {
    id: "2625",
    name: "Carazo",
    country_code: "NI",
  },
  {
    id: "2626",
    name: "Chinandega",
    country_code: "NI",
  },
  {
    id: "2627",
    name: "Chontales",
    country_code: "NI",
  },
  {
    id: "2628",
    name: "Esteli",
    country_code: "NI",
  },
  {
    id: "2629",
    name: "Granada",
    country_code: "NI",
  },
  {
    id: "2630",
    name: "Jinotega",
    country_code: "NI",
  },
  {
    id: "2631",
    name: "Leon",
    country_code: "NI",
  },
  {
    id: "2632",
    name: "Madriz",
    country_code: "NI",
  },
  {
    id: "2633",
    name: "Managua",
    country_code: "NI",
  },
  {
    id: "2634",
    name: "Masaya",
    country_code: "NI",
  },
  {
    id: "2635",
    name: "Matagalpa",
    country_code: "NI",
  },
  {
    id: "2636",
    name: "Nueva Segovia",
    country_code: "NI",
  },
  {
    id: "2637",
    name: "Rio San Juan",
    country_code: "NI",
  },
  {
    id: "2638",
    name: "Rivas",
    country_code: "NI",
  },
  {
    id: "2639",
    name: "Agadez",
    country_code: "NE",
  },
  {
    id: "2640",
    name: "Diffa",
    country_code: "NE",
  },
  {
    id: "2641",
    name: "Dosso",
    country_code: "NE",
  },
  {
    id: "2642",
    name: "Maradi",
    country_code: "NE",
  },
  {
    id: "2643",
    name: "Niamey",
    country_code: "NE",
  },
  {
    id: "2644",
    name: "Tahoua",
    country_code: "NE",
  },
  {
    id: "2645",
    name: "Tillabery",
    country_code: "NE",
  },
  {
    id: "2646",
    name: "Zinder",
    country_code: "NE",
  },
  {
    id: "2647",
    name: "Abia",
    country_code: "NG",
  },
  {
    id: "2648",
    name: "Abuja Federal Capital Territor",
    country_code: "NG",
  },
  {
    id: "2649",
    name: "Adamawa",
    country_code: "NG",
  },
  {
    id: "2650",
    name: "Akwa Ibom",
    country_code: "NG",
  },
  {
    id: "2651",
    name: "Anambra",
    country_code: "NG",
  },
  {
    id: "2652",
    name: "Bauchi",
    country_code: "NG",
  },
  {
    id: "2653",
    name: "Bayelsa",
    country_code: "NG",
  },
  {
    id: "2654",
    name: "Benue",
    country_code: "NG",
  },
  {
    id: "2655",
    name: "Borno",
    country_code: "NG",
  },
  {
    id: "2656",
    name: "Cross River",
    country_code: "NG",
  },
  {
    id: "2657",
    name: "Delta",
    country_code: "NG",
  },
  {
    id: "2658",
    name: "Ebonyi",
    country_code: "NG",
  },
  {
    id: "2659",
    name: "Edo",
    country_code: "NG",
  },
  {
    id: "2660",
    name: "Ekiti",
    country_code: "NG",
  },
  {
    id: "2661",
    name: "Enugu",
    country_code: "NG",
  },
  {
    id: "2662",
    name: "Gombe",
    country_code: "NG",
  },
  {
    id: "2663",
    name: "Imo",
    country_code: "NG",
  },
  {
    id: "2664",
    name: "Jigawa",
    country_code: "NG",
  },
  {
    id: "2665",
    name: "Kaduna",
    country_code: "NG",
  },
  {
    id: "2666",
    name: "Kano",
    country_code: "NG",
  },
  {
    id: "2667",
    name: "Katsina",
    country_code: "NG",
  },
  {
    id: "2668",
    name: "Kebbi",
    country_code: "NG",
  },
  {
    id: "2669",
    name: "Kogi",
    country_code: "NG",
  },
  {
    id: "2670",
    name: "Kwara",
    country_code: "NG",
  },
  {
    id: "2671",
    name: "Lagos",
    country_code: "NG",
  },
  {
    id: "2672",
    name: "Nassarawa",
    country_code: "NG",
  },
  {
    id: "2673",
    name: "Niger",
    country_code: "NG",
  },
  {
    id: "2674",
    name: "Ogun",
    country_code: "NG",
  },
  {
    id: "2675",
    name: "Ondo",
    country_code: "NG",
  },
  {
    id: "2676",
    name: "Osun",
    country_code: "NG",
  },
  {
    id: "2677",
    name: "Oyo",
    country_code: "NG",
  },
  {
    id: "2678",
    name: "Plateau",
    country_code: "NG",
  },
  {
    id: "2679",
    name: "Rivers",
    country_code: "NG",
  },
  {
    id: "2680",
    name: "Sokoto",
    country_code: "NG",
  },
  {
    id: "2681",
    name: "Taraba",
    country_code: "NG",
  },
  {
    id: "2682",
    name: "Yobe",
    country_code: "NG",
  },
  {
    id: "2683",
    name: "Zamfara",
    country_code: "NG",
  },
  {
    id: "2684",
    name: "Niue",
    country_code: "KP",
  },
  {
    id: "2685",
    name: "Norfolk Island",
    country_code: "MK",
  },
  {
    id: "2690",
    name: "Akershus",
    country_code: "NO",
  },
  {
    id: "2691",
    name: "Aust Agder",
    country_code: "NO",
  },
  {
    id: "2692",
    name: "Bergen",
    country_code: "NO",
  },
  {
    id: "2693",
    name: "Buskerud",
    country_code: "NO",
  },
  {
    id: "2694",
    name: "Finnmark",
    country_code: "NO",
  },
  {
    id: "2695",
    name: "Hedmark",
    country_code: "NO",
  },
  {
    id: "2696",
    name: "Hordaland",
    country_code: "NO",
  },
  {
    id: "2697",
    name: "Moere og Romsdal",
    country_code: "NO",
  },
  {
    id: "2698",
    name: "Nord Trondelag",
    country_code: "NO",
  },
  {
    id: "2699",
    name: "Nordland",
    country_code: "NO",
  },
  {
    id: "2700",
    name: "Oestfold",
    country_code: "NO",
  },
  {
    id: "2701",
    name: "Oppland",
    country_code: "NO",
  },
  {
    id: "2702",
    name: "Oslo",
    country_code: "NO",
  },
  {
    id: "2703",
    name: "Rogaland",
    country_code: "NO",
  },
  {
    id: "2704",
    name: "Soer Troendelag",
    country_code: "NO",
  },
  {
    id: "2705",
    name: "Sogn og Fjordane",
    country_code: "NO",
  },
  {
    id: "2706",
    name: "Stavern",
    country_code: "NO",
  },
  {
    id: "2707",
    name: "Sykkylven",
    country_code: "NO",
  },
  {
    id: "2708",
    name: "Telemark",
    country_code: "NO",
  },
  {
    id: "2709",
    name: "Troms",
    country_code: "NO",
  },
  {
    id: "2710",
    name: "Vest Agder",
    country_code: "NO",
  },
  {
    id: "2711",
    name: "Vestfold",
    country_code: "NO",
  },
  {
    id: "2712",
    name: "ÃƒÂ˜stfold",
    country_code: "NO",
  },
  {
    id: "2713",
    name: "Al Buraimi",
    country_code: "OM",
  },
  {
    id: "2714",
    name: "Dhufar",
    country_code: "OM",
  },
  {
    id: "2715",
    name: "Masqat",
    country_code: "OM",
  },
  {
    id: "2716",
    name: "Musandam",
    country_code: "OM",
  },
  {
    id: "2717",
    name: "Rusayl",
    country_code: "OM",
  },
  {
    id: "2718",
    name: "Wadi Kabir",
    country_code: "OM",
  },
  {
    id: "2719",
    name: "ad-Dakhiliyah",
    country_code: "OM",
  },
  {
    id: "2720",
    name: "adh-Dhahirah",
    country_code: "OM",
  },
  {
    id: "2721",
    name: "al-Batinah",
    country_code: "OM",
  },
  {
    id: "2722",
    name: "ash-Sharqiyah",
    country_code: "OM",
  },
  {
    id: "2723",
    name: "Baluchistan",
    country_code: "PK",
  },
  {
    id: "2724",
    name: "Federal Capital Area",
    country_code: "PK",
  },
  {
    id: "2725",
    name: "Federally administered Tribal",
    country_code: "PK",
  },
  {
    id: "2726",
    name: "North-West Frontier",
    country_code: "PK",
  },
  {
    id: "2727",
    name: "Northern Areas",
    country_code: "PK",
  },
  {
    id: "2728",
    name: "Punjab",
    country_code: "PK",
  },
  {
    id: "2729",
    name: "Sind",
    country_code: "PK",
  },
  {
    id: "2730",
    name: "Aimeliik",
    country_code: "PW",
  },
  {
    id: "2731",
    name: "Airai",
    country_code: "PW",
  },
  {
    id: "2732",
    name: "Angaur",
    country_code: "PW",
  },
  {
    id: "2733",
    name: "Hatobohei",
    country_code: "PW",
  },
  {
    id: "2734",
    name: "Kayangel",
    country_code: "PW",
  },
  {
    id: "2735",
    name: "Koror",
    country_code: "PW",
  },
  {
    id: "2736",
    name: "Melekeok",
    country_code: "PW",
  },
  {
    id: "2737",
    name: "Ngaraard",
    country_code: "PW",
  },
  {
    id: "2738",
    name: "Ngardmau",
    country_code: "PW",
  },
  {
    id: "2739",
    name: "Ngaremlengui",
    country_code: "PW",
  },
  {
    id: "2740",
    name: "Ngatpang",
    country_code: "PW",
  },
  {
    id: "2741",
    name: "Ngchesar",
    country_code: "PW",
  },
  {
    id: "2742",
    name: "Ngerchelong",
    country_code: "PW",
  },
  {
    id: "2743",
    name: "Ngiwal",
    country_code: "PW",
  },
  {
    id: "2744",
    name: "Peleliu",
    country_code: "PW",
  },
  {
    id: "2745",
    name: "Sonsorol",
    country_code: "PW",
  },
  {
    id: "2763",
    name: "Bocas del Toro",
    country_code: "PA",
  },
  {
    id: "2764",
    name: "Chiriqui",
    country_code: "PA",
  },
  {
    id: "2765",
    name: "Cocle",
    country_code: "PA",
  },
  {
    id: "2766",
    name: "Colon",
    country_code: "PA",
  },
  {
    id: "2767",
    name: "Darien",
    country_code: "PA",
  },
  {
    id: "2768",
    name: "Embera",
    country_code: "PA",
  },
  {
    id: "2769",
    name: "Herrera",
    country_code: "PA",
  },
  {
    id: "2770",
    name: "Kuna Yala",
    country_code: "PA",
  },
  {
    id: "2771",
    name: "Los Santos",
    country_code: "PA",
  },
  {
    id: "2772",
    name: "Ngobe Bugle",
    country_code: "PA",
  },
  {
    id: "2773",
    name: "Panama",
    country_code: "PA",
  },
  {
    id: "2774",
    name: "Veraguas",
    country_code: "PA",
  },
  {
    id: "2775",
    name: "East New Britain",
    country_code: "PG",
  },
  {
    id: "2776",
    name: "East Sepik",
    country_code: "PG",
  },
  {
    id: "2777",
    name: "Eastern Highlands",
    country_code: "PG",
  },
  {
    id: "2778",
    name: "Enga",
    country_code: "PG",
  },
  {
    id: "2779",
    name: "Fly River",
    country_code: "PG",
  },
  {
    id: "2780",
    name: "Gulf",
    country_code: "PG",
  },
  {
    id: "2781",
    name: "Madang",
    country_code: "PG",
  },
  {
    id: "2782",
    name: "Manus",
    country_code: "PG",
  },
  {
    id: "2783",
    name: "Milne Bay",
    country_code: "PG",
  },
  {
    id: "2784",
    name: "Morobe",
    country_code: "PG",
  },
  {
    id: "2785",
    name: "National Capital District",
    country_code: "PG",
  },
  {
    id: "2786",
    name: "New Ireland",
    country_code: "PG",
  },
  {
    id: "2787",
    name: "North Solomons",
    country_code: "PG",
  },
  {
    id: "2788",
    name: "Oro",
    country_code: "PG",
  },
  {
    id: "2789",
    name: "Sandaun",
    country_code: "PG",
  },
  {
    id: "2790",
    name: "Simbu",
    country_code: "PG",
  },
  {
    id: "2791",
    name: "Southern Highlands",
    country_code: "PG",
  },
  {
    id: "2792",
    name: "West New Britain",
    country_code: "PG",
  },
  {
    id: "2793",
    name: "Western Highlands",
    country_code: "PG",
  },
  {
    id: "2794",
    name: "Alto Paraguay",
    country_code: "PY",
  },
  {
    id: "2795",
    name: "Alto Parana",
    country_code: "PY",
  },
  {
    id: "2796",
    name: "Amambay",
    country_code: "PY",
  },
  {
    id: "2797",
    name: "Asuncion",
    country_code: "PY",
  },
  {
    id: "2798",
    name: "Boqueron",
    country_code: "PY",
  },
  {
    id: "2799",
    name: "Caaguazu",
    country_code: "PY",
  },
  {
    id: "2800",
    name: "Caazapa",
    country_code: "PY",
  },
  {
    id: "2801",
    name: "Canendiyu",
    country_code: "PY",
  },
  {
    id: "2802",
    name: "Central",
    country_code: "PY",
  },
  {
    id: "2803",
    name: "Concepcion",
    country_code: "PY",
  },
  {
    id: "2804",
    name: "Cordillera",
    country_code: "PY",
  },
  {
    id: "2805",
    name: "Guaira",
    country_code: "PY",
  },
  {
    id: "2806",
    name: "Itapua",
    country_code: "PY",
  },
  {
    id: "2807",
    name: "Misiones",
    country_code: "PY",
  },
  {
    id: "2808",
    name: "Neembucu",
    country_code: "PY",
  },
  {
    id: "2809",
    name: "Paraguari",
    country_code: "PY",
  },
  {
    id: "2810",
    name: "Presidente Hayes",
    country_code: "PY",
  },
  {
    id: "2811",
    name: "San Pedro",
    country_code: "PY",
  },
  {
    id: "2812",
    name: "Amazonas",
    country_code: "PE",
  },
  {
    id: "2813",
    name: "Ancash",
    country_code: "PE",
  },
  {
    id: "2814",
    name: "Apurimac",
    country_code: "PE",
  },
  {
    id: "2815",
    name: "Arequipa",
    country_code: "PE",
  },
  {
    id: "2816",
    name: "Ayacucho",
    country_code: "PE",
  },
  {
    id: "2817",
    name: "Cajamarca",
    country_code: "PE",
  },
  {
    id: "2818",
    name: "Cusco",
    country_code: "PE",
  },
  {
    id: "2819",
    name: "Huancavelica",
    country_code: "PE",
  },
  {
    id: "2820",
    name: "Huanuco",
    country_code: "PE",
  },
  {
    id: "2821",
    name: "Ica",
    country_code: "PE",
  },
  {
    id: "2822",
    name: "Junin",
    country_code: "PE",
  },
  {
    id: "2823",
    name: "La Libertad",
    country_code: "PE",
  },
  {
    id: "2824",
    name: "Lambayeque",
    country_code: "PE",
  },
  {
    id: "2825",
    name: "Lima y Callao",
    country_code: "PE",
  },
  {
    id: "2826",
    name: "Loreto",
    country_code: "PE",
  },
  {
    id: "2827",
    name: "Madre de Dios",
    country_code: "PE",
  },
  {
    id: "2828",
    name: "Moquegua",
    country_code: "PE",
  },
  {
    id: "2829",
    name: "Pasco",
    country_code: "PE",
  },
  {
    id: "2830",
    name: "Piura",
    country_code: "PE",
  },
  {
    id: "2831",
    name: "Puno",
    country_code: "PE",
  },
  {
    id: "2832",
    name: "San Martin",
    country_code: "PE",
  },
  {
    id: "2833",
    name: "Tacna",
    country_code: "PE",
  },
  {
    id: "2834",
    name: "Tumbes",
    country_code: "PE",
  },
  {
    id: "2835",
    name: "Ucayali",
    country_code: "PE",
  },
  {
    id: "2836",
    name: "Batangas",
    country_code: "PH",
  },
  {
    id: "2837",
    name: "Bicol",
    country_code: "PH",
  },
  {
    id: "2838",
    name: "Bulacan",
    country_code: "PH",
  },
  {
    id: "2839",
    name: "Cagayan",
    country_code: "PH",
  },
  {
    id: "2840",
    name: "Caraga",
    country_code: "PH",
  },
  {
    id: "2841",
    name: "Central Luzon",
    country_code: "PH",
  },
  {
    id: "2842",
    name: "Central Mindanao",
    country_code: "PH",
  },
  {
    id: "2843",
    name: "Central Visayas",
    country_code: "PH",
  },
  {
    id: "2844",
    name: "Cordillera",
    country_code: "PH",
  },
  {
    id: "2845",
    name: "Davao",
    country_code: "PH",
  },
  {
    id: "2846",
    name: "Eastern Visayas",
    country_code: "PH",
  },
  {
    id: "2847",
    name: "Greater Metropolitan Area",
    country_code: "PH",
  },
  {
    id: "2848",
    name: "Ilocos",
    country_code: "PH",
  },
  {
    id: "2849",
    name: "Laguna",
    country_code: "PH",
  },
  {
    id: "2850",
    name: "Luzon",
    country_code: "PH",
  },
  {
    id: "2851",
    name: "Mactan",
    country_code: "PH",
  },
  {
    id: "2852",
    name: "Metropolitan Manila Area",
    country_code: "PH",
  },
  {
    id: "2853",
    name: "Muslim Mindanao",
    country_code: "PH",
  },
  {
    id: "2854",
    name: "Northern Mindanao",
    country_code: "PH",
  },
  {
    id: "2855",
    name: "Southern Mindanao",
    country_code: "PH",
  },
  {
    id: "2856",
    name: "Southern Tagalog",
    country_code: "PH",
  },
  {
    id: "2857",
    name: "Western Mindanao",
    country_code: "PH",
  },
  {
    id: "2858",
    name: "Western Visayas",
    country_code: "PH",
  },
  {
    id: "2860",
    name: "Biale Blota",
    country_code: "PL",
  },
  {
    id: "2861",
    name: "Dobroszyce",
    country_code: "PL",
  },
  {
    id: "2862",
    name: "Dolnoslaskie",
    country_code: "PL",
  },
  {
    id: "2863",
    name: "Dziekanow Lesny",
    country_code: "PL",
  },
  {
    id: "2864",
    name: "Hopowo",
    country_code: "PL",
  },
  {
    id: "2865",
    name: "Kartuzy",
    country_code: "PL",
  },
  {
    id: "2866",
    name: "Koscian",
    country_code: "PL",
  },
  {
    id: "2867",
    name: "Krakow",
    country_code: "PL",
  },
  {
    id: "2868",
    name: "Kujawsko-Pomorskie",
    country_code: "PL",
  },
  {
    id: "2869",
    name: "Lodzkie",
    country_code: "PL",
  },
  {
    id: "2870",
    name: "Lubelskie",
    country_code: "PL",
  },
  {
    id: "2871",
    name: "Lubuskie",
    country_code: "PL",
  },
  {
    id: "2872",
    name: "Malomice",
    country_code: "PL",
  },
  {
    id: "2873",
    name: "Malopolskie",
    country_code: "PL",
  },
  {
    id: "2874",
    name: "Mazowieckie",
    country_code: "PL",
  },
  {
    id: "2875",
    name: "Mirkow",
    country_code: "PL",
  },
  {
    id: "2876",
    name: "Opolskie",
    country_code: "PL",
  },
  {
    id: "2877",
    name: "Ostrowiec",
    country_code: "PL",
  },
  {
    id: "2878",
    name: "Podkarpackie",
    country_code: "PL",
  },
  {
    id: "2879",
    name: "Podlaskie",
    country_code: "PL",
  },
  {
    id: "2880",
    name: "Polska",
    country_code: "PL",
  },
  {
    id: "2881",
    name: "Pomorskie",
    country_code: "PL",
  },
  {
    id: "2882",
    name: "Poznan",
    country_code: "PL",
  },
  {
    id: "2883",
    name: "Pruszkow",
    country_code: "PL",
  },
  {
    id: "2884",
    name: "Rymanowska",
    country_code: "PL",
  },
  {
    id: "2885",
    name: "Rzeszow",
    country_code: "PL",
  },
  {
    id: "2886",
    name: "Slaskie",
    country_code: "PL",
  },
  {
    id: "2887",
    name: "Stare Pole",
    country_code: "PL",
  },
  {
    id: "2888",
    name: "Swietokrzyskie",
    country_code: "PL",
  },
  {
    id: "2889",
    name: "Warminsko-Mazurskie",
    country_code: "PL",
  },
  {
    id: "2890",
    name: "Warsaw",
    country_code: "PL",
  },
  {
    id: "2891",
    name: "Wejherowo",
    country_code: "PL",
  },
  {
    id: "2892",
    name: "Wielkopolskie",
    country_code: "PL",
  },
  {
    id: "2893",
    name: "Wroclaw",
    country_code: "PL",
  },
  {
    id: "2894",
    name: "Zachodnio-Pomorskie",
    country_code: "PL",
  },
  {
    id: "2895",
    name: "Zukowo",
    country_code: "PL",
  },
  {
    id: "2896",
    name: "Abrantes",
    country_code: "PT",
  },
  {
    id: "2897",
    name: "Acores",
    country_code: "PT",
  },
  {
    id: "2898",
    name: "Alentejo",
    country_code: "PT",
  },
  {
    id: "2899",
    name: "Algarve",
    country_code: "PT",
  },
  {
    id: "2900",
    name: "Braga",
    country_code: "PT",
  },
  {
    id: "2901",
    name: "Centro",
    country_code: "PT",
  },
  {
    id: "2902",
    name: "Distrito de Leiria",
    country_code: "PT",
  },
  {
    id: "2903",
    name: "Distrito de Viana do Castelo",
    country_code: "PT",
  },
  {
    id: "2904",
    name: "Distrito de Vila Real",
    country_code: "PT",
  },
  {
    id: "2905",
    name: "Distrito do Porto",
    country_code: "PT",
  },
  {
    id: "2906",
    name: "Lisboa e Vale do Tejo",
    country_code: "PT",
  },
  {
    id: "2907",
    name: "Madeira",
    country_code: "PT",
  },
  {
    id: "2908",
    name: "Norte",
    country_code: "PT",
  },
  {
    id: "2909",
    name: "Paivas",
    country_code: "PT",
  },
  {
    id: "2910",
    name: "Arecibo",
    country_code: "PR",
  },
  {
    id: "2911",
    name: "Bayamon",
    country_code: "PR",
  },
  {
    id: "2912",
    name: "Carolina",
    country_code: "PR",
  },
  {
    id: "2913",
    name: "Florida",
    country_code: "PR",
  },
  {
    id: "2914",
    name: "Guayama",
    country_code: "PR",
  },
  {
    id: "2915",
    name: "Humacao",
    country_code: "PR",
  },
  {
    id: "2916",
    name: "Mayaguez-Aguadilla",
    country_code: "PR",
  },
  {
    id: "2917",
    name: "Ponce",
    country_code: "PR",
  },
  {
    id: "2918",
    name: "Salinas",
    country_code: "PR",
  },
  {
    id: "2919",
    name: "San Juan",
    country_code: "PR",
  },
  {
    id: "2920",
    name: "Doha",
    country_code: "QA",
  },
  {
    id: "2921",
    name: "Jarian-al-Batnah",
    country_code: "QA",
  },
  {
    id: "2922",
    name: "Umm Salal",
    country_code: "QA",
  },
  {
    id: "2923",
    name: "ad-Dawhah",
    country_code: "QA",
  },
  {
    id: "2924",
    name: "al-Ghuwayriyah",
    country_code: "QA",
  },
  {
    id: "2925",
    name: "al-Jumayliyah",
    country_code: "QA",
  },
  {
    id: "2926",
    name: "al-Khawr",
    country_code: "QA",
  },
  {
    id: "2927",
    name: "al-Wakrah",
    country_code: "QA",
  },
  {
    id: "2928",
    name: "ar-Rayyan",
    country_code: "QA",
  },
  {
    id: "2929",
    name: "ash-Shamal",
    country_code: "QA",
  },
  {
    id: "2934",
    name: "Alba",
    country_code: "RO",
  },
  {
    id: "2935",
    name: "Arad",
    country_code: "RO",
  },
  {
    id: "2936",
    name: "Arges",
    country_code: "RO",
  },
  {
    id: "2937",
    name: "Bacau",
    country_code: "RO",
  },
  {
    id: "2938",
    name: "Bihor",
    country_code: "RO",
  },
  {
    id: "2939",
    name: "Bistrita-Nasaud",
    country_code: "RO",
  },
  {
    id: "2940",
    name: "Botosani",
    country_code: "RO",
  },
  {
    id: "2941",
    name: "Braila",
    country_code: "RO",
  },
  {
    id: "2942",
    name: "Brasov",
    country_code: "RO",
  },
  {
    id: "2943",
    name: "Bucuresti",
    country_code: "RO",
  },
  {
    id: "2944",
    name: "Buzau",
    country_code: "RO",
  },
  {
    id: "2945",
    name: "Calarasi",
    country_code: "RO",
  },
  {
    id: "2946",
    name: "Caras-Severin",
    country_code: "RO",
  },
  {
    id: "2947",
    name: "Cluj",
    country_code: "RO",
  },
  {
    id: "2948",
    name: "Constanta",
    country_code: "RO",
  },
  {
    id: "2949",
    name: "Covasna",
    country_code: "RO",
  },
  {
    id: "2950",
    name: "Dambovita",
    country_code: "RO",
  },
  {
    id: "2951",
    name: "Dolj",
    country_code: "RO",
  },
  {
    id: "2952",
    name: "Galati",
    country_code: "RO",
  },
  {
    id: "2953",
    name: "Giurgiu",
    country_code: "RO",
  },
  {
    id: "2954",
    name: "Gorj",
    country_code: "RO",
  },
  {
    id: "2955",
    name: "Harghita",
    country_code: "RO",
  },
  {
    id: "2956",
    name: "Hunedoara",
    country_code: "RO",
  },
  {
    id: "2957",
    name: "Ialomita",
    country_code: "RO",
  },
  {
    id: "2958",
    name: "Iasi",
    country_code: "RO",
  },
  {
    id: "2959",
    name: "Ilfov",
    country_code: "RO",
  },
  {
    id: "2960",
    name: "Maramures",
    country_code: "RO",
  },
  {
    id: "2961",
    name: "Mehedinti",
    country_code: "RO",
  },
  {
    id: "2962",
    name: "Mures",
    country_code: "RO",
  },
  {
    id: "2963",
    name: "Neamt",
    country_code: "RO",
  },
  {
    id: "2964",
    name: "Olt",
    country_code: "RO",
  },
  {
    id: "2965",
    name: "Prahova",
    country_code: "RO",
  },
  {
    id: "2966",
    name: "Salaj",
    country_code: "RO",
  },
  {
    id: "2967",
    name: "Satu Mare",
    country_code: "RO",
  },
  {
    id: "2968",
    name: "Sibiu",
    country_code: "RO",
  },
  {
    id: "2969",
    name: "Sondelor",
    country_code: "RO",
  },
  {
    id: "2970",
    name: "Suceava",
    country_code: "RO",
  },
  {
    id: "2971",
    name: "Teleorman",
    country_code: "RO",
  },
  {
    id: "2972",
    name: "Timis",
    country_code: "RO",
  },
  {
    id: "2973",
    name: "Tulcea",
    country_code: "RO",
  },
  {
    id: "2974",
    name: "Valcea",
    country_code: "RO",
  },
  {
    id: "2975",
    name: "Vaslui",
    country_code: "RO",
  },
  {
    id: "2976",
    name: "Vrancea",
    country_code: "RO",
  },
  {
    id: "2977",
    name: "Adygeja",
    country_code: "RU",
  },
  {
    id: "2978",
    name: "Aga",
    country_code: "RU",
  },
  {
    id: "2979",
    name: "Alanija",
    country_code: "RU",
  },
  {
    id: "2980",
    name: "Altaj",
    country_code: "RU",
  },
  {
    id: "2981",
    name: "Amur",
    country_code: "RU",
  },
  {
    id: "2982",
    name: "Arhangelsk",
    country_code: "RU",
  },
  {
    id: "2983",
    name: "Astrahan",
    country_code: "RU",
  },
  {
    id: "2984",
    name: "Bashkortostan",
    country_code: "RU",
  },
  {
    id: "2985",
    name: "Belgorod",
    country_code: "RU",
  },
  {
    id: "2986",
    name: "Brjansk",
    country_code: "RU",
  },
  {
    id: "2987",
    name: "Burjatija",
    country_code: "RU",
  },
  {
    id: "2988",
    name: "Chechenija",
    country_code: "RU",
  },
  {
    id: "2989",
    name: "Cheljabinsk",
    country_code: "RU",
  },
  {
    id: "2990",
    name: "Chita",
    country_code: "RU",
  },
  {
    id: "2991",
    name: "Chukotka",
    country_code: "RU",
  },
  {
    id: "2992",
    name: "Chuvashija",
    country_code: "RU",
  },
  {
    id: "2993",
    name: "Dagestan",
    country_code: "RU",
  },
  {
    id: "2994",
    name: "Evenkija",
    country_code: "RU",
  },
  {
    id: "2995",
    name: "Gorno-Altaj",
    country_code: "RU",
  },
  {
    id: "2996",
    name: "Habarovsk",
    country_code: "RU",
  },
  {
    id: "2997",
    name: "Hakasija",
    country_code: "RU",
  },
  {
    id: "2998",
    name: "Hanty-Mansija",
    country_code: "RU",
  },
  {
    id: "2999",
    name: "Ingusetija",
    country_code: "RU",
  },
  {
    id: "3000",
    name: "Irkutsk",
    country_code: "RU",
  },
  {
    id: "3001",
    name: "Ivanovo",
    country_code: "RU",
  },
  {
    id: "3002",
    name: "Jamalo-Nenets",
    country_code: "RU",
  },
  {
    id: "3003",
    name: "Jaroslavl",
    country_code: "RU",
  },
  {
    id: "3004",
    name: "Jevrej",
    country_code: "RU",
  },
  {
    id: "3005",
    name: "Kabardino-Balkarija",
    country_code: "RU",
  },
  {
    id: "3006",
    name: "Kaliningrad",
    country_code: "RU",
  },
  {
    id: "3007",
    name: "Kalmykija",
    country_code: "RU",
  },
  {
    id: "3008",
    name: "Kaluga",
    country_code: "RU",
  },
  {
    id: "3009",
    name: "Kamchatka",
    country_code: "RU",
  },
  {
    id: "3010",
    name: "Karachaj-Cherkessija",
    country_code: "RU",
  },
  {
    id: "3011",
    name: "Karelija",
    country_code: "RU",
  },
  {
    id: "3012",
    name: "Kemerovo",
    country_code: "RU",
  },
  {
    id: "3013",
    name: "Khabarovskiy Kray",
    country_code: "RU",
  },
  {
    id: "3014",
    name: "Kirov",
    country_code: "RU",
  },
  {
    id: "3015",
    name: "Komi",
    country_code: "RU",
  },
  {
    id: "3016",
    name: "Komi-Permjakija",
    country_code: "RU",
  },
  {
    id: "3017",
    name: "Korjakija",
    country_code: "RU",
  },
  {
    id: "3018",
    name: "Kostroma",
    country_code: "RU",
  },
  {
    id: "3019",
    name: "Krasnodar",
    country_code: "RU",
  },
  {
    id: "3020",
    name: "Krasnojarsk",
    country_code: "RU",
  },
  {
    id: "3021",
    name: "Krasnoyarskiy Kray",
    country_code: "RU",
  },
  {
    id: "3022",
    name: "Kurgan",
    country_code: "RU",
  },
  {
    id: "3023",
    name: "Kursk",
    country_code: "RU",
  },
  {
    id: "3024",
    name: "Leningrad",
    country_code: "RU",
  },
  {
    id: "3025",
    name: "Lipeck",
    country_code: "RU",
  },
  {
    id: "3026",
    name: "Magadan",
    country_code: "RU",
  },
  {
    id: "3027",
    name: "Marij El",
    country_code: "RU",
  },
  {
    id: "3028",
    name: "Mordovija",
    country_code: "RU",
  },
  {
    id: "3029",
    name: "Moscow",
    country_code: "RU",
  },
  {
    id: "3030",
    name: "Moskovskaja Oblast",
    country_code: "RU",
  },
  {
    id: "3031",
    name: "Moskovskaya Oblast",
    country_code: "RU",
  },
  {
    id: "3032",
    name: "Moskva",
    country_code: "RU",
  },
  {
    id: "3033",
    name: "Murmansk",
    country_code: "RU",
  },
  {
    id: "3034",
    name: "Nenets",
    country_code: "RU",
  },
  {
    id: "3035",
    name: "Nizhnij Novgorod",
    country_code: "RU",
  },
  {
    id: "3036",
    name: "Novgorod",
    country_code: "RU",
  },
  {
    id: "3037",
    name: "Novokusnezk",
    country_code: "RU",
  },
  {
    id: "3038",
    name: "Novosibirsk",
    country_code: "RU",
  },
  {
    id: "3039",
    name: "Omsk",
    country_code: "RU",
  },
  {
    id: "3040",
    name: "Orenburg",
    country_code: "RU",
  },
  {
    id: "3041",
    name: "Orjol",
    country_code: "RU",
  },
  {
    id: "3042",
    name: "Penza",
    country_code: "RU",
  },
  {
    id: "3043",
    name: "Perm",
    country_code: "RU",
  },
  {
    id: "3044",
    name: "Primorje",
    country_code: "RU",
  },
  {
    id: "3045",
    name: "Pskov",
    country_code: "RU",
  },
  {
    id: "3046",
    name: "Pskovskaya Oblast",
    country_code: "RU",
  },
  {
    id: "3047",
    name: "Rjazan",
    country_code: "RU",
  },
  {
    id: "3048",
    name: "Rostov",
    country_code: "RU",
  },
  {
    id: "3049",
    name: "Saha",
    country_code: "RU",
  },
  {
    id: "3050",
    name: "Sahalin",
    country_code: "RU",
  },
  {
    id: "3051",
    name: "Samara",
    country_code: "RU",
  },
  {
    id: "3052",
    name: "Samarskaya",
    country_code: "RU",
  },
  {
    id: "3053",
    name: "Sankt-Peterburg",
    country_code: "RU",
  },
  {
    id: "3054",
    name: "Saratov",
    country_code: "RU",
  },
  {
    id: "3055",
    name: "Smolensk",
    country_code: "RU",
  },
  {
    id: "3056",
    name: "Stavropol",
    country_code: "RU",
  },
  {
    id: "3057",
    name: "Sverdlovsk",
    country_code: "RU",
  },
  {
    id: "3058",
    name: "Tajmyrija",
    country_code: "RU",
  },
  {
    id: "3059",
    name: "Tambov",
    country_code: "RU",
  },
  {
    id: "3060",
    name: "Tatarstan",
    country_code: "RU",
  },
  {
    id: "3061",
    name: "Tjumen",
    country_code: "RU",
  },
  {
    id: "3062",
    name: "Tomsk",
    country_code: "RU",
  },
  {
    id: "3063",
    name: "Tula",
    country_code: "RU",
  },
  {
    id: "3064",
    name: "Tver",
    country_code: "RU",
  },
  {
    id: "3065",
    name: "Tyva",
    country_code: "RU",
  },
  {
    id: "3066",
    name: "Udmurtija",
    country_code: "RU",
  },
  {
    id: "3067",
    name: "Uljanovsk",
    country_code: "RU",
  },
  {
    id: "3068",
    name: "Ulyanovskaya Oblast",
    country_code: "RU",
  },
  {
    id: "3069",
    name: "Ust-Orda",
    country_code: "RU",
  },
  {
    id: "3070",
    name: "Vladimir",
    country_code: "RU",
  },
  {
    id: "3071",
    name: "Volgograd",
    country_code: "RU",
  },
  {
    id: "3072",
    name: "Vologda",
    country_code: "RU",
  },
  {
    id: "3073",
    name: "Voronezh",
    country_code: "RU",
  },
  {
    id: "3074",
    name: "Butare",
    country_code: "RW",
  },
  {
    id: "3075",
    name: "Byumba",
    country_code: "RW",
  },
  {
    id: "3076",
    name: "Cyangugu",
    country_code: "RW",
  },
  {
    id: "3077",
    name: "Gikongoro",
    country_code: "RW",
  },
  {
    id: "3078",
    name: "Gisenyi",
    country_code: "RW",
  },
  {
    id: "3079",
    name: "Gitarama",
    country_code: "RW",
  },
  {
    id: "3080",
    name: "Kibungo",
    country_code: "RW",
  },
  {
    id: "3081",
    name: "Kibuye",
    country_code: "RW",
  },
  {
    id: "3082",
    name: "Kigali-ngali",
    country_code: "RW",
  },
  {
    id: "3083",
    name: "Ruhengeri",
    country_code: "RW",
  },
  {
    id: "3088",
    name: "Christ Church Nichola Town",
    country_code: "KN",
  },
  {
    id: "3089",
    name: "Saint Anne Sandy Point",
    country_code: "KN",
  },
  {
    id: "3090",
    name: "Saint George Basseterre",
    country_code: "KN",
  },
  {
    id: "3091",
    name: "Saint George Gingerland",
    country_code: "KN",
  },
  {
    id: "3092",
    name: "Saint James Windward",
    country_code: "KN",
  },
  {
    id: "3093",
    name: "Saint John Capesterre",
    country_code: "KN",
  },
  {
    id: "3094",
    name: "Saint John Figtree",
    country_code: "KN",
  },
  {
    id: "3095",
    name: "Saint Mary Cayon",
    country_code: "KN",
  },
  {
    id: "3096",
    name: "Saint Paul Capesterre",
    country_code: "KN",
  },
  {
    id: "3097",
    name: "Saint Paul Charlestown",
    country_code: "KN",
  },
  {
    id: "3098",
    name: "Saint Peter Basseterre",
    country_code: "KN",
  },
  {
    id: "3099",
    name: "Saint Thomas Lowland",
    country_code: "KN",
  },
  {
    id: "3100",
    name: "Saint Thomas Middle Island",
    country_code: "KN",
  },
  {
    id: "3101",
    name: "Trinity Palmetto Point",
    country_code: "KN",
  },
  {
    id: "3102",
    name: "Anse-la-Raye",
    country_code: "LC",
  },
  {
    id: "3103",
    name: "Canaries",
    country_code: "LC",
  },
  {
    id: "3104",
    name: "Castries",
    country_code: "LC",
  },
  {
    id: "3105",
    name: "Choiseul",
    country_code: "LC",
  },
  {
    id: "3106",
    name: "Dennery",
    country_code: "LC",
  },
  {
    id: "3107",
    name: "Gros Inlet",
    country_code: "LC",
  },
  {
    id: "3108",
    name: "Laborie",
    country_code: "LC",
  },
  {
    id: "3109",
    name: "Micoud",
    country_code: "LC",
  },
  {
    id: "3110",
    name: "Soufriere",
    country_code: "LC",
  },
  {
    id: "3111",
    name: "Vieux Fort",
    country_code: "LC",
  },
  {
    id: "3114",
    name: "Charlotte",
    country_code: "VC",
  },
  {
    id: "3115",
    name: "Grenadines",
    country_code: "VC",
  },
  {
    id: "3116",
    name: "Saint Andrew",
    country_code: "VC",
  },
  {
    id: "3117",
    name: "Saint David",
    country_code: "VC",
  },
  {
    id: "3118",
    name: "Saint George",
    country_code: "VC",
  },
  {
    id: "3119",
    name: "Saint Patrick",
    country_code: "VC",
  },
  {
    id: "3120",
    name: "A''ana",
    country_code: "WS",
  },
  {
    id: "3121",
    name: "Aiga-i-le-Tai",
    country_code: "WS",
  },
  {
    id: "3122",
    name: "Atua",
    country_code: "WS",
  },
  {
    id: "3123",
    name: "Fa''asaleleaga",
    country_code: "WS",
  },
  {
    id: "3124",
    name: "Gaga''emauga",
    country_code: "WS",
  },
  {
    id: "3125",
    name: "Gagaifomauga",
    country_code: "WS",
  },
  {
    id: "3126",
    name: "Palauli",
    country_code: "WS",
  },
  {
    id: "3127",
    name: "Satupa''itea",
    country_code: "WS",
  },
  {
    id: "3128",
    name: "Tuamasaga",
    country_code: "WS",
  },
  {
    id: "3129",
    name: "Va''a-o-Fonoti",
    country_code: "WS",
  },
  {
    id: "3130",
    name: "Vaisigano",
    country_code: "WS",
  },
  {
    id: "3131",
    name: "Acquaviva",
    country_code: "SM",
  },
  {
    id: "3132",
    name: "Borgo Maggiore",
    country_code: "SM",
  },
  {
    id: "3133",
    name: "Chiesanuova",
    country_code: "SM",
  },
  {
    id: "3134",
    name: "Domagnano",
    country_code: "SM",
  },
  {
    id: "3135",
    name: "Faetano",
    country_code: "SM",
  },
  {
    id: "3136",
    name: "Fiorentino",
    country_code: "SM",
  },
  {
    id: "3137",
    name: "Montegiardino",
    country_code: "SM",
  },
  {
    id: "3138",
    name: "San Marino",
    country_code: "SM",
  },
  {
    id: "3139",
    name: "Serravalle",
    country_code: "SM",
  },
  {
    id: "3140",
    name: "Agua Grande",
    country_code: "ST",
  },
  {
    id: "3141",
    name: "Cantagalo",
    country_code: "ST",
  },
  {
    id: "3142",
    name: "Lemba",
    country_code: "ST",
  },
  {
    id: "3143",
    name: "Lobata",
    country_code: "ST",
  },
  {
    id: "3144",
    name: "Me-Zochi",
    country_code: "ST",
  },
  {
    id: "3145",
    name: "Pague",
    country_code: "ST",
  },
  {
    id: "3146",
    name: "Al Khobar",
    country_code: "SA",
  },
  {
    id: "3147",
    name: "Aseer",
    country_code: "SA",
  },
  {
    id: "3148",
    name: "Ash Sharqiyah",
    country_code: "SA",
  },
  {
    id: "3149",
    name: "Asir",
    country_code: "SA",
  },
  {
    id: "3150",
    name: "Central Province",
    country_code: "SA",
  },
  {
    id: "3151",
    name: "Eastern Province",
    country_code: "SA",
  },
  {
    id: "3152",
    name: "Ha''il",
    country_code: "SA",
  },
  {
    id: "3153",
    name: "Jawf",
    country_code: "SA",
  },
  {
    id: "3154",
    name: "Jizan",
    country_code: "SA",
  },
  {
    id: "3155",
    name: "Makkah",
    country_code: "SA",
  },
  {
    id: "3156",
    name: "Najran",
    country_code: "SA",
  },
  {
    id: "3157",
    name: "Qasim",
    country_code: "SA",
  },
  {
    id: "3158",
    name: "Tabuk",
    country_code: "SA",
  },
  {
    id: "3159",
    name: "Western Province",
    country_code: "SA",
  },
  {
    id: "3160",
    name: "al-Bahah",
    country_code: "SA",
  },
  {
    id: "3161",
    name: "al-Hudud-ash-Shamaliyah",
    country_code: "SA",
  },
  {
    id: "3162",
    name: "al-Madinah",
    country_code: "SA",
  },
  {
    id: "3163",
    name: "ar-Riyad",
    country_code: "SA",
  },
  {
    id: "3164",
    name: "Dakar",
    country_code: "SN",
  },
  {
    id: "3165",
    name: "Diourbel",
    country_code: "SN",
  },
  {
    id: "3166",
    name: "Fatick",
    country_code: "SN",
  },
  {
    id: "3167",
    name: "Kaolack",
    country_code: "SN",
  },
  {
    id: "3168",
    name: "Kolda",
    country_code: "SN",
  },
  {
    id: "3169",
    name: "Louga",
    country_code: "SN",
  },
  {
    id: "3170",
    name: "Saint-Louis",
    country_code: "SN",
  },
  {
    id: "3171",
    name: "Tambacounda",
    country_code: "SN",
  },
  {
    id: "3172",
    name: "Thies",
    country_code: "SN",
  },
  {
    id: "3173",
    name: "Ziguinchor",
    country_code: "SN",
  },
  {
    id: "3174",
    name: "Central Serbia",
    country_code: "RS",
  },
  {
    id: "3175",
    name: "Kosovo and Metohija",
    country_code: "RS",
  },
  {
    id: "3176",
    name: "Vojvodina",
    country_code: "RS",
  },
  {
    id: "3177",
    name: "Anse Boileau",
    country_code: "SC",
  },
  {
    id: "3178",
    name: "Anse Royale",
    country_code: "SC",
  },
  {
    id: "3179",
    name: "Cascade",
    country_code: "SC",
  },
  {
    id: "3180",
    name: "Takamaka",
    country_code: "SC",
  },
  {
    id: "3181",
    name: "Victoria",
    country_code: "SC",
  },
  {
    id: "3182",
    name: "Eastern",
    country_code: "SL",
  },
  {
    id: "3183",
    name: "Northern",
    country_code: "SL",
  },
  {
    id: "3184",
    name: "Southern",
    country_code: "SL",
  },
  {
    id: "3185",
    name: "Western",
    country_code: "SL",
  },
  {
    id: "3186",
    name: "Singapore",
    country_code: "SG",
  },
  {
    id: "3187",
    name: "Banskobystricky",
    country_code: "SK",
  },
  {
    id: "3188",
    name: "Bratislavsky",
    country_code: "SK",
  },
  {
    id: "3189",
    name: "Kosicky",
    country_code: "SK",
  },
  {
    id: "3190",
    name: "Nitriansky",
    country_code: "SK",
  },
  {
    id: "3191",
    name: "Presovsky",
    country_code: "SK",
  },
  {
    id: "3192",
    name: "Trenciansky",
    country_code: "SK",
  },
  {
    id: "3193",
    name: "Trnavsky",
    country_code: "SK",
  },
  {
    id: "3194",
    name: "Zilinsky",
    country_code: "SK",
  },
  {
    id: "3195",
    name: "Benedikt",
    country_code: "SI",
  },
  {
    id: "3196",
    name: "Gorenjska",
    country_code: "SI",
  },
  {
    id: "3197",
    name: "Gorishka",
    country_code: "SI",
  },
  {
    id: "3198",
    name: "Jugovzhodna Slovenija",
    country_code: "SI",
  },
  {
    id: "3199",
    name: "Koroshka",
    country_code: "SI",
  },
  {
    id: "3200",
    name: "Notranjsko-krashka",
    country_code: "SI",
  },
  {
    id: "3201",
    name: "Obalno-krashka",
    country_code: "SI",
  },
  {
    id: "3202",
    name: "Obcina Domzale",
    country_code: "SI",
  },
  {
    id: "3203",
    name: "Obcina Vitanje",
    country_code: "SI",
  },
  {
    id: "3204",
    name: "Osrednjeslovenska",
    country_code: "SI",
  },
  {
    id: "3205",
    name: "Podravska",
    country_code: "SI",
  },
  {
    id: "3206",
    name: "Pomurska",
    country_code: "SI",
  },
  {
    id: "3207",
    name: "Savinjska",
    country_code: "SI",
  },
  {
    id: "3208",
    name: "Slovenian Littoral",
    country_code: "SI",
  },
  {
    id: "3209",
    name: "Spodnjeposavska",
    country_code: "SI",
  },
  {
    id: "3210",
    name: "Zasavska",
    country_code: "SI",
  },
  {
    id: "3212",
    name: "Central",
    country_code: "SB",
  },
  {
    id: "3213",
    name: "Choiseul",
    country_code: "SB",
  },
  {
    id: "3214",
    name: "Guadalcanal",
    country_code: "SB",
  },
  {
    id: "3215",
    name: "Isabel",
    country_code: "SB",
  },
  {
    id: "3216",
    name: "Makira and Ulawa",
    country_code: "SB",
  },
  {
    id: "3217",
    name: "Malaita",
    country_code: "SB",
  },
  {
    id: "3218",
    name: "Rennell and Bellona",
    country_code: "SB",
  },
  {
    id: "3219",
    name: "Temotu",
    country_code: "SB",
  },
  {
    id: "3220",
    name: "Western",
    country_code: "SB",
  },
  {
    id: "3221",
    name: "Awdal",
    country_code: "SO",
  },
  {
    id: "3222",
    name: "Bakol",
    country_code: "SO",
  },
  {
    id: "3223",
    name: "Banadir",
    country_code: "SO",
  },
  {
    id: "3224",
    name: "Bari",
    country_code: "SO",
  },
  {
    id: "3225",
    name: "Bay",
    country_code: "SO",
  },
  {
    id: "3226",
    name: "Galgudug",
    country_code: "SO",
  },
  {
    id: "3227",
    name: "Gedo",
    country_code: "SO",
  },
  {
    id: "3228",
    name: "Hiran",
    country_code: "SO",
  },
  {
    id: "3229",
    name: "Jubbada Hose",
    country_code: "SO",
  },
  {
    id: "3230",
    name: "Jubbadha Dexe",
    country_code: "SO",
  },
  {
    id: "3231",
    name: "Mudug",
    country_code: "SO",
  },
  {
    id: "3232",
    name: "Nugal",
    country_code: "SO",
  },
  {
    id: "3233",
    name: "Sanag",
    country_code: "SO",
  },
  {
    id: "3234",
    name: "Shabellaha Dhexe",
    country_code: "SO",
  },
  {
    id: "3235",
    name: "Shabellaha Hose",
    country_code: "SO",
  },
  {
    id: "3236",
    name: "Togdher",
    country_code: "SO",
  },
  {
    id: "3237",
    name: "Woqoyi Galbed",
    country_code: "SO",
  },
  {
    id: "3238",
    name: "Eastern Cape",
    country_code: "ZA",
  },
  {
    id: "3239",
    name: "Free State",
    country_code: "ZA",
  },
  {
    id: "3240",
    name: "Gauteng",
    country_code: "ZA",
  },
  {
    id: "3241",
    name: "Kempton Park",
    country_code: "ZA",
  },
  {
    id: "3242",
    name: "Kramerville",
    country_code: "ZA",
  },
  {
    id: "3243",
    name: "KwaZulu Natal",
    country_code: "ZA",
  },
  {
    id: "3244",
    name: "Limpopo",
    country_code: "ZA",
  },
  {
    id: "3245",
    name: "Mpumalanga",
    country_code: "ZA",
  },
  {
    id: "3246",
    name: "North West",
    country_code: "ZA",
  },
  {
    id: "3247",
    name: "Northern Cape",
    country_code: "ZA",
  },
  {
    id: "3248",
    name: "Parow",
    country_code: "ZA",
  },
  {
    id: "3249",
    name: "Table View",
    country_code: "ZA",
  },
  {
    id: "3250",
    name: "Umtentweni",
    country_code: "ZA",
  },
  {
    id: "3251",
    name: "Western Cape",
    country_code: "ZA",
  },
  {
    id: "3252",
    name: "South Georgia",
    country_code: "KR",
  },
  {
    id: "3253",
    name: "Central Equatoria",
    country_code: "SS",
  },
  {
    id: "3254",
    name: "A Coruna",
    country_code: "ES",
  },
  {
    id: "3255",
    name: "Alacant",
    country_code: "ES",
  },
  {
    id: "3256",
    name: "Alava",
    country_code: "ES",
  },
  {
    id: "3257",
    name: "Albacete",
    country_code: "ES",
  },
  {
    id: "3258",
    name: "Almeria",
    country_code: "ES",
  },
  {
    id: "3259",
    name: "Andalucia",
    country_code: "ES",
  },
  {
    id: "3260",
    name: "Asturias",
    country_code: "ES",
  },
  {
    id: "3261",
    name: "Avila",
    country_code: "ES",
  },
  {
    id: "3262",
    name: "Badajoz",
    country_code: "ES",
  },
  {
    id: "3263",
    name: "Balears",
    country_code: "ES",
  },
  {
    id: "3264",
    name: "Barcelona",
    country_code: "ES",
  },
  {
    id: "3265",
    name: "Bertamirans",
    country_code: "ES",
  },
  {
    id: "3266",
    name: "Biscay",
    country_code: "ES",
  },
  {
    id: "3267",
    name: "Burgos",
    country_code: "ES",
  },
  {
    id: "3268",
    name: "Caceres",
    country_code: "ES",
  },
  {
    id: "3269",
    name: "Cadiz",
    country_code: "ES",
  },
  {
    id: "3270",
    name: "Cantabria",
    country_code: "ES",
  },
  {
    id: "3271",
    name: "Castello",
    country_code: "ES",
  },
  {
    id: "3272",
    name: "Catalunya",
    country_code: "ES",
  },
  {
    id: "3273",
    name: "Ceuta",
    country_code: "ES",
  },
  {
    id: "3274",
    name: "Ciudad Real",
    country_code: "ES",
  },
  {
    id: "3275",
    name: "Comunidad Autonoma de Canarias",
    country_code: "ES",
  },
  {
    id: "3276",
    name: "Comunidad Autonoma de Cataluna",
    country_code: "ES",
  },
  {
    id: "3277",
    name: "Comunidad Autonoma de Galicia",
    country_code: "ES",
  },
  {
    id: "3278",
    name: "Comunidad Autonoma de las Isla",
    country_code: "ES",
  },
  {
    id: "3279",
    name: "Comunidad Autonoma del Princip",
    country_code: "ES",
  },
  {
    id: "3280",
    name: "Comunidad Valenciana",
    country_code: "ES",
  },
  {
    id: "3281",
    name: "Cordoba",
    country_code: "ES",
  },
  {
    id: "3282",
    name: "Cuenca",
    country_code: "ES",
  },
  {
    id: "3283",
    name: "Gipuzkoa",
    country_code: "ES",
  },
  {
    id: "3284",
    name: "Girona",
    country_code: "ES",
  },
  {
    id: "3285",
    name: "Granada",
    country_code: "ES",
  },
  {
    id: "3286",
    name: "Guadalajara",
    country_code: "ES",
  },
  {
    id: "3287",
    name: "Guipuzcoa",
    country_code: "ES",
  },
  {
    id: "3288",
    name: "Huelva",
    country_code: "ES",
  },
  {
    id: "3289",
    name: "Huesca",
    country_code: "ES",
  },
  {
    id: "3290",
    name: "Jaen",
    country_code: "ES",
  },
  {
    id: "3291",
    name: "La Rioja",
    country_code: "ES",
  },
  {
    id: "3292",
    name: "Las Palmas",
    country_code: "ES",
  },
  {
    id: "3293",
    name: "Leon",
    country_code: "ES",
  },
  {
    id: "3294",
    name: "Lerida",
    country_code: "ES",
  },
  {
    id: "3295",
    name: "Lleida",
    country_code: "ES",
  },
  {
    id: "3296",
    name: "Lugo",
    country_code: "ES",
  },
  {
    id: "3297",
    name: "Madrid",
    country_code: "ES",
  },
  {
    id: "3298",
    name: "Malaga",
    country_code: "ES",
  },
  {
    id: "3299",
    name: "Melilla",
    country_code: "ES",
  },
  {
    id: "3300",
    name: "Murcia",
    country_code: "ES",
  },
  {
    id: "3301",
    name: "Navarra",
    country_code: "ES",
  },
  {
    id: "3302",
    name: "Ourense",
    country_code: "ES",
  },
  {
    id: "3303",
    name: "Pais Vasco",
    country_code: "ES",
  },
  {
    id: "3304",
    name: "Palencia",
    country_code: "ES",
  },
  {
    id: "3305",
    name: "Pontevedra",
    country_code: "ES",
  },
  {
    id: "3306",
    name: "Salamanca",
    country_code: "ES",
  },
  {
    id: "3307",
    name: "Santa Cruz de Tenerife",
    country_code: "ES",
  },
  {
    id: "3308",
    name: "Segovia",
    country_code: "ES",
  },
  {
    id: "3309",
    name: "Sevilla",
    country_code: "ES",
  },
  {
    id: "3310",
    name: "Soria",
    country_code: "ES",
  },
  {
    id: "3311",
    name: "Tarragona",
    country_code: "ES",
  },
  {
    id: "3312",
    name: "Tenerife",
    country_code: "ES",
  },
  {
    id: "3313",
    name: "Teruel",
    country_code: "ES",
  },
  {
    id: "3314",
    name: "Toledo",
    country_code: "ES",
  },
  {
    id: "3315",
    name: "Valencia",
    country_code: "ES",
  },
  {
    id: "3316",
    name: "Valladolid",
    country_code: "ES",
  },
  {
    id: "3317",
    name: "Vizcaya",
    country_code: "ES",
  },
  {
    id: "3318",
    name: "Zamora",
    country_code: "ES",
  },
  {
    id: "3319",
    name: "Zaragoza",
    country_code: "ES",
  },
  {
    id: "3320",
    name: "Amparai",
    country_code: "LK",
  },
  {
    id: "3321",
    name: "Anuradhapuraya",
    country_code: "LK",
  },
  {
    id: "3322",
    name: "Badulla",
    country_code: "LK",
  },
  {
    id: "3323",
    name: "Boralesgamuwa",
    country_code: "LK",
  },
  {
    id: "3324",
    name: "Colombo",
    country_code: "LK",
  },
  {
    id: "3325",
    name: "Galla",
    country_code: "LK",
  },
  {
    id: "3326",
    name: "Gampaha",
    country_code: "LK",
  },
  {
    id: "3327",
    name: "Hambantota",
    country_code: "LK",
  },
  {
    id: "3328",
    name: "Kalatura",
    country_code: "LK",
  },
  {
    id: "3329",
    name: "Kegalla",
    country_code: "LK",
  },
  {
    id: "3330",
    name: "Kilinochchi",
    country_code: "LK",
  },
  {
    id: "3331",
    name: "Kurunegala",
    country_code: "LK",
  },
  {
    id: "3332",
    name: "Madakalpuwa",
    country_code: "LK",
  },
  {
    id: "3333",
    name: "Maha Nuwara",
    country_code: "LK",
  },
  {
    id: "3334",
    name: "Malwana",
    country_code: "LK",
  },
  {
    id: "3335",
    name: "Mannarama",
    country_code: "LK",
  },
  {
    id: "3336",
    name: "Matale",
    country_code: "LK",
  },
  {
    id: "3337",
    name: "Matara",
    country_code: "LK",
  },
  {
    id: "3338",
    name: "Monaragala",
    country_code: "LK",
  },
  {
    id: "3339",
    name: "Mullaitivu",
    country_code: "LK",
  },
  {
    id: "3340",
    name: "North Eastern Province",
    country_code: "LK",
  },
  {
    id: "3341",
    name: "North Western Province",
    country_code: "LK",
  },
  {
    id: "3342",
    name: "Nuwara Eliya",
    country_code: "LK",
  },
  {
    id: "3343",
    name: "Polonnaruwa",
    country_code: "LK",
  },
  {
    id: "3344",
    name: "Puttalama",
    country_code: "LK",
  },
  {
    id: "3345",
    name: "Ratnapuraya",
    country_code: "LK",
  },
  {
    id: "3346",
    name: "Southern Province",
    country_code: "LK",
  },
  {
    id: "3347",
    name: "Tirikunamalaya",
    country_code: "LK",
  },
  {
    id: "3348",
    name: "Tuscany",
    country_code: "LK",
  },
  {
    id: "3349",
    name: "Vavuniyawa",
    country_code: "LK",
  },
  {
    id: "3350",
    name: "Western Province",
    country_code: "LK",
  },
  {
    id: "3351",
    name: "Yapanaya",
    country_code: "LK",
  },
  {
    id: "3352",
    name: "kadawatha",
    country_code: "LK",
  },
  {
    id: "3353",
    name: "A''ali-an-Nil",
    country_code: "SD",
  },
  {
    id: "3354",
    name: "Bahr-al-Jabal",
    country_code: "SD",
  },
  {
    id: "3355",
    name: "Central Equatoria",
    country_code: "SD",
  },
  {
    id: "3356",
    name: "Gharb Bahr-al-Ghazal",
    country_code: "SD",
  },
  {
    id: "3357",
    name: "Gharb Darfur",
    country_code: "SD",
  },
  {
    id: "3358",
    name: "Gharb Kurdufan",
    country_code: "SD",
  },
  {
    id: "3359",
    name: "Gharb-al-Istiwa''iyah",
    country_code: "SD",
  },
  {
    id: "3360",
    name: "Janub Darfur",
    country_code: "SD",
  },
  {
    id: "3361",
    name: "Janub Kurdufan",
    country_code: "SD",
  },
  {
    id: "3362",
    name: "Junqali",
    country_code: "SD",
  },
  {
    id: "3363",
    name: "Kassala",
    country_code: "SD",
  },
  {
    id: "3364",
    name: "Nahr-an-Nil",
    country_code: "SD",
  },
  {
    id: "3365",
    name: "Shamal Bahr-al-Ghazal",
    country_code: "SD",
  },
  {
    id: "3366",
    name: "Shamal Darfur",
    country_code: "SD",
  },
  {
    id: "3367",
    name: "Shamal Kurdufan",
    country_code: "SD",
  },
  {
    id: "3368",
    name: "Sharq-al-Istiwa''iyah",
    country_code: "SD",
  },
  {
    id: "3369",
    name: "Sinnar",
    country_code: "SD",
  },
  {
    id: "3370",
    name: "Warab",
    country_code: "SD",
  },
  {
    id: "3371",
    name: "Wilayat al Khartum",
    country_code: "SD",
  },
  {
    id: "3372",
    name: "al-Bahr-al-Ahmar",
    country_code: "SD",
  },
  {
    id: "3373",
    name: "al-Buhayrat",
    country_code: "SD",
  },
  {
    id: "3374",
    name: "al-Jazirah",
    country_code: "SD",
  },
  {
    id: "3375",
    name: "al-Khartum",
    country_code: "SD",
  },
  {
    id: "3376",
    name: "al-Qadarif",
    country_code: "SD",
  },
  {
    id: "3377",
    name: "al-Wahdah",
    country_code: "SD",
  },
  {
    id: "3378",
    name: "an-Nil-al-Abyad",
    country_code: "SD",
  },
  {
    id: "3379",
    name: "an-Nil-al-Azraq",
    country_code: "SD",
  },
  {
    id: "3380",
    name: "ash-Shamaliyah",
    country_code: "SD",
  },
  {
    id: "3381",
    name: "Brokopondo",
    country_code: "SR",
  },
  {
    id: "3382",
    name: "Commewijne",
    country_code: "SR",
  },
  {
    id: "3383",
    name: "Coronie",
    country_code: "SR",
  },
  {
    id: "3384",
    name: "Marowijne",
    country_code: "SR",
  },
  {
    id: "3385",
    name: "Nickerie",
    country_code: "SR",
  },
  {
    id: "3386",
    name: "Para",
    country_code: "SR",
  },
  {
    id: "3387",
    name: "Paramaribo",
    country_code: "SR",
  },
  {
    id: "3388",
    name: "Saramacca",
    country_code: "SR",
  },
  {
    id: "3389",
    name: "Wanica",
    country_code: "SR",
  },
  {
    id: "3395",
    name: "Alvsborgs Lan",
    country_code: "SE",
  },
  {
    id: "3396",
    name: "Angermanland",
    country_code: "SE",
  },
  {
    id: "3397",
    name: "Blekinge",
    country_code: "SE",
  },
  {
    id: "3398",
    name: "Bohuslan",
    country_code: "SE",
  },
  {
    id: "3399",
    name: "Dalarna",
    country_code: "SE",
  },
  {
    id: "3400",
    name: "Gavleborg",
    country_code: "SE",
  },
  {
    id: "3401",
    name: "Gaza",
    country_code: "SE",
  },
  {
    id: "3402",
    name: "Gotland",
    country_code: "SE",
  },
  {
    id: "3403",
    name: "Halland",
    country_code: "SE",
  },
  {
    id: "3404",
    name: "Jamtland",
    country_code: "SE",
  },
  {
    id: "3405",
    name: "Jonkoping",
    country_code: "SE",
  },
  {
    id: "3406",
    name: "Kalmar",
    country_code: "SE",
  },
  {
    id: "3407",
    name: "Kristianstads",
    country_code: "SE",
  },
  {
    id: "3408",
    name: "Kronoberg",
    country_code: "SE",
  },
  {
    id: "3409",
    name: "Norrbotten",
    country_code: "SE",
  },
  {
    id: "3410",
    name: "Orebro",
    country_code: "SE",
  },
  {
    id: "3411",
    name: "Ostergotland",
    country_code: "SE",
  },
  {
    id: "3412",
    name: "Saltsjo-Boo",
    country_code: "SE",
  },
  {
    id: "3413",
    name: "Skane",
    country_code: "SE",
  },
  {
    id: "3414",
    name: "Smaland",
    country_code: "SE",
  },
  {
    id: "3415",
    name: "Sodermanland",
    country_code: "SE",
  },
  {
    id: "3416",
    name: "Stockholm",
    country_code: "SE",
  },
  {
    id: "3417",
    name: "Uppsala",
    country_code: "SE",
  },
  {
    id: "3418",
    name: "Varmland",
    country_code: "SE",
  },
  {
    id: "3419",
    name: "Vasterbotten",
    country_code: "SE",
  },
  {
    id: "3420",
    name: "Vastergotland",
    country_code: "SE",
  },
  {
    id: "3421",
    name: "Vasternorrland",
    country_code: "SE",
  },
  {
    id: "3422",
    name: "Vastmanland",
    country_code: "SE",
  },
  {
    id: "3423",
    name: "Vastra Gotaland",
    country_code: "SE",
  },
  {
    id: "3424",
    name: "Aargau",
    country_code: "CH",
  },
  {
    id: "3425",
    name: "Appenzell Inner-Rhoden",
    country_code: "CH",
  },
  {
    id: "3426",
    name: "Appenzell-Ausser Rhoden",
    country_code: "CH",
  },
  {
    id: "3427",
    name: "Basel-Landschaft",
    country_code: "CH",
  },
  {
    id: "3428",
    name: "Basel-Stadt",
    country_code: "CH",
  },
  {
    id: "3429",
    name: "Bern",
    country_code: "CH",
  },
  {
    id: "3430",
    name: "Canton Ticino",
    country_code: "CH",
  },
  {
    id: "3431",
    name: "Fribourg",
    country_code: "CH",
  },
  {
    id: "3432",
    name: "Geneve",
    country_code: "CH",
  },
  {
    id: "3433",
    name: "Glarus",
    country_code: "CH",
  },
  {
    id: "3434",
    name: "Graubunden",
    country_code: "CH",
  },
  {
    id: "3435",
    name: "Heerbrugg",
    country_code: "CH",
  },
  {
    id: "3436",
    name: "Jura",
    country_code: "CH",
  },
  {
    id: "3437",
    name: "Kanton Aargau",
    country_code: "CH",
  },
  {
    id: "3438",
    name: "Luzern",
    country_code: "CH",
  },
  {
    id: "3439",
    name: "Morbio Inferiore",
    country_code: "CH",
  },
  {
    id: "3440",
    name: "Muhen",
    country_code: "CH",
  },
  {
    id: "3441",
    name: "Neuchatel",
    country_code: "CH",
  },
  {
    id: "3442",
    name: "Nidwalden",
    country_code: "CH",
  },
  {
    id: "3443",
    name: "Obwalden",
    country_code: "CH",
  },
  {
    id: "3444",
    name: "Sankt Gallen",
    country_code: "CH",
  },
  {
    id: "3445",
    name: "Schaffhausen",
    country_code: "CH",
  },
  {
    id: "3446",
    name: "Schwyz",
    country_code: "CH",
  },
  {
    id: "3447",
    name: "Solothurn",
    country_code: "CH",
  },
  {
    id: "3448",
    name: "Thurgau",
    country_code: "CH",
  },
  {
    id: "3449",
    name: "Ticino",
    country_code: "CH",
  },
  {
    id: "3450",
    name: "Uri",
    country_code: "CH",
  },
  {
    id: "3451",
    name: "Valais",
    country_code: "CH",
  },
  {
    id: "3452",
    name: "Vaud",
    country_code: "CH",
  },
  {
    id: "3453",
    name: "Vauffelin",
    country_code: "CH",
  },
  {
    id: "3454",
    name: "Zug",
    country_code: "CH",
  },
  {
    id: "3455",
    name: "Zurich",
    country_code: "CH",
  },
  {
    id: "3456",
    name: "Aleppo",
    country_code: "SY",
  },
  {
    id: "3457",
    name: "Dar''a",
    country_code: "SY",
  },
  {
    id: "3458",
    name: "Dayr-az-Zawr",
    country_code: "SY",
  },
  {
    id: "3459",
    name: "Dimashq",
    country_code: "SY",
  },
  {
    id: "3460",
    name: "Halab",
    country_code: "SY",
  },
  {
    id: "3461",
    name: "Hamah",
    country_code: "SY",
  },
  {
    id: "3462",
    name: "Hims",
    country_code: "SY",
  },
  {
    id: "3463",
    name: "Idlib",
    country_code: "SY",
  },
  {
    id: "3464",
    name: "Madinat Dimashq",
    country_code: "SY",
  },
  {
    id: "3465",
    name: "Tartus",
    country_code: "SY",
  },
  {
    id: "3466",
    name: "al-Hasakah",
    country_code: "SY",
  },
  {
    id: "3467",
    name: "al-Ladhiqiyah",
    country_code: "SY",
  },
  {
    id: "3468",
    name: "al-Qunaytirah",
    country_code: "SY",
  },
  {
    id: "3469",
    name: "ar-Raqqah",
    country_code: "SY",
  },
  {
    id: "3470",
    name: "as-Suwayda",
    country_code: "SY",
  },
  {
    id: "3471",
    name: "Changhua County",
    country_code: "TW",
  },
  {
    id: "3472",
    name: "Chiayi County",
    country_code: "TW",
  },
  {
    id: "3473",
    name: "Chiayi City",
    country_code: "TW",
  },
  {
    id: "3474",
    name: "Taipei City",
    country_code: "TW",
  },
  {
    id: "3475",
    name: "Hsinchu County",
    country_code: "TW",
  },
  {
    id: "3476",
    name: "Hsinchu City",
    country_code: "TW",
  },
  {
    id: "3477",
    name: "Hualien County",
    country_code: "TW",
  },
  {
    id: "3480",
    name: "Kaohsiung City",
    country_code: "TW",
  },
  {
    id: "3481",
    name: "Keelung City",
    country_code: "TW",
  },
  {
    id: "3482",
    name: "Kinmen County",
    country_code: "TW",
  },
  {
    id: "3483",
    name: "Miaoli County",
    country_code: "TW",
  },
  {
    id: "3484",
    name: "Nantou County",
    country_code: "TW",
  },
  {
    id: "3486",
    name: "Penghu County",
    country_code: "TW",
  },
  {
    id: "3487",
    name: "Pingtung County",
    country_code: "TW",
  },
  {
    id: "3488",
    name: "Taichung City",
    country_code: "TW",
  },
  {
    id: "3492",
    name: "Tainan City",
    country_code: "TW",
  },
  {
    id: "3493",
    name: "New Taipei City",
    country_code: "TW",
  },
  {
    id: "3495",
    name: "Taitung County",
    country_code: "TW",
  },
  {
    id: "3496",
    name: "Taoyuan City",
    country_code: "TW",
  },
  {
    id: "3497",
    name: "Yilan County",
    country_code: "TW",
  },
  {
    id: "3498",
    name: "YunLin County",
    country_code: "TW",
  },
  {
    id: "4121",
    name: "Lienchiang County",
    country_code: "TW",
  },
  {
    id: "3500",
    name: "Dushanbe",
    country_code: "TJ",
  },
  {
    id: "3501",
    name: "Gorno-Badakhshan",
    country_code: "TJ",
  },
  {
    id: "3502",
    name: "Karotegin",
    country_code: "TJ",
  },
  {
    id: "3503",
    name: "Khatlon",
    country_code: "TJ",
  },
  {
    id: "3504",
    name: "Sughd",
    country_code: "TJ",
  },
  {
    id: "3505",
    name: "Arusha",
    country_code: "TZ",
  },
  {
    id: "3506",
    name: "Dar es Salaam",
    country_code: "TZ",
  },
  {
    id: "3507",
    name: "Dodoma",
    country_code: "TZ",
  },
  {
    id: "3508",
    name: "Iringa",
    country_code: "TZ",
  },
  {
    id: "3509",
    name: "Kagera",
    country_code: "TZ",
  },
  {
    id: "3510",
    name: "Kigoma",
    country_code: "TZ",
  },
  {
    id: "3511",
    name: "Kilimanjaro",
    country_code: "TZ",
  },
  {
    id: "3512",
    name: "Lindi",
    country_code: "TZ",
  },
  {
    id: "3513",
    name: "Mara",
    country_code: "TZ",
  },
  {
    id: "3514",
    name: "Mbeya",
    country_code: "TZ",
  },
  {
    id: "3515",
    name: "Morogoro",
    country_code: "TZ",
  },
  {
    id: "3516",
    name: "Mtwara",
    country_code: "TZ",
  },
  {
    id: "3517",
    name: "Mwanza",
    country_code: "TZ",
  },
  {
    id: "3518",
    name: "Pwani",
    country_code: "TZ",
  },
  {
    id: "3519",
    name: "Rukwa",
    country_code: "TZ",
  },
  {
    id: "3520",
    name: "Ruvuma",
    country_code: "TZ",
  },
  {
    id: "3521",
    name: "Shinyanga",
    country_code: "TZ",
  },
  {
    id: "3522",
    name: "Singida",
    country_code: "TZ",
  },
  {
    id: "3523",
    name: "Tabora",
    country_code: "TZ",
  },
  {
    id: "3524",
    name: "Tanga",
    country_code: "TZ",
  },
  {
    id: "3525",
    name: "Zanzibar and Pemba",
    country_code: "TZ",
  },
  {
    id: "3526",
    name: "Amnat Charoen",
    country_code: "TH",
  },
  {
    id: "3527",
    name: "Ang Thong",
    country_code: "TH",
  },
  {
    id: "3528",
    name: "Bangkok",
    country_code: "TH",
  },
  {
    id: "3529",
    name: "Buri Ram",
    country_code: "TH",
  },
  {
    id: "3530",
    name: "Chachoengsao",
    country_code: "TH",
  },
  {
    id: "3531",
    name: "Chai Nat",
    country_code: "TH",
  },
  {
    id: "3532",
    name: "Chaiyaphum",
    country_code: "TH",
  },
  {
    id: "3533",
    name: "Changwat Chaiyaphum",
    country_code: "TH",
  },
  {
    id: "3534",
    name: "Chanthaburi",
    country_code: "TH",
  },
  {
    id: "3535",
    name: "Chiang Mai",
    country_code: "TH",
  },
  {
    id: "3536",
    name: "Chiang Rai",
    country_code: "TH",
  },
  {
    id: "3537",
    name: "Chon Buri",
    country_code: "TH",
  },
  {
    id: "3538",
    name: "Chumphon",
    country_code: "TH",
  },
  {
    id: "3539",
    name: "Kalasin",
    country_code: "TH",
  },
  {
    id: "3540",
    name: "Kamphaeng Phet",
    country_code: "TH",
  },
  {
    id: "3541",
    name: "Kanchanaburi",
    country_code: "TH",
  },
  {
    id: "3542",
    name: "Khon Kaen",
    country_code: "TH",
  },
  {
    id: "3543",
    name: "Krabi",
    country_code: "TH",
  },
  {
    id: "3544",
    name: "Krung Thep",
    country_code: "TH",
  },
  {
    id: "3545",
    name: "Lampang",
    country_code: "TH",
  },
  {
    id: "3546",
    name: "Lamphun",
    country_code: "TH",
  },
  {
    id: "3547",
    name: "Loei",
    country_code: "TH",
  },
  {
    id: "3548",
    name: "Lop Buri",
    country_code: "TH",
  },
  {
    id: "3549",
    name: "Mae Hong Son",
    country_code: "TH",
  },
  {
    id: "3550",
    name: "Maha Sarakham",
    country_code: "TH",
  },
  {
    id: "3551",
    name: "Mukdahan",
    country_code: "TH",
  },
  {
    id: "3552",
    name: "Nakhon Nayok",
    country_code: "TH",
  },
  {
    id: "3553",
    name: "Nakhon Pathom",
    country_code: "TH",
  },
  {
    id: "3554",
    name: "Nakhon Phanom",
    country_code: "TH",
  },
  {
    id: "3555",
    name: "Nakhon Ratchasima",
    country_code: "TH",
  },
  {
    id: "3556",
    name: "Nakhon Sawan",
    country_code: "TH",
  },
  {
    id: "3557",
    name: "Nakhon Si Thammarat",
    country_code: "TH",
  },
  {
    id: "3558",
    name: "Nan",
    country_code: "TH",
  },
  {
    id: "3559",
    name: "Narathiwat",
    country_code: "TH",
  },
  {
    id: "3560",
    name: "Nong Bua Lam Phu",
    country_code: "TH",
  },
  {
    id: "3561",
    name: "Nong Khai",
    country_code: "TH",
  },
  {
    id: "3562",
    name: "Nonthaburi",
    country_code: "TH",
  },
  {
    id: "3563",
    name: "Pathum Thani",
    country_code: "TH",
  },
  {
    id: "3564",
    name: "Pattani",
    country_code: "TH",
  },
  {
    id: "3565",
    name: "Phangnga",
    country_code: "TH",
  },
  {
    id: "3566",
    name: "Phatthalung",
    country_code: "TH",
  },
  {
    id: "3567",
    name: "Phayao",
    country_code: "TH",
  },
  {
    id: "3568",
    name: "Phetchabun",
    country_code: "TH",
  },
  {
    id: "3569",
    name: "Phetchaburi",
    country_code: "TH",
  },
  {
    id: "3570",
    name: "Phichit",
    country_code: "TH",
  },
  {
    id: "3571",
    name: "Phitsanulok",
    country_code: "TH",
  },
  {
    id: "3572",
    name: "Phra Nakhon Si Ayutthaya",
    country_code: "TH",
  },
  {
    id: "3573",
    name: "Phrae",
    country_code: "TH",
  },
  {
    id: "3574",
    name: "Phuket",
    country_code: "TH",
  },
  {
    id: "3575",
    name: "Prachin Buri",
    country_code: "TH",
  },
  {
    id: "3576",
    name: "Prachuap Khiri Khan",
    country_code: "TH",
  },
  {
    id: "3577",
    name: "Ranong",
    country_code: "TH",
  },
  {
    id: "3578",
    name: "Ratchaburi",
    country_code: "TH",
  },
  {
    id: "3579",
    name: "Rayong",
    country_code: "TH",
  },
  {
    id: "3580",
    name: "Roi Et",
    country_code: "TH",
  },
  {
    id: "3581",
    name: "Sa Kaeo",
    country_code: "TH",
  },
  {
    id: "3582",
    name: "Sakon Nakhon",
    country_code: "TH",
  },
  {
    id: "3583",
    name: "Samut Prakan",
    country_code: "TH",
  },
  {
    id: "3584",
    name: "Samut Sakhon",
    country_code: "TH",
  },
  {
    id: "3585",
    name: "Samut Songkhran",
    country_code: "TH",
  },
  {
    id: "3586",
    name: "Saraburi",
    country_code: "TH",
  },
  {
    id: "3587",
    name: "Satun",
    country_code: "TH",
  },
  {
    id: "3588",
    name: "Si Sa Ket",
    country_code: "TH",
  },
  {
    id: "3589",
    name: "Sing Buri",
    country_code: "TH",
  },
  {
    id: "3590",
    name: "Songkhla",
    country_code: "TH",
  },
  {
    id: "3591",
    name: "Sukhothai",
    country_code: "TH",
  },
  {
    id: "3592",
    name: "Suphan Buri",
    country_code: "TH",
  },
  {
    id: "3593",
    name: "Surat Thani",
    country_code: "TH",
  },
  {
    id: "3594",
    name: "Surin",
    country_code: "TH",
  },
  {
    id: "3595",
    name: "Tak",
    country_code: "TH",
  },
  {
    id: "3596",
    name: "Trang",
    country_code: "TH",
  },
  {
    id: "3597",
    name: "Trat",
    country_code: "TH",
  },
  {
    id: "3598",
    name: "Ubon Ratchathani",
    country_code: "TH",
  },
  {
    id: "3599",
    name: "Udon Thani",
    country_code: "TH",
  },
  {
    id: "3600",
    name: "Uthai Thani",
    country_code: "TH",
  },
  {
    id: "3601",
    name: "Uttaradit",
    country_code: "TH",
  },
  {
    id: "3602",
    name: "Yala",
    country_code: "TH",
  },
  {
    id: "3603",
    name: "Yasothon",
    country_code: "TH",
  },
  {
    id: "3604",
    name: "Centre",
    country_code: "TG",
  },
  {
    id: "3605",
    name: "Kara",
    country_code: "TG",
  },
  {
    id: "3606",
    name: "Maritime",
    country_code: "TG",
  },
  {
    id: "3607",
    name: "Plateaux",
    country_code: "TG",
  },
  {
    id: "3608",
    name: "Savanes",
    country_code: "TG",
  },
  {
    id: "3612",
    name: "Eua",
    country_code: "TO",
  },
  {
    id: "3613",
    name: "Ha''apai",
    country_code: "TO",
  },
  {
    id: "3614",
    name: "Niuas",
    country_code: "TO",
  },
  {
    id: "3615",
    name: "Tongatapu",
    country_code: "TO",
  },
  {
    id: "3616",
    name: "Vava''u",
    country_code: "TO",
  },
  {
    id: "3617",
    name: "Arima-Tunapuna-Piarco",
    country_code: "TT",
  },
  {
    id: "3618",
    name: "Caroni",
    country_code: "TT",
  },
  {
    id: "3619",
    name: "Chaguanas",
    country_code: "TT",
  },
  {
    id: "3620",
    name: "Couva-Tabaquite-Talparo",
    country_code: "TT",
  },
  {
    id: "3621",
    name: "Diego Martin",
    country_code: "TT",
  },
  {
    id: "3622",
    name: "Glencoe",
    country_code: "TT",
  },
  {
    id: "3623",
    name: "Penal Debe",
    country_code: "TT",
  },
  {
    id: "3624",
    name: "Point Fortin",
    country_code: "TT",
  },
  {
    id: "3625",
    name: "Port of Spain",
    country_code: "TT",
  },
  {
    id: "3626",
    name: "Princes Town",
    country_code: "TT",
  },
  {
    id: "3627",
    name: "Saint George",
    country_code: "TT",
  },
  {
    id: "3628",
    name: "San Fernando",
    country_code: "TT",
  },
  {
    id: "3629",
    name: "San Juan",
    country_code: "TT",
  },
  {
    id: "3630",
    name: "Sangre Grande",
    country_code: "TT",
  },
  {
    id: "3631",
    name: "Siparia",
    country_code: "TT",
  },
  {
    id: "3632",
    name: "Tobago",
    country_code: "TT",
  },
  {
    id: "3633",
    name: "Aryanah",
    country_code: "TN",
  },
  {
    id: "3634",
    name: "Bajah",
    country_code: "TN",
  },
  {
    id: "3635",
    name: "Bin ''Arus",
    country_code: "TN",
  },
  {
    id: "3636",
    name: "Binzart",
    country_code: "TN",
  },
  {
    id: "3637",
    name: "Gouvernorat de Ariana",
    country_code: "TN",
  },
  {
    id: "3638",
    name: "Gouvernorat de Nabeul",
    country_code: "TN",
  },
  {
    id: "3639",
    name: "Gouvernorat de Sousse",
    country_code: "TN",
  },
  {
    id: "3640",
    name: "Hammamet Yasmine",
    country_code: "TN",
  },
  {
    id: "3641",
    name: "Jundubah",
    country_code: "TN",
  },
  {
    id: "3642",
    name: "Madaniyin",
    country_code: "TN",
  },
  {
    id: "3643",
    name: "Manubah",
    country_code: "TN",
  },
  {
    id: "3644",
    name: "Monastir",
    country_code: "TN",
  },
  {
    id: "3645",
    name: "Nabul",
    country_code: "TN",
  },
  {
    id: "3646",
    name: "Qabis",
    country_code: "TN",
  },
  {
    id: "3647",
    name: "Qafsah",
    country_code: "TN",
  },
  {
    id: "3648",
    name: "Qibili",
    country_code: "TN",
  },
  {
    id: "3649",
    name: "Safaqis",
    country_code: "TN",
  },
  {
    id: "3650",
    name: "Sfax",
    country_code: "TN",
  },
  {
    id: "3651",
    name: "Sidi Bu Zayd",
    country_code: "TN",
  },
  {
    id: "3652",
    name: "Silyanah",
    country_code: "TN",
  },
  {
    id: "3653",
    name: "Susah",
    country_code: "TN",
  },
  {
    id: "3654",
    name: "Tatawin",
    country_code: "TN",
  },
  {
    id: "3655",
    name: "Tawzar",
    country_code: "TN",
  },
  {
    id: "3656",
    name: "Tunis",
    country_code: "TN",
  },
  {
    id: "3657",
    name: "Zaghwan",
    country_code: "TN",
  },
  {
    id: "3658",
    name: "al-Kaf",
    country_code: "TN",
  },
  {
    id: "3659",
    name: "al-Mahdiyah",
    country_code: "TN",
  },
  {
    id: "3660",
    name: "al-Munastir",
    country_code: "TN",
  },
  {
    id: "3661",
    name: "al-Qasrayn",
    country_code: "TN",
  },
  {
    id: "3662",
    name: "al-Qayrawan",
    country_code: "TN",
  },
  {
    id: "3663",
    name: "Adana",
    country_code: "TR",
  },
  {
    id: "3664",
    name: "Adiyaman",
    country_code: "TR",
  },
  {
    id: "3665",
    name: "Afyon",
    country_code: "TR",
  },
  {
    id: "3666",
    name: "Agri",
    country_code: "TR",
  },
  {
    id: "3667",
    name: "Aksaray",
    country_code: "TR",
  },
  {
    id: "3668",
    name: "Amasya",
    country_code: "TR",
  },
  {
    id: "3669",
    name: "Ankara",
    country_code: "TR",
  },
  {
    id: "3670",
    name: "Antalya",
    country_code: "TR",
  },
  {
    id: "3671",
    name: "Ardahan",
    country_code: "TR",
  },
  {
    id: "3672",
    name: "Artvin",
    country_code: "TR",
  },
  {
    id: "3673",
    name: "Aydin",
    country_code: "TR",
  },
  {
    id: "3674",
    name: "Balikesir",
    country_code: "TR",
  },
  {
    id: "3675",
    name: "Bartin",
    country_code: "TR",
  },
  {
    id: "3676",
    name: "Batman",
    country_code: "TR",
  },
  {
    id: "3677",
    name: "Bayburt",
    country_code: "TR",
  },
  {
    id: "3678",
    name: "Bilecik",
    country_code: "TR",
  },
  {
    id: "3679",
    name: "Bingol",
    country_code: "TR",
  },
  {
    id: "3680",
    name: "Bitlis",
    country_code: "TR",
  },
  {
    id: "3681",
    name: "Bolu",
    country_code: "TR",
  },
  {
    id: "3682",
    name: "Burdur",
    country_code: "TR",
  },
  {
    id: "3683",
    name: "Bursa",
    country_code: "TR",
  },
  {
    id: "3684",
    name: "Canakkale",
    country_code: "TR",
  },
  {
    id: "3685",
    name: "Cankiri",
    country_code: "TR",
  },
  {
    id: "3686",
    name: "Corum",
    country_code: "TR",
  },
  {
    id: "3687",
    name: "Denizli",
    country_code: "TR",
  },
  {
    id: "3688",
    name: "Diyarbakir",
    country_code: "TR",
  },
  {
    id: "3689",
    name: "Duzce",
    country_code: "TR",
  },
  {
    id: "3690",
    name: "Edirne",
    country_code: "TR",
  },
  {
    id: "3691",
    name: "Elazig",
    country_code: "TR",
  },
  {
    id: "3692",
    name: "Erzincan",
    country_code: "TR",
  },
  {
    id: "3693",
    name: "Erzurum",
    country_code: "TR",
  },
  {
    id: "3694",
    name: "Eskisehir",
    country_code: "TR",
  },
  {
    id: "3695",
    name: "Gaziantep",
    country_code: "TR",
  },
  {
    id: "3696",
    name: "Giresun",
    country_code: "TR",
  },
  {
    id: "3697",
    name: "Gumushane",
    country_code: "TR",
  },
  {
    id: "3698",
    name: "Hakkari",
    country_code: "TR",
  },
  {
    id: "3699",
    name: "Hatay",
    country_code: "TR",
  },
  {
    id: "3700",
    name: "Icel",
    country_code: "TR",
  },
  {
    id: "3701",
    name: "Igdir",
    country_code: "TR",
  },
  {
    id: "3702",
    name: "Isparta",
    country_code: "TR",
  },
  {
    id: "3703",
    name: "Istanbul",
    country_code: "TR",
  },
  {
    id: "3704",
    name: "Izmir",
    country_code: "TR",
  },
  {
    id: "3705",
    name: "Kahramanmaras",
    country_code: "TR",
  },
  {
    id: "3706",
    name: "Karabuk",
    country_code: "TR",
  },
  {
    id: "3707",
    name: "Karaman",
    country_code: "TR",
  },
  {
    id: "3708",
    name: "Kars",
    country_code: "TR",
  },
  {
    id: "3709",
    name: "Karsiyaka",
    country_code: "TR",
  },
  {
    id: "3710",
    name: "Kastamonu",
    country_code: "TR",
  },
  {
    id: "3711",
    name: "Kayseri",
    country_code: "TR",
  },
  {
    id: "3712",
    name: "Kilis",
    country_code: "TR",
  },
  {
    id: "3713",
    name: "Kirikkale",
    country_code: "TR",
  },
  {
    id: "3714",
    name: "Kirklareli",
    country_code: "TR",
  },
  {
    id: "3715",
    name: "Kirsehir",
    country_code: "TR",
  },
  {
    id: "3716",
    name: "Kocaeli",
    country_code: "TR",
  },
  {
    id: "3717",
    name: "Konya",
    country_code: "TR",
  },
  {
    id: "3718",
    name: "Kutahya",
    country_code: "TR",
  },
  {
    id: "3719",
    name: "Lefkosa",
    country_code: "TR",
  },
  {
    id: "3720",
    name: "Malatya",
    country_code: "TR",
  },
  {
    id: "3721",
    name: "Manisa",
    country_code: "TR",
  },
  {
    id: "3722",
    name: "Mardin",
    country_code: "TR",
  },
  {
    id: "3723",
    name: "Mugla",
    country_code: "TR",
  },
  {
    id: "3724",
    name: "Mus",
    country_code: "TR",
  },
  {
    id: "3725",
    name: "Nevsehir",
    country_code: "TR",
  },
  {
    id: "3726",
    name: "Nigde",
    country_code: "TR",
  },
  {
    id: "3727",
    name: "Ordu",
    country_code: "TR",
  },
  {
    id: "3728",
    name: "Osmaniye",
    country_code: "TR",
  },
  {
    id: "3729",
    name: "Rize",
    country_code: "TR",
  },
  {
    id: "3730",
    name: "Sakarya",
    country_code: "TR",
  },
  {
    id: "3731",
    name: "Samsun",
    country_code: "TR",
  },
  {
    id: "3732",
    name: "Sanliurfa",
    country_code: "TR",
  },
  {
    id: "3733",
    name: "Siirt",
    country_code: "TR",
  },
  {
    id: "3734",
    name: "Sinop",
    country_code: "TR",
  },
  {
    id: "3735",
    name: "Sirnak",
    country_code: "TR",
  },
  {
    id: "3736",
    name: "Sivas",
    country_code: "TR",
  },
  {
    id: "3737",
    name: "Tekirdag",
    country_code: "TR",
  },
  {
    id: "3738",
    name: "Tokat",
    country_code: "TR",
  },
  {
    id: "3739",
    name: "Trabzon",
    country_code: "TR",
  },
  {
    id: "3740",
    name: "Tunceli",
    country_code: "TR",
  },
  {
    id: "3741",
    name: "Usak",
    country_code: "TR",
  },
  {
    id: "3742",
    name: "Van",
    country_code: "TR",
  },
  {
    id: "3743",
    name: "Yalova",
    country_code: "TR",
  },
  {
    id: "3744",
    name: "Yozgat",
    country_code: "TR",
  },
  {
    id: "3745",
    name: "Zonguldak",
    country_code: "TR",
  },
  {
    id: "3746",
    name: "Ahal",
    country_code: "TM",
  },
  {
    id: "3747",
    name: "Asgabat",
    country_code: "TM",
  },
  {
    id: "3748",
    name: "Balkan",
    country_code: "TM",
  },
  {
    id: "3749",
    name: "Dasoguz",
    country_code: "TM",
  },
  {
    id: "3750",
    name: "Lebap",
    country_code: "TM",
  },
  {
    id: "3751",
    name: "Mari",
    country_code: "TM",
  },
  {
    id: "3752",
    name: "Grand Turk",
    country_code: "TC",
  },
  {
    id: "3753",
    name: "South Caicos and East Caicos",
    country_code: "TC",
  },
  {
    id: "3754",
    name: "Funafuti",
    country_code: "TV",
  },
  {
    id: "3755",
    name: "Nanumanga",
    country_code: "TV",
  },
  {
    id: "3756",
    name: "Nanumea",
    country_code: "TV",
  },
  {
    id: "3757",
    name: "Niutao",
    country_code: "TV",
  },
  {
    id: "3758",
    name: "Nui",
    country_code: "TV",
  },
  {
    id: "3759",
    name: "Nukufetau",
    country_code: "TV",
  },
  {
    id: "3760",
    name: "Nukulaelae",
    country_code: "TV",
  },
  {
    id: "3761",
    name: "Vaitupu",
    country_code: "TV",
  },
  {
    id: "3762",
    name: "Central",
    country_code: "UG",
  },
  {
    id: "3763",
    name: "Eastern",
    country_code: "UG",
  },
  {
    id: "3764",
    name: "Northern",
    country_code: "UG",
  },
  {
    id: "3765",
    name: "Western",
    country_code: "UG",
  },
  {
    id: "3766",
    name: "Cherkas''ka",
    country_code: "UA",
  },
  {
    id: "3767",
    name: "Chernihivs''ka",
    country_code: "UA",
  },
  {
    id: "3768",
    name: "Chernivets''ka",
    country_code: "UA",
  },
  {
    id: "3769",
    name: "Crimea",
    country_code: "UA",
  },
  {
    id: "3770",
    name: "Dnipropetrovska",
    country_code: "UA",
  },
  {
    id: "3771",
    name: "Donets''ka",
    country_code: "UA",
  },
  {
    id: "3772",
    name: "Ivano-Frankivs''ka",
    country_code: "UA",
  },
  {
    id: "3773",
    name: "Kharkiv",
    country_code: "UA",
  },
  {
    id: "3774",
    name: "Kharkov",
    country_code: "UA",
  },
  {
    id: "3775",
    name: "Khersonska",
    country_code: "UA",
  },
  {
    id: "3776",
    name: "Khmel''nyts''ka",
    country_code: "UA",
  },
  {
    id: "3777",
    name: "Kirovohrad",
    country_code: "UA",
  },
  {
    id: "3778",
    name: "Krym",
    country_code: "UA",
  },
  {
    id: "3779",
    name: "Kyyiv",
    country_code: "UA",
  },
  {
    id: "3780",
    name: "Kyyivs''ka",
    country_code: "UA",
  },
  {
    id: "3781",
    name: "L''vivs''ka",
    country_code: "UA",
  },
  {
    id: "3782",
    name: "Luhans''ka",
    country_code: "UA",
  },
  {
    id: "3783",
    name: "Mykolayivs''ka",
    country_code: "UA",
  },
  {
    id: "3784",
    name: "Odes''ka",
    country_code: "UA",
  },
  {
    id: "3785",
    name: "Odessa",
    country_code: "UA",
  },
  {
    id: "3786",
    name: "Poltavs''ka",
    country_code: "UA",
  },
  {
    id: "3787",
    name: "Rivnens''ka",
    country_code: "UA",
  },
  {
    id: "3788",
    name: "Sevastopol",
    country_code: "UA",
  },
  {
    id: "3789",
    name: "Sums''ka",
    country_code: "UA",
  },
  {
    id: "3790",
    name: "Ternopil''s''ka",
    country_code: "UA",
  },
  {
    id: "3791",
    name: "Volyns''ka",
    country_code: "UA",
  },
  {
    id: "3792",
    name: "Vynnyts''ka",
    country_code: "UA",
  },
  {
    id: "3793",
    name: "Zakarpats''ka",
    country_code: "UA",
  },
  {
    id: "3794",
    name: "Zaporizhia",
    country_code: "UA",
  },
  {
    id: "3795",
    name: "Zhytomyrs''ka",
    country_code: "UA",
  },
  {
    id: "3796",
    name: "Abu Zabi",
    country_code: "AE",
  },
  {
    id: "3797",
    name: "Ajman",
    country_code: "AE",
  },
  {
    id: "3798",
    name: "Dubai",
    country_code: "AE",
  },
  {
    id: "3799",
    name: "Ras al-Khaymah",
    country_code: "AE",
  },
  {
    id: "3800",
    name: "Sharjah",
    country_code: "AE",
  },
  {
    id: "3801",
    name: "Sharjha",
    country_code: "AE",
  },
  {
    id: "3802",
    name: "Umm al Qaywayn",
    country_code: "AE",
  },
  {
    id: "3803",
    name: "al-Fujayrah",
    country_code: "AE",
  },
  {
    id: "3804",
    name: "ash-Shariqah",
    country_code: "AE",
  },
  {
    id: "3805",
    name: "Aberdeen",
    country_code: "GB",
  },
  {
    id: "3806",
    name: "Aberdeenshire",
    country_code: "GB",
  },
  {
    id: "3807",
    name: "Argyll",
    country_code: "GB",
  },
  {
    id: "3808",
    name: "Armagh",
    country_code: "GB",
  },
  {
    id: "3809",
    name: "Bedfordshire",
    country_code: "GB",
  },
  {
    id: "3810",
    name: "Belfast",
    country_code: "GB",
  },
  {
    id: "3811",
    name: "Berkshire",
    country_code: "GB",
  },
  {
    id: "3812",
    name: "Birmingham",
    country_code: "GB",
  },
  {
    id: "3813",
    name: "Brechin",
    country_code: "GB",
  },
  {
    id: "3814",
    name: "Bridgnorth",
    country_code: "GB",
  },
  {
    id: "3815",
    name: "Bristol",
    country_code: "GB",
  },
  {
    id: "3816",
    name: "Buckinghamshire",
    country_code: "GB",
  },
  {
    id: "3817",
    name: "Cambridge",
    country_code: "GB",
  },
  {
    id: "3818",
    name: "Cambridgeshire",
    country_code: "GB",
  },
  {
    id: "3819",
    name: "Channel Islands",
    country_code: "GB",
  },
  {
    id: "3820",
    name: "Cheshire",
    country_code: "GB",
  },
  {
    id: "3821",
    name: "Cleveland",
    country_code: "GB",
  },
  {
    id: "3822",
    name: "Co Fermanagh",
    country_code: "GB",
  },
  {
    id: "3823",
    name: "Conwy",
    country_code: "GB",
  },
  {
    id: "3824",
    name: "Cornwall",
    country_code: "GB",
  },
  {
    id: "3825",
    name: "Coventry",
    country_code: "GB",
  },
  {
    id: "3826",
    name: "Craven Arms",
    country_code: "GB",
  },
  {
    id: "3827",
    name: "Cumbria",
    country_code: "GB",
  },
  {
    id: "3828",
    name: "Denbighshire",
    country_code: "GB",
  },
  {
    id: "3829",
    name: "Derby",
    country_code: "GB",
  },
  {
    id: "3830",
    name: "Derbyshire",
    country_code: "GB",
  },
  {
    id: "3831",
    name: "Devon",
    country_code: "GB",
  },
  {
    id: "3832",
    name: "Dial Code Dungannon",
    country_code: "GB",
  },
  {
    id: "3833",
    name: "Didcot",
    country_code: "GB",
  },
  {
    id: "3834",
    name: "Dorset",
    country_code: "GB",
  },
  {
    id: "3835",
    name: "Dunbartonshire",
    country_code: "GB",
  },
  {
    id: "3836",
    name: "Durham",
    country_code: "GB",
  },
  {
    id: "3837",
    name: "East Dunbartonshire",
    country_code: "GB",
  },
  {
    id: "3838",
    name: "East Lothian",
    country_code: "GB",
  },
  {
    id: "3839",
    name: "East Midlands",
    country_code: "GB",
  },
  {
    id: "3840",
    name: "East Sussex",
    country_code: "GB",
  },
  {
    id: "3841",
    name: "East Yorkshire",
    country_code: "GB",
  },
  {
    id: "3842",
    name: "England",
    country_code: "GB",
  },
  {
    id: "3843",
    name: "Essex",
    country_code: "GB",
  },
  {
    id: "3844",
    name: "Fermanagh",
    country_code: "GB",
  },
  {
    id: "3845",
    name: "Fife",
    country_code: "GB",
  },
  {
    id: "3846",
    name: "Flintshire",
    country_code: "GB",
  },
  {
    id: "3847",
    name: "Fulham",
    country_code: "GB",
  },
  {
    id: "3848",
    name: "Gainsborough",
    country_code: "GB",
  },
  {
    id: "3849",
    name: "Glocestershire",
    country_code: "GB",
  },
  {
    id: "3850",
    name: "Gwent",
    country_code: "GB",
  },
  {
    id: "3851",
    name: "Hampshire",
    country_code: "GB",
  },
  {
    id: "3852",
    name: "Hants",
    country_code: "GB",
  },
  {
    id: "3853",
    name: "Herefordshire",
    country_code: "GB",
  },
  {
    id: "3854",
    name: "Hertfordshire",
    country_code: "GB",
  },
  {
    id: "3855",
    name: "Ireland",
    country_code: "GB",
  },
  {
    id: "3856",
    name: "Isle Of Man",
    country_code: "GB",
  },
  {
    id: "3857",
    name: "Isle of Wight",
    country_code: "GB",
  },
  {
    id: "3858",
    name: "Kenford",
    country_code: "GB",
  },
  {
    id: "3859",
    name: "Kent",
    country_code: "GB",
  },
  {
    id: "3860",
    name: "Kilmarnock",
    country_code: "GB",
  },
  {
    id: "3861",
    name: "Lanarkshire",
    country_code: "GB",
  },
  {
    id: "3862",
    name: "Lancashire",
    country_code: "GB",
  },
  {
    id: "3863",
    name: "Leicestershire",
    country_code: "GB",
  },
  {
    id: "3864",
    name: "Lincolnshire",
    country_code: "GB",
  },
  {
    id: "3865",
    name: "Llanymynech",
    country_code: "GB",
  },
  {
    id: "3866",
    name: "London",
    country_code: "GB",
  },
  {
    id: "3867",
    name: "Ludlow",
    country_code: "GB",
  },
  {
    id: "3868",
    name: "Manchester",
    country_code: "GB",
  },
  {
    id: "3869",
    name: "Mayfair",
    country_code: "GB",
  },
  {
    id: "3870",
    name: "Merseyside",
    country_code: "GB",
  },
  {
    id: "3871",
    name: "Mid Glamorgan",
    country_code: "GB",
  },
  {
    id: "3872",
    name: "Middlesex",
    country_code: "GB",
  },
  {
    id: "3873",
    name: "Mildenhall",
    country_code: "GB",
  },
  {
    id: "3874",
    name: "Monmouthshire",
    country_code: "GB",
  },
  {
    id: "3875",
    name: "Newton Stewart",
    country_code: "GB",
  },
  {
    id: "3876",
    name: "Norfolk",
    country_code: "GB",
  },
  {
    id: "3877",
    name: "North Humberside",
    country_code: "GB",
  },
  {
    id: "3878",
    name: "North Yorkshire",
    country_code: "GB",
  },
  {
    id: "3879",
    name: "Northamptonshire",
    country_code: "GB",
  },
  {
    id: "3880",
    name: "Northants",
    country_code: "GB",
  },
  {
    id: "3881",
    name: "Northern Ireland",
    country_code: "GB",
  },
  {
    id: "3882",
    name: "Northumberland",
    country_code: "GB",
  },
  {
    id: "3883",
    name: "Nottinghamshire",
    country_code: "GB",
  },
  {
    id: "3884",
    name: "Oxford",
    country_code: "GB",
  },
  {
    id: "3885",
    name: "Powys",
    country_code: "GB",
  },
  {
    id: "3886",
    name: "Roos-shire",
    country_code: "GB",
  },
  {
    id: "3887",
    name: "SUSSEX",
    country_code: "GB",
  },
  {
    id: "3888",
    name: "Sark",
    country_code: "GB",
  },
  {
    id: "3889",
    name: "Scotland",
    country_code: "GB",
  },
  {
    id: "3890",
    name: "Scottish Borders",
    country_code: "GB",
  },
  {
    id: "3891",
    name: "Shropshire",
    country_code: "GB",
  },
  {
    id: "3892",
    name: "Somerset",
    country_code: "GB",
  },
  {
    id: "3893",
    name: "South Glamorgan",
    country_code: "GB",
  },
  {
    id: "3894",
    name: "South Wales",
    country_code: "GB",
  },
  {
    id: "3895",
    name: "South Yorkshire",
    country_code: "GB",
  },
  {
    id: "3896",
    name: "Southwell",
    country_code: "GB",
  },
  {
    id: "3897",
    name: "Staffordshire",
    country_code: "GB",
  },
  {
    id: "3898",
    name: "Strabane",
    country_code: "GB",
  },
  {
    id: "3899",
    name: "Suffolk",
    country_code: "GB",
  },
  {
    id: "3900",
    name: "Surrey",
    country_code: "GB",
  },
  {
    id: "3901",
    name: "Sussex",
    country_code: "GB",
  },
  {
    id: "3902",
    name: "Twickenham",
    country_code: "GB",
  },
  {
    id: "3903",
    name: "Tyne and Wear",
    country_code: "GB",
  },
  {
    id: "3904",
    name: "Tyrone",
    country_code: "GB",
  },
  {
    id: "3905",
    name: "Utah",
    country_code: "GB",
  },
  {
    id: "3906",
    name: "Wales",
    country_code: "GB",
  },
  {
    id: "3907",
    name: "Warwickshire",
    country_code: "GB",
  },
  {
    id: "3908",
    name: "West Lothian",
    country_code: "GB",
  },
  {
    id: "3909",
    name: "West Midlands",
    country_code: "GB",
  },
  {
    id: "3910",
    name: "West Sussex",
    country_code: "GB",
  },
  {
    id: "3911",
    name: "West Yorkshire",
    country_code: "GB",
  },
  {
    id: "3912",
    name: "Whissendine",
    country_code: "GB",
  },
  {
    id: "3913",
    name: "Wiltshire",
    country_code: "GB",
  },
  {
    id: "3914",
    name: "Wokingham",
    country_code: "GB",
  },
  {
    id: "3915",
    name: "Worcestershire",
    country_code: "GB",
  },
  {
    id: "3916",
    name: "Wrexham",
    country_code: "GB",
  },
  {
    id: "3917",
    name: "Wurttemberg",
    country_code: "GB",
  },
  {
    id: "3918",
    name: "Yorkshire",
    country_code: "GB",
  },
  {
    id: "3919",
    name: "Alabama",
    country_code: "US",
  },
  {
    id: "3920",
    name: "Alaska",
    country_code: "US",
  },
  {
    id: "3921",
    name: "Arizona",
    country_code: "US",
  },
  {
    id: "3922",
    name: "Arkansas",
    country_code: "US",
  },
  {
    id: "3923",
    name: "Byram",
    country_code: "US",
  },
  {
    id: "3924",
    name: "California",
    country_code: "US",
  },
  {
    id: "3925",
    name: "Cokato",
    country_code: "US",
  },
  {
    id: "3926",
    name: "Colorado",
    country_code: "US",
  },
  {
    id: "3927",
    name: "Connecticut",
    country_code: "US",
  },
  {
    id: "3928",
    name: "Delaware",
    country_code: "US",
  },
  {
    id: "3929",
    name: "District of Columbia",
    country_code: "US",
  },
  {
    id: "3930",
    name: "Florida",
    country_code: "US",
  },
  {
    id: "3931",
    name: "Georgia",
    country_code: "US",
  },
  {
    id: "3932",
    name: "Hawaii",
    country_code: "US",
  },
  {
    id: "3933",
    name: "Idaho",
    country_code: "US",
  },
  {
    id: "3934",
    name: "Illinois",
    country_code: "US",
  },
  {
    id: "3935",
    name: "Indiana",
    country_code: "US",
  },
  {
    id: "3936",
    name: "Iowa",
    country_code: "US",
  },
  {
    id: "3937",
    name: "Kansas",
    country_code: "US",
  },
  {
    id: "3938",
    name: "Kentucky",
    country_code: "US",
  },
  {
    id: "3939",
    name: "Louisiana",
    country_code: "US",
  },
  {
    id: "3940",
    name: "Lowa",
    country_code: "US",
  },
  {
    id: "3941",
    name: "Maine",
    country_code: "US",
  },
  {
    id: "3942",
    name: "Maryland",
    country_code: "US",
  },
  {
    id: "3943",
    name: "Massachusetts",
    country_code: "US",
  },
  {
    id: "3944",
    name: "Medfield",
    country_code: "US",
  },
  {
    id: "3945",
    name: "Michigan",
    country_code: "US",
  },
  {
    id: "3946",
    name: "Minnesota",
    country_code: "US",
  },
  {
    id: "3947",
    name: "Mississippi",
    country_code: "US",
  },
  {
    id: "3948",
    name: "Missouri",
    country_code: "US",
  },
  {
    id: "3949",
    name: "Montana",
    country_code: "US",
  },
  {
    id: "3950",
    name: "Nebraska",
    country_code: "US",
  },
  {
    id: "3951",
    name: "Nevada",
    country_code: "US",
  },
  {
    id: "3952",
    name: "New Hampshire",
    country_code: "US",
  },
  {
    id: "3953",
    name: "New Jersey",
    country_code: "US",
  },
  {
    id: "3954",
    name: "New Jersy",
    country_code: "US",
  },
  {
    id: "3955",
    name: "New Mexico",
    country_code: "US",
  },
  {
    id: "3956",
    name: "New York",
    country_code: "US",
  },
  {
    id: "3957",
    name: "North Carolina",
    country_code: "US",
  },
  {
    id: "3958",
    name: "North Dakota",
    country_code: "US",
  },
  {
    id: "3959",
    name: "Ohio",
    country_code: "US",
  },
  {
    id: "3960",
    name: "Oklahoma",
    country_code: "US",
  },
  {
    id: "3961",
    name: "Ontario",
    country_code: "US",
  },
  {
    id: "3962",
    name: "Oregon",
    country_code: "US",
  },
  {
    id: "3963",
    name: "Pennsylvania",
    country_code: "US",
  },
  {
    id: "3964",
    name: "Ramey",
    country_code: "US",
  },
  {
    id: "3965",
    name: "Rhode Island",
    country_code: "US",
  },
  {
    id: "3966",
    name: "South Carolina",
    country_code: "US",
  },
  {
    id: "3967",
    name: "South Dakota",
    country_code: "US",
  },
  {
    id: "3968",
    name: "Sublimity",
    country_code: "US",
  },
  {
    id: "3969",
    name: "Tennessee",
    country_code: "US",
  },
  {
    id: "3970",
    name: "Texas",
    country_code: "US",
  },
  {
    id: "3971",
    name: "Trimble",
    country_code: "US",
  },
  {
    id: "3972",
    name: "Utah",
    country_code: "US",
  },
  {
    id: "3973",
    name: "Vermont",
    country_code: "US",
  },
  {
    id: "3974",
    name: "Virginia",
    country_code: "US",
  },
  {
    id: "3975",
    name: "Washington",
    country_code: "US",
  },
  {
    id: "3976",
    name: "West Virginia",
    country_code: "US",
  },
  {
    id: "3977",
    name: "Wisconsin",
    country_code: "US",
  },
  {
    id: "3978",
    name: "Wyoming",
    country_code: "US",
  },
  {
    id: "3980",
    name: "Artigas",
    country_code: "UY",
  },
  {
    id: "3981",
    name: "Canelones",
    country_code: "UY",
  },
  {
    id: "3982",
    name: "Cerro Largo",
    country_code: "UY",
  },
  {
    id: "3983",
    name: "Colonia",
    country_code: "UY",
  },
  {
    id: "3984",
    name: "Durazno",
    country_code: "UY",
  },
  {
    id: "3985",
    name: "FLorida",
    country_code: "UY",
  },
  {
    id: "3986",
    name: "Flores",
    country_code: "UY",
  },
  {
    id: "3987",
    name: "Lavalleja",
    country_code: "UY",
  },
  {
    id: "3988",
    name: "Maldonado",
    country_code: "UY",
  },
  {
    id: "3989",
    name: "Montevideo",
    country_code: "UY",
  },
  {
    id: "3990",
    name: "Paysandu",
    country_code: "UY",
  },
  {
    id: "3991",
    name: "Rio Negro",
    country_code: "UY",
  },
  {
    id: "3992",
    name: "Rivera",
    country_code: "UY",
  },
  {
    id: "3993",
    name: "Rocha",
    country_code: "UY",
  },
  {
    id: "3994",
    name: "Salto",
    country_code: "UY",
  },
  {
    id: "3995",
    name: "San Jose",
    country_code: "UY",
  },
  {
    id: "3996",
    name: "Soriano",
    country_code: "UY",
  },
  {
    id: "3997",
    name: "Tacuarembo",
    country_code: "UY",
  },
  {
    id: "3998",
    name: "Treinta y Tres",
    country_code: "UY",
  },
  {
    id: "3999",
    name: "Andijon",
    country_code: "UZ",
  },
  {
    id: "4000",
    name: "Buhoro",
    country_code: "UZ",
  },
  {
    id: "4001",
    name: "Buxoro Viloyati",
    country_code: "UZ",
  },
  {
    id: "4002",
    name: "Cizah",
    country_code: "UZ",
  },
  {
    id: "4003",
    name: "Fargona",
    country_code: "UZ",
  },
  {
    id: "4004",
    name: "Horazm",
    country_code: "UZ",
  },
  {
    id: "4005",
    name: "Kaskadar",
    country_code: "UZ",
  },
  {
    id: "4006",
    name: "Korakalpogiston",
    country_code: "UZ",
  },
  {
    id: "4007",
    name: "Namangan",
    country_code: "UZ",
  },
  {
    id: "4008",
    name: "Navoi",
    country_code: "UZ",
  },
  {
    id: "4009",
    name: "Samarkand",
    country_code: "UZ",
  },
  {
    id: "4010",
    name: "Sirdare",
    country_code: "UZ",
  },
  {
    id: "4011",
    name: "Surhondar",
    country_code: "UZ",
  },
  {
    id: "4012",
    name: "Toskent",
    country_code: "UZ",
  },
  {
    id: "4013",
    name: "Malampa",
    country_code: "VU",
  },
  {
    id: "4014",
    name: "Penama",
    country_code: "VU",
  },
  {
    id: "4015",
    name: "Sanma",
    country_code: "VU",
  },
  {
    id: "4016",
    name: "Shefa",
    country_code: "VU",
  },
  {
    id: "4017",
    name: "Tafea",
    country_code: "VU",
  },
  {
    id: "4018",
    name: "Torba",
    country_code: "VU",
  },
  {
    id: "4019",
    name: "Vatican City State (Holy See)",
    country_code: "VA",
  },
  {
    id: "4020",
    name: "Amazonas",
    country_code: "VE",
  },
  {
    id: "4021",
    name: "Anzoategui",
    country_code: "VE",
  },
  {
    id: "4022",
    name: "Apure",
    country_code: "VE",
  },
  {
    id: "4023",
    name: "Aragua",
    country_code: "VE",
  },
  {
    id: "4024",
    name: "Barinas",
    country_code: "VE",
  },
  {
    id: "4025",
    name: "Bolivar",
    country_code: "VE",
  },
  {
    id: "4026",
    name: "Carabobo",
    country_code: "VE",
  },
  {
    id: "4027",
    name: "Cojedes",
    country_code: "VE",
  },
  {
    id: "4028",
    name: "Delta Amacuro",
    country_code: "VE",
  },
  {
    id: "4029",
    name: "Distrito Federal",
    country_code: "VE",
  },
  {
    id: "4030",
    name: "Falcon",
    country_code: "VE",
  },
  {
    id: "4031",
    name: "Guarico",
    country_code: "VE",
  },
  {
    id: "4032",
    name: "Lara",
    country_code: "VE",
  },
  {
    id: "4033",
    name: "Merida",
    country_code: "VE",
  },
  {
    id: "4034",
    name: "Miranda",
    country_code: "VE",
  },
  {
    id: "4035",
    name: "Monagas",
    country_code: "VE",
  },
  {
    id: "4036",
    name: "Nueva Esparta",
    country_code: "VE",
  },
  {
    id: "4037",
    name: "Portuguesa",
    country_code: "VE",
  },
  {
    id: "4038",
    name: "Sucre",
    country_code: "VE",
  },
  {
    id: "4039",
    name: "Tachira",
    country_code: "VE",
  },
  {
    id: "4040",
    name: "Trujillo",
    country_code: "VE",
  },
  {
    id: "4041",
    name: "Vargas",
    country_code: "VE",
  },
  {
    id: "4042",
    name: "Yaracuy",
    country_code: "VE",
  },
  {
    id: "4043",
    name: "Zulia",
    country_code: "VE",
  },
  {
    id: "4044",
    name: "Bac Giang",
    country_code: "VN",
  },
  {
    id: "4045",
    name: "Binh Dinh",
    country_code: "VN",
  },
  {
    id: "4046",
    name: "Binh Duong",
    country_code: "VN",
  },
  {
    id: "4047",
    name: "Da Nang",
    country_code: "VN",
  },
  {
    id: "4048",
    name: "Dong Bang Song Cuu Long",
    country_code: "VN",
  },
  {
    id: "4049",
    name: "Dong Bang Song Hong",
    country_code: "VN",
  },
  {
    id: "4050",
    name: "Dong Nai",
    country_code: "VN",
  },
  {
    id: "4051",
    name: "Dong Nam Bo",
    country_code: "VN",
  },
  {
    id: "4052",
    name: "Duyen Hai Mien Trung",
    country_code: "VN",
  },
  {
    id: "4053",
    name: "Hanoi",
    country_code: "VN",
  },
  {
    id: "4054",
    name: "Hung Yen",
    country_code: "VN",
  },
  {
    id: "4055",
    name: "Khu Bon Cu",
    country_code: "VN",
  },
  {
    id: "4056",
    name: "Long An",
    country_code: "VN",
  },
  {
    id: "4057",
    name: "Mien Nui Va Trung Du",
    country_code: "VN",
  },
  {
    id: "4058",
    name: "Thai Nguyen",
    country_code: "VN",
  },
  {
    id: "4059",
    name: "Thanh Pho Ho Chi Minh",
    country_code: "VN",
  },
  {
    id: "4060",
    name: "Thu Do Ha Noi",
    country_code: "VN",
  },
  {
    id: "4061",
    name: "Tinh Can Tho",
    country_code: "VN",
  },
  {
    id: "4062",
    name: "Tinh Da Nang",
    country_code: "VN",
  },
  {
    id: "4063",
    name: "Tinh Gia Lai",
    country_code: "VN",
  },
  {
    id: "4064",
    name: "Anegada",
    country_code: "VC",
  },
  {
    id: "4065",
    name: "Jost van Dyke",
    country_code: "VC",
  },
  {
    id: "4066",
    name: "Tortola",
    country_code: "VC",
  },
  {
    id: "4067",
    name: "Saint Croix",
    country_code: "PS",
  },
  {
    id: "4068",
    name: "Saint John",
    country_code: "PS",
  },
  {
    id: "4069",
    name: "Saint Thomas",
    country_code: "PS",
  },
  {
    id: "4077",
    name: "Adan",
    country_code: "YE",
  },
  {
    id: "4078",
    name: "Abyan",
    country_code: "YE",
  },
  {
    id: "4079",
    name: "Dhamar",
    country_code: "YE",
  },
  {
    id: "4080",
    name: "Hadramaut",
    country_code: "YE",
  },
  {
    id: "4081",
    name: "Hajjah",
    country_code: "YE",
  },
  {
    id: "4082",
    name: "Hudaydah",
    country_code: "YE",
  },
  {
    id: "4083",
    name: "Ibb",
    country_code: "YE",
  },
  {
    id: "4084",
    name: "Lahij",
    country_code: "YE",
  },
  {
    id: "4085",
    name: "Ma''rib",
    country_code: "YE",
  },
  {
    id: "4086",
    name: "Madinat San''a",
    country_code: "YE",
  },
  {
    id: "4087",
    name: "Sa''dah",
    country_code: "YE",
  },
  {
    id: "4088",
    name: "Sana",
    country_code: "YE",
  },
  {
    id: "4089",
    name: "Shabwah",
    country_code: "YE",
  },
  {
    id: "4090",
    name: "Ta''izz",
    country_code: "YE",
  },
  {
    id: "4091",
    name: "al-Bayda",
    country_code: "YE",
  },
  {
    id: "4092",
    name: "al-Hudaydah",
    country_code: "YE",
  },
  {
    id: "4093",
    name: "al-Jawf",
    country_code: "YE",
  },
  {
    id: "4094",
    name: "al-Mahrah",
    country_code: "YE",
  },
  {
    id: "4095",
    name: "al-Mahwit",
    country_code: "YE",
  },
  {
    id: "4102",
    name: "Central",
    country_code: "ZM",
  },
  {
    id: "4103",
    name: "Copperbelt",
    country_code: "ZM",
  },
  {
    id: "4104",
    name: "Eastern",
    country_code: "ZM",
  },
  {
    id: "4105",
    name: "Luapala",
    country_code: "ZM",
  },
  {
    id: "4106",
    name: "Lusaka",
    country_code: "ZM",
  },
  {
    id: "4107",
    name: "North-Western",
    country_code: "ZM",
  },
  {
    id: "4108",
    name: "Northern",
    country_code: "ZM",
  },
  {
    id: "4109",
    name: "Southern",
    country_code: "ZM",
  },
  {
    id: "4110",
    name: "Western",
    country_code: "ZM",
  },
  {
    id: "4111",
    name: "Bulawayo",
    country_code: "ZW",
  },
  {
    id: "4112",
    name: "Harare",
    country_code: "ZW",
  },
  {
    id: "4113",
    name: "Manicaland",
    country_code: "ZW",
  },
  {
    id: "4114",
    name: "Mashonaland Central",
    country_code: "ZW",
  },
  {
    id: "4115",
    name: "Mashonaland East",
    country_code: "ZW",
  },
  {
    id: "4116",
    name: "Mashonaland West",
    country_code: "ZW",
  },
  {
    id: "4117",
    name: "Masvingo",
    country_code: "ZW",
  },
  {
    id: "4118",
    name: "Matabeleland North",
    country_code: "ZW",
  },
  {
    id: "4119",
    name: "Matabeleland South",
    country_code: "ZW",
  },
  {
    id: "4120",
    name: "Midlands",
    country_code: "ZW",
  },
];
