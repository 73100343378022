/* eslint-disable no-use-before-define */

import React, { useEffect, useMemo, useState } from "react";
import "./Controllers.css";
import { SpinnerCircular } from "spinners-react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { DataGrid } from "@mui/x-data-grid";
import sitemasterlogo from "../../Components/SitemasterLogo.png";
import krain_boxes from "./krain_boxes.png";
import LinearProgress from "@mui/material/LinearProgress";
import { apiUser } from "../../Apis/configs/axiosConfig";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Paper,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import dowloadGuide from "./dowloadGuide.png";
import SaveIcon from "@mui/icons-material/Save";
import connecting from "./connectin.png";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import { MySwal } from "../../Components/helpers/SwalAlert";
import EditIcon from "@mui/icons-material/Edit";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { grey } from "@mui/material/colors";
import { ExpandMore, Info } from "@mui/icons-material";
import Swal from "sweetalert2";
import CloseIcon from "@mui/icons-material/Close";
import { setLocale } from "yup";
import { useTranslation } from "react-i18next";
import ControllerDataForm from "../../Components/ControllerDataForm";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
  padding: "20px",
  textAlign: "center",
  objectFit: "contain",
};

export const MyControllers_view = () => {
  const [name, setName] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [runningLoading, setRunningloading] = useState(false);
  const [registerLoading, setregisterLoading] = useState(false);
  const [idController, setidController] = useState("");
  const [progressValue, setProgressValue] = useState(0);
  const [inputValues, setInputValues] = useState("");
  const [loading, setloading] = useState(false);
  const [step, setStep] = useState(0);
  const [controllerLoading, setcontrollerLoading] = useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const { t, i18n } = useTranslation();

  const columns = [
    {
      field: "alias",
      headerName: t("Controller Name"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      align: "left",
      headerClassName: "header-table-title-style",
    },
    {
      field: "id",
      headerName: t("Server ID"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
    },
    {
      field: "device_connected",
      headerName: t("Status"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      renderCell: (params) => {
        return params.value === true ? (
          <Chip
            icon={<WifiIcon />}
            label={t("Connected")}
            color="success"
            size="small"
          />
        ) : (
          <Chip
            icon={<WifiIcon />}
            label={t("Disconnected")}
            color="default"
            size="small"
          />
        );
      },
    },
    {
      field: "alert_status",
      headerName: t("Alert"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      renderCell: (params) => {
        return params.value > 0 ? (
          <Button
            variant="contained"
            size="small"
            tabIndex={params.hasFocus ? 0 : -1}
            style={{ marginLeft: 16, backgroundColor: "Orange" }}
            startIcon={<ReportProblemIcon></ReportProblemIcon>}
          >
            {" "}
            Warning
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      field: t("Actions"),
      flex: 2,
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
      headerClassName: "header-table-title-style",
      sortable: false,
      renderCell: (params) => (
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ border: "none", width: "50%" }}
            onClick={() => editBox(params.row)}
            variant="outlined"
            startIcon={
              <EditIcon style={{ fontSize: "30px", color: "#032C65" }} />
            }
          ></Button>
          <Button
            style={{ border: "none", width: "50%" }}
            onClick={() => deleteController(params.row)}
            variant="outlined"
            startIcon={
              <DeleteIcon style={{ fontSize: "30px", color: "#800000" }} />
            }
          ></Button>
        </div>
      ),
    },
  ];

  const [row, setRow] = useState({});

  const [status, setStatus] = useState(0);

  let navigate = useNavigate();
  const [selectedMobileRow, setSelectedMobileRow] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:800px)");

  const modalStyleAdaptable = {
    ...modalStyle,
    width: isSmallScreen ? "95vw" : "30vw",
    height: isSmallScreen ? "80vh" : "80vh",
    overflow: "auto",
  };

  const [editRow, seteditRow] = useState({});
  const [form, setForm] = useState(
    editRow || {
      alias: "",
      address: {
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        datas();
        checkStatus();
        if (idController) {
          // console.log(idController);
          const callStatus = setInterval(checkStatus, 10000);

          return () => clearInterval(callStatus);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [idController]);

  const datas = async () => {
    setIsLoading(true);

    try {
      const userResp = await apiUser.get("/controllers");
      // console.log("3");
      if (!userResp || !userResp.data || userResp.data.data === undefined) {
      } else {
        setName(userResp.data.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (params, event) => {
    if (params && params.row && params.row.device_connected != false) {
      if (!event) {
        setSelectedMobileRow(params.row);

        navigate(`/dashboard/${params.row.id}`, {
          state: {
            id: params.row.id,
            alias: params.row.alias,
            device_connected: params.row.device_connected,
            alert: params.row.alert_status,
          },
        });
      } else {
        const isButton = event.target.closest("button");
        if (!isButton) {
          setSelectedMobileRow(params.row);

          navigate(`/dashboard/${params.id}`, {
            state: {
              id: params.row.id,
              alias: params.row.alias,
              device_connected: params.row.device_connected,
              alert: params.row.alert_status,
            },
          });
        } else {
        }
      }
    } else {
      MySwal.fire({
        title: "Error",
        text: " Controller disconnected , cant access.   ",
        icon: "warning",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };
  const TableControllersList = () => {
    const mapData = useMemo(() => {
      return Object.keys(name).map((key) => {
        const { alias, device_connected, alert_status, id } = name[key];
        return {
          id: id,
          alias: alias,
          device_connected: device_connected,
          alert_status: alert_status,
        };
      });
    }, [name, selectedRow, isSmallScreen]);

    return (
      <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
        {isSmallScreen ? (
          <MobileTable data={mapData} selectedMobileRow={selectedMobileRow} />
        ) : (
          <Grid
            item
            container
            xs={1}
            sm={12}
            md={10}
            lg={8}
            justifyContent="center"
          >
            {Object.keys(name).length > 0 ? (
              <DataGrid
                sx={{
                  backgroundColor: "white",
                  border: "5px",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
                  fontSize: "15px",
                }}
                hideFooter
                rows={name}
                columns={columns}
                onSelectionModelChange={(newSelection) => {
                  if (newSelection.selectionModel.length > 0) {
                    const selectedRowIndex = newSelection.selectionModel[0];
                    setSelectedRow(name[selectedRowIndex]);
                  }
                }}
                onRowClick={(params, event) => handleRowClick(params, event)}
                selectionModel={selectedRow ? [selectedRow.id] : []}
              />
            ) : (
              <Grid item>
                <Stack direction="column" justifyContent="center">
                  <Typography
                    variant="h5"
                    color={grey[600]}
                    fontWeight="bold"
                    textAlign="center"
                  >
                    <Info
                      fontSize="large"
                      sx={{ color: grey[600], fontSize: "4em" }}
                    />
                    <br />
                    No controllers registered
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  const MobileTable = ({ data, selectedMobileRow }) =>
    data.map((row, idx) => (
      <Grid
        key={`controllers-mobile-table-${idx}`}
        item
        container
        xs={9}
        sx={{ marginTop: 4 }}
      >
        <Accordion
          key={row.id}
          style={{
            backgroundColor: "white",
            color: "black",
            border: "1px solid grey",
            borderRadius: "8px",
            marginBottom: "16px",
            width: "100%",
          }}
          component={Paper}
          elevation={4}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                style={{
                  color: "#032C65",
                  fontWeight: "normal",
                  marginRight: "8px",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
              >
                {t("Controller Name")}:
              </Typography>
              <DeleteIcon
                aria-label="delete"
                onClick={() => deleteController(row)}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  fontSize: 30,
                  color: "#800000",
                }}
              />
              <Typography
                style={{
                  color: "black",
                  textAlign: "left",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                {row.alias}
              </Typography>

              {Number(row.alert_status) > 0 ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    marginLeft: "8px",
                    marginBottom: "8px",
                  }}
                  startIcon={<ReportProblemIcon />}
                >
                  Warning
                </Button>
              ) : (
                <BeenhereIcon
                  style={{
                    backgroundColor: "white",
                    color: "green",
                    marginTop: "8px",
                    fontSize: 35,
                    marginBottom: "8px",
                  }}
                />
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              style={{
                color: "#032C65",
                fontWeight: "normal",
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {t("Server ID")}:
            </Typography>
            <Typography
              style={{
                color: "black",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {row.id || "N/A"}
            </Typography>
            <Typography
              style={{
                color: "#032C65",

                fontWeight: "normal",
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {t("Status")}:
            </Typography>

            <div
              style={{
                marginRight: "8px",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              {row.device_connected ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    marginRight: "8px",
                    textAlign: "left",
                  }}
                  startIcon={<WifiIcon />}
                >
                  {t("Connected")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#DB2C2C",
                    color: "white",
                    marginRight: "8px",
                    textAlign: "left",
                  }}
                  startIcon={<WifiOffIcon />}
                >
                  {t(`Disconnected`)}
                </Button>
              )}
            </div>
          </AccordionDetails>
          <AccordionDetails
            style={{
              textAlign: "justify",
              color: "#032C65",
              padding: "16px",
              borderTop: "1px solid #ccc",
              marginTop: "8px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              size="large"
              style={{
                backgroundColor: "yellowgreen",
                border: "1px solid",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
                fontSize: "15px",
                color: "black",
              }}
              startIcon={<AnalyticsIcon />}
              onClick={() => handleRowClick({ row }, null)}
            >
              {t(`Dashboard`)}
            </Button>
            <Button
              type="button"
              size="large"
              style={{
                backgroundColor: "yellowgreen",
                border: "1px solid black",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(5, 11, 0, 0.3)",
                color: "black",
              }}
              startIcon={<EditIcon />}
              onClick={() => editBox({ row }, null)}
            >
              {t(`Edit`)}
            </Button>
          </AccordionDetails>
        </Accordion>
      </Grid>
    ));

  const openBox = () => setOpen(true);

  const closeBox = () => {
    setOpen(false);
  };

  const register = async () => {
    try {
      setProgressValue(0);

      setregisterLoading(true);
      setRunningloading(true);
      const requestStartTime = Date.now();

      const request = await apiUser.post(
        `/controllers/registration/get_next_id`
      );
      // console.log(request);
      const requestEndTime = Date.now();
      const requestDuration = requestEndTime - requestStartTime;

      const minDuration = Math.max(12000 - requestDuration, 0);

      setProgressValue(0);

      let progress = 0;
      let elapsedDuration = 0;

      const interval = setInterval(() => {
        elapsedDuration += 100;
        progress = (elapsedDuration / minDuration) * 100;

        setProgressValue(Math.min(progress, 100));

        if (elapsedDuration >= minDuration) {
          clearInterval(interval);
          setregisterLoading(false);
          setRunningloading(false);

          setidController(request.data.next_id);
          inputsID(request.data.next_id);
          nextClick();
        }
      }, 100);
    } catch (error) {
      console.error(error);
      setregisterLoading(false);
    }
  };

  const inputsID = (string) => {
    let divider = string.split("");

    setInputValues(divider);
  };

  const checkStatus = async () => {
    try {
      if (idController) {
        const resp = await apiUser.get(
          `/controllers/registration/${idController}`
        );
        let { registration_status } = resp.data;

        setcontrollerLoading(true);
        if (registration_status === 0) {
          setStatus(0);
        }
        if (registration_status === 1) {
          setStatus(1);
        }
        if (registration_status === 2) {
          setStatus(2);
          setcontrollerLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const download = () => {
    window.open(
      "https://www.krain.com/content/files/images/landingpages/irrigation%20controllers/site%20master/sitemaster%20remote%20access%20guide.pdf",
      "_blank"
    );
  };

  const nextClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const deleteController = async (row) => {
    try {
      const result = await MySwal.fire({
        title: t("Delete controller"),
        text: t("Are you sure to delete ") + `${row.id}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#032C65",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it!"),
        cancelButtonText: t("Cancel"),
      });

      if (result.isConfirmed) {
        const resp = await apiUser.delete(`/controllers/${row.id}`);

        setName((prevName) => prevName.filter((item) => item.id !== row.id));

        MySwal.fire({
          title: t("Controller deleted"),
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    } catch (e) {
      console.error(e);
      MySwal.fire({
        title: "Error",
        text: t("Failed to delete the controller."),
        icon: "error",
      });
    }
  };

  const editBox = async (row) => {
    setopenEdit(true);
    setStep(5);

    try {
      setloading(true);

      let apiResponse;

      if (isSmallScreen) {
        setRow(row.row);
        apiResponse = await apiUser.get(`/controllers/${row.row.id}`);
      } else {
        setRow(row);
        apiResponse = await apiUser.get(`/controllers/${row.id}`);
      }

      seteditRow(apiResponse.data.data);
    } catch (error) {
      console.error("Error during API call:");
    } finally {
      setloading(false);
    }

    // setTimeout(() => {
    //   if (editRow?.address) {
    //     setloading(false);

    //     setForm({
    //       alias: editRow.alias || "",
    //       address: {
    //         address_line_1: editRow.address.address_line_1 || "",
    //         address_line_2: editRow.address.address_line_2 || "",
    //         city: editRow.address.city || "",
    //         state: editRow.address.state || "",
    //         zipcode: editRow.address.zipcode || "",
    //         country: editRow.address.country || "",
    //       },
    //     });
    //   }
    // }, 2000);
  };

  useEffect(() => {
    if (
      editRow !== null &&
      editRow.address !== undefined &&
      editRow.address !== null
    ) {
      setloading(false);
      setForm({
        alias: editRow.alias || "",
        address: {
          address_line_1: editRow.address.address_line_1 || "",
          address_line_2: editRow.address.address_line_2 || "",
          city: editRow.address.city || "",
          state: editRow.address.state || "",
          zipcode: editRow.address.zipcode || "",
          country: editRow.address.country || "",
        },
      });
    }
    if (editRow.address === null) {
      setloading(false);
    }
  }, [editRow]);

  const closeEditbox = () => {
    setopenEdit(false);
    setStep(0);
    seteditRow({});
  };

  const saveEdit = async (payload) => {
    try {
      setloading(true);
      setRunningloading(true);

      const resp = await apiUser.put(
        `/controllers/${row.id}/update_data/`,
        payload
      );

      if (resp.data.success) {
        setRunningloading(false);
        closeEditbox();

        Swal.fire({
          title: t("Edit Controller"),
          text: t("Controller has been modified"),
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          datas();
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        {runningLoading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={runningLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          sx={{
            borderRadius: "8px",
            border: `1px solid ${grey[300]}`,
            backgroundColor: "#F7F8FA",
            padding: 2,
          }}
          item
          container
          component={Paper}
          elevation={0}
          xs={10}
          sm={10}
        >
          <Grid
            sx={{ borderRadius: "8px" }}
            item
            xs={12}
            sm={6}
            justifyContent="end"
            alignItems="center"
            component={Stack}
          >
            <Typography variant="h5" fontWeight="bolder" color="#6B7A99">
              {t("My Controllers")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={5}
            paddingX={3}
            paddingY={0.2}
            textAlign="left"
            direction="row"
            justifyContent="end"
            alignItems="center"
            style={{ borderRadius: "13px" }}
            sx={{
              marginTop: { xs: 1, sm: 1, md: 0, lg: 0 },
            }}
          >
            <Button
              onClick={() => openBox()}
              className="button-new-controller-style"
              variant="contained"
              style={{
                backgroundColor: "#032C65",
                color: "white",
                borderRadius: "10px",
                position: "relative",
                left: 0,
                padding: 10,
                fontSize: 16,

                fontWeight: "normal",
              }}
              startIcon={<AddCircleIcon />}
            >
              {t("Add Controller")}
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid item container justifyContent="center" marginTop={4}>
            <SpinnerCircular />
          </Grid>
        ) : (
          <TableControllersList />
        )}
      </Grid>

      <Modal
        open={open}
        onClose={closeBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          // sx={modalStyleAdaptable}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "20px",
            maxHeight: "100vh",
          }}
        >
          <Grid container justifyContent="end">
            <CloseIcon
              size="large"
              color="primary"
              sx={{
                right: 6,
                top: "1%",
                cursor: "pointer",
              }}
              onClick={closeBox}
            />
          </Grid>
          <img
            className="logo-section"
            alt="Section"
            style={{ width: "100%", maxWidth: "250px", marginBottom: "20px" }}
            src={sitemasterlogo}
          />

          {step === 0 && (
            <>
              <img
                className="logo-section"
                alt="Section"
                style={{
                  width: "80%",
                  maxWidth: "250px",
                  marginBottom: "20px",
                }}
                src={dowloadGuide}
              />

              <LoadingButton
                className="button-new-controller-style"
                variant="contained"
                onClick={() => download()}
                loading={loading}
                loadingPosition="start"
                style={{
                  backgroundColor: "white",
                  color: "#032C65",
                  width: "85%",
                  left: 20,
                  border: "none",
                  fontSize: 18,
                  borderRadius: "10px",
                  padding: 15,
                }}
                startIcon={
                  <SaveIcon style={{ color: "#FFC300", fontSize: 30 }} />
                }
              >
                {t(`Download Sitemaster Network manual`)}{" "}
              </LoadingButton>
            </>
          )}

          {step === 0 && (
            <Button
              onClick={() => nextClick()}
              style={{
                width: "200px",
                height: "8%",
                backgroundColor: "#032C65",
                color: "white",
                fontWeight: "normal",
                borderRadius: "8px",
                marginBottom: "30px",
                top: "30px",
              }}
            >
              {t(`Next`)}
            </Button>
          )}

          {step === 1 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: 18,
                  marginTop: "5px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t("Add new controller")}
              </Typography>

              <img
                className="logo-section"
                alt="Section"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "20px",
                }}
                src={krain_boxes}
              />

              {registerLoading ? (
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{
                    height: "15px",
                    borderRadius: "22px",
                    width: "80%",
                    marginTop: "-10px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                ></LinearProgress>
              ) : (
                ""
              )}

              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "10px",
                  width: "80%",
                  textAlign: "center",
                }}
              >
                <LoadingButton
                  variant="contained"
                  onClick={() => register()}
                  loadingPosition="start"
                  type="submit"
                  loading={runningLoading}
                  style={{
                    padding: "10px 40px",
                    backgroundColor: "#032C65",
                    color: "white",
                    fontWeight: "normal",
                    margin: "20px 0",
                  }}
                >
                  {runningLoading ? t("Generating") : t("Generate ID")}
                </LoadingButton>
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "5px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t("Server ID")} {t(`Generated`)}
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "95%",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                {inputValues.map((index) => (
                  <input
                    key={`${index}`}
                    type="text"
                    value={index}
                    style={{
                      marginBottom: "10px",
                      padding: "15px",
                      width: "45px",
                      marginLeft: "2px",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                    }}
                  />
                ))}
              </div>

              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  color: "#032C65",
                }}
              >
                {t(
                  `Introduce the ID generated in your controller, and proceed with next step`
                )}
              </p>
              <Button
                onClick={() => nextClick()}
                style={{
                  width: "200px",
                  height: "8%",
                  fontSize: "18px",
                  fontWeight: "normal",
                  backgroundColor: "#032C65",
                  color: "white",
                  marginBottom: "30px",
                  borderRadius: "8px",
                }}
              >
                {t(`Next`)}
              </Button>
            </>
          )}
          {step === 3 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "5px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`Controller ID`)}:
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "bolder",
                  marginTop: "5px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {inputValues}
              </Typography>
              <img
                className="logo-section"
                alt="Section"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  marginBottom: "20px",
                }}
                src={connecting}
              />
              {status === 0 && (
                <>
                  <p style={{ color: "#032C65", fontSize: "20px" }}>
                    {" "}
                    {t(`Waiting for controller connection...`)}
                  </p>
                  <SpinnerCircular />
                </>
              )}
              {status === 1 && (
                <>
                  <p style={{ color: "#032C65", fontSize: "20px" }}>
                    {" "}
                    {t(`Syncing controller`)}
                  </p>
                  <SpinnerCircular />
                </>
              )}
              {status === 2 && (
                <>
                  <p style={{ color: "#032C65", fontSize: "20px" }}>
                    {t(`Controller has synced correctly`)}
                  </p>

                  <Button
                    onClick={() => nextClick()}
                    style={{
                      width: "200px",
                      height: "6%",
                      backgroundColor: "#032C65",
                      fontWeight: "normal",
                      color: "white",
                      borderRadius: "8px",
                      marginBottom: "30px",
                      top: "10px",
                    }}
                  >
                    {t(`Next`)}
                  </Button>
                </>
              )}
            </>
          )}

          {step === 4 && (
            <>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "22px",
                  fontWeight: "normal",
                  marginTop: "5px",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                {t(`Configuration completed`)}
              </Typography>
              <img
                className="logo-section"
                alt="Section"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  marginBottom: "20px",
                }}
                src={krain_boxes}
              />

              <p
                style={{
                  textAlign: "center",
                  color: "#032C65",
                  fontSize: "18px",
                }}
              >
                {" "}
                {t(`Your new controller should appear on your dashboard controllers,
                if you need more assistance with it click on the button`)}{" "}
                {t(`"Contact
                us"`)}
                .{" "}
              </p>

              <Button
                onClick={() => {
                  window.location.reload();
                }}
                style={{
                  width: "200px",
                  height: "6%",
                  fontSize: "18px",
                  fontWeight: "normal",
                  backgroundColor: "#032C65",
                  color: "white",
                  borderRadius: "8px",
                }}
              >
                {t("Confirm")}
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={closeEditbox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={modalStyleAdaptable}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            maxWidth: "90vw",
            overflow: "auto",
          }}
        >
          <Grid container justifyContent="end" padding={0}>
            <CloseIcon size="large" color="primary" onClick={closeEditbox} />
          </Grid>
          <img
            className="logo-section"
            alt="Section"
            style={{ width: "100%", marginBottom: "20px" }}
            src={sitemasterlogo}
          />

          {step === 5 && loading ? (
            <SpinnerCircular />
          ) : (
            <ControllerDataForm
              showAlias={true}
              isLoading={loading}
              controllerAlias={!!editRow ? editRow.alias : null}
              controllerAddress={!!editRow ? editRow.address : {}}
              onSubmitHandler={(values) => {
                let error = null;

                if (!values.country) {
                  error = "country";
                }
                if (!values.state) {
                  error = "state";
                }
                if (!values.zipcode) {
                  error = "zip";
                }
                if (!values.city) {
                  error = "city";
                }
                if (!values.address_line_1) {
                  error = "line_1";
                }

                const errorLabel = t(`errors.${error}_need_presence`);

                if (!error) {
                  console.log(values);
                  const basePayload = {
                    address: {
                      address_line_1: values.address_line_1,
                      address_line_2: values.address_line_2,
                      city: values.city,
                      zipcode: values.zipcode,
                      country: values.country.name
                        ? t(values.country.name)
                        : values.country,
                      state: values.state.name
                        ? t(values.state.name)
                        : values.state,
                    },
                  };
                  const payloadInput = values.alias
                    ? {
                        alias: values.alias,
                        ...basePayload,
                      }
                    : basePayload;
                  console.log(payloadInput);

                  saveEdit(payloadInput);
                } else {
                  Swal.fire({
                    title: t("errors.input_required"),
                    text: errorLabel,
                    icon: "error",
                  });
                }
              }}
            />
          )}

          {/* {!loading && step !== 5 && (
            <div style={{ position: "relative", display: "inline-block" }}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={runningLoading}
                onClick={saveEdit}
                style={{
                  marginTop: "20px",
                  top: "10px",
                  width: "200px",
                  backgroundColor: "#032C65",
                  color: "white",
                  fontWeight: "normal",
                  border: "1px solid grey",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(5, 11, 0, 0.4)",
                }}
              >
                {t("Confirm")}
              </LoadingButton>
            </div>
          )} */}
        </Box>
      </Modal>
    </>
  );
};
